<template>
  <PopoverButton class="group rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary">
    <div class="flex items-center space-x-2 divide-x divide-gray-200 rounded-2xl border border-dashed border-gray-200 px-2 py-1 text-xs group-hover:bg-gray-50">
      <div class="flex items-center space-x-1">
        <template v-if="!required || empty">
          <FontAwesomeIcon
            v-if="empty"
            class="h-3.5 w-3.5 fill-gray-500 group-hover:fill-gray-600"
            icon="circle-plus-solid"
          />
          <FontAwesomeIcon
            v-else
            class="h-3.5 w-3.5 fill-gray-500 group-hover:fill-gray-600"
            icon="circle-xmark-solid"
            @click.prevent="$emit('clear')"
          />
        </template>
        <div class="font-medium text-gray-500 group-hover:text-gray-600">
          {{ $t(label) }}
        </div>
      </div>
      <slot />
    </div>
  </PopoverButton>
</template>

<script lang="ts" setup>
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import { PopoverButton } from "@headlessui/vue";
import type { ComputedRef } from "vue";

defineEmits<{
  click: [];
  clear: [];
}>();

const { t: $t } = useI18n();

const label = inject<string>("label", "");
const empty = inject<ComputedRef<boolean>>("empty");
const required = inject<ComputedRef<boolean>>("required");
</script>
