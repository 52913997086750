import BookingsCreatePage from "@/contexts/bookings/ui/pages/BookingsCreatePage.vue";
import BookingsImportsCreatePage from "@/contexts/bookings/ui/pages/BookingsImportsCreatePage.vue";
import BookingsImportsPage from "@/contexts/bookings/ui/pages/BookingsImportsPage.vue";
import BookingsManagePage from "@/contexts/bookings/ui/pages/BookingsManagePage.vue";
import BookingsPage from "@/contexts/bookings/ui/pages/BookingsPage.vue";
import Middleware from "@/contexts/shared/models/Middleware";
import type { RouteRecordRaw, Router } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    name: "bookings",
    path: "/bookings",
    component: BookingsPage,
    meta: {
      layout: "dashboard",
      group: "bookings",
      middleware: [Middleware.auth],
    },
    children: [
      {
        name: "bookings.create",
        path: "/bookings/create",
        component: BookingsCreatePage,
        meta: {
          layout: "dashboard",
          group: "bookings",
          middleware: [Middleware.auth],
        },
      },
    ],
  },
  {
    name: "bookings.imports",
    path: "/bookings/imports",
    component: BookingsImportsPage,
    meta: {
      layout: "dashboard",
      group: "bookings",
      middleware: [Middleware.auth],
    },
    children: [
      {
        name: "bookings.imports.create",
        path: "/bookings/imports/create",
        component: BookingsImportsCreatePage,
        meta: {
          layout: "dashboard",
          group: "bookings",
          middleware: [Middleware.auth],
        },
      },
    ],
  },
  {
    name: "bookings.manage",
    path: "/bookings/:id",
    component: BookingsManagePage,
    meta: {
      layout: "dashboard",
      group: "bookings",
      middleware: [Middleware.auth],
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
