import bookingsClient from "@/clients/bookings";
import CancelBookingFactory from "@/contexts/bookings/composables/factories/CancelBookingFactory";
import ConfirmBookingFactory from "@/contexts/bookings/composables/factories/ConfirmBookingFactory";
import CreateBookingFactory from "@/contexts/bookings/composables/factories/CreateBookingFactory";
import PdfBookingCheckInFactory from "@/contexts/bookings/composables/factories/PdfBookingCheckInFactory";
import SearchBookingFactory from "@/contexts/bookings/composables/factories/SearchBookingFactory";
import ShowBookingFactory from "@/contexts/bookings/composables/factories/ShowBookingFactory";
import type { Booking, UnsavedBooking } from "@/contexts/bookings/models/Booking";
import type { SearchBookingsCriteria } from "@/contexts/bookings/models/SearchBookingsCriteria";
import BookingProviderProvider from "@/contexts/bookings/providers/bookingProviderProvider";
import BookingStatusProvider from "@/contexts/bookings/providers/bookingStatusProvider";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { Option } from "@/contexts/shared/models/Select";
import api from "@/libs/api";

export default function useBookings() {
  const { t: $t } = useI18n();

  const empty: UnsavedBooking = {
    language: null,
    check_in: null,
    check_out: null,
    adults: 0,
    children: 0,
    is_youngsters: false,
    has_pets: false,
    security_deposit: null,
    notes: null,
    accommodation_id: null,
    guest_id: null,
    extras: [],
  };

  const searchBookings = async (criteria: SearchBookingsCriteria, cursor?: string): Promise<Pagination<Booking>> => {
    const { data, response } = await api.GET("/bookings", { params: { query: SearchBookingFactory.toSearchBookingsRequest(criteria, cursor) } });
    return Promise.resolve(SearchBookingFactory.fromSearchBookingsResponse(data, response.headers));
  };
  const createBooking = async (booking: UnsavedBooking): Promise<Booking> => {
    return CreateBookingFactory.fromCreateBookingResponse(await bookingsClient.createBooking(CreateBookingFactory.toCreateBookingRequest(booking)));
  };
  const showBooking = async (id: string): Promise<Booking> => {
    const { data } = await api.GET("/bookings/{booking}", { params: { path: { booking: id } } });
    return ShowBookingFactory.fromShowBookingResponse(data);
  };
  const confirmBooking = async (id: string | string[]): Promise<void> => {
    await api.PATCH("/bookings/confirm", { body: ConfirmBookingFactory.toConfirmBookingsRequest(id) });
  };
  const cancelBooking = async (id: string | string[]): Promise<void> => {
    await api.PATCH("/bookings/cancel", { body: CancelBookingFactory.toCancelBookingsRequest(id) });
  };
  const deleteBooking = async (id: string): Promise<void> => {
    await api.DELETE("/bookings/{booking}", { params: { path: { booking: id } } });
  };
  const pdfBookingCheckIn = async (id: string | string[]): Promise<string> => {
    const { data } = await api.GET("/bookings/check-in/pdf", { params: { query: PdfBookingCheckInFactory.toPdfBookingCheckInRequest(id) } });
    return PdfBookingCheckInFactory.fromPdfBookingCheckInResponse(data);
  };

  const bookingProviderOptions: Option[] = BookingProviderProvider.get().map((item) => ({ value: item, label: $t(`shared.providers.${item}`) }));
  const bookingStatusOptions: Option[] = BookingStatusProvider.get().map((item) => ({ value: item, label: $t(`bookings.status.${item}`) }));

  return {
    empty,
    showBooking,
    createBooking,
    cancelBooking,
    deleteBooking,
    searchBookings,
    confirmBooking,
    pdfBookingCheckIn,
    bookingStatusOptions,
    bookingProviderOptions,
  };
}
