<template>
  <div>
    <ListDetails v-if="hasAddress && accommodation">
      <ListDetailsColumn v-if="coordinates">
        <MapLocation :coordinates="coordinates" />
      </ListDetailsColumn>
      <ListDetailsColumn>
        <ListDetailsCell label="accommodations.props.street">
          {{ accommodation.street ?? "-" }}
        </ListDetailsCell>
        <ListDetailsCell label="accommodations.props.city">
          {{ accommodation.city ?? "-" }}
        </ListDetailsCell>
        <ListDetailsCell label="accommodations.props.postal_code">
          {{ accommodation.postal_code ?? "-" }}
        </ListDetailsCell>
        <ListDetailsCell label="accommodations.props.country_code">
          {{ accommodation.country_code ? $t(`shared.country.${accommodation.country_code}`) : "-" }}
        </ListDetailsCell>
        <ListDetailsCell label="accommodations.props.latitude">
          {{ accommodation.latitude ?? "-" }}
        </ListDetailsCell>
        <ListDetailsCell label="accommodations.props.longitude">
          {{ accommodation.longitude ?? "-" }}
        </ListDetailsCell>
      </ListDetailsColumn>
    </ListDetails>
    <SectionEmptyState v-else>
      {{ $t("accommodations.manage.address.empty") }}
    </SectionEmptyState>
  </div>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { GMapsCoordinates } from "@/contexts/shared/composables/useMaps";
import useMaps from "@/contexts/shared/composables/useMaps";
import ListDetails from "@/contexts/shared/ui/components/list/ListDetails.vue";
import ListDetailsCell from "@/contexts/shared/ui/components/list/ListDetailsCell.vue";
import ListDetailsColumn from "@/contexts/shared/ui/components/list/ListDetailsColumn.vue";
import MapLocation from "@/contexts/shared/ui/components/map/MapLocation.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

const props = defineProps<{
  accommodation: Partial<Accommodation>;
}>();

const { t: $t } = useI18n();
const { getCoordinates } = useMaps();

const hasAddress = computed<boolean>(() => !!(props.accommodation?.street || props.accommodation?.city || props.accommodation?.postal_code || props.accommodation?.country_code));
const coordinates = computed<GMapsCoordinates | undefined>(() => getCoordinates(props.accommodation?.latitude, props.accommodation?.longitude));
</script>
