import { accommodationContractFromResponse } from "@/contexts/accommodation-contracts/utils/accommodationContractFromResponse";
import type { AccommodationContract, UnsavedAccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import type { paths } from "@/libs/api/schema";

const toCreateAccommodationContractRequest = (contract: UnsavedAccommodationContract): paths["/accommodations/{accommodation}/contracts"]["post"]["requestBody"]["content"]["application/json"] => {
  return {
    year: contract.year,
    reference_price: contract.reference_price,
    notes: contract.notes,
  };
};

const fromCreateAccommodationContractResponse = (response: paths["/accommodations/{accommodation}/contracts"]["post"]["responses"][200]["content"]["application/json"] | undefined): AccommodationContract => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationContractFromResponse(response.data);
};

export default {
  toCreateAccommodationContractRequest,
  fromCreateAccommodationContractResponse,
};
