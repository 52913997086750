<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("booking_guests.create.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form @submit.prevent="submit">
      <BookingGuestsForm
        v-model="form"
        :errors="createBookingGuestsRequest.errors"
      />
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="createBookingGuestsRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import useBookingGuests from "@/contexts/booking-guests/composables/useBookingGuests";
import type { BookingGuest, UnsavedBookingGuest } from "@/contexts/booking-guests/models/BookingGuest";
import BookingGuestsForm from "@/contexts/booking-guests/ui/components/BookingGuestsForm.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";

const emit = defineEmits<{
  close: [];
  created: [value: BookingGuest];
}>();

const { t: $t } = useI18n();
const { createBookingGuest } = useBookingGuests();
const { successNotification, errorNotification } = useNotification();

const form = ref<UnsavedBookingGuest>({ name: "", emails: [""], phones: [""], language: "" });

const createBookingGuestsRequest = useRequest({
  promise: () => createBookingGuest(form.value),
  onSuccess: (response) => {
    successNotification("booking_guests.create.success");
    emit("close");
    emit("created", response);
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("booking_guests.create.error");
    }
  },
});

const submit = async () => {
  await createBookingGuestsRequest.fetch();
};
</script>
