<template>
  <ConfirmDeleteModal
    :is-loading="deleteCleaningGroupRequest.isLoading"
    description="cleaning_groups.delete.description"
    title="cleaning_groups.delete.title"
    @close="$emit('close')"
    @confirm="deleteCleaningGroupRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useCleaningGroups from "@/contexts/cleaning-groups/composables/useCleaningGroups";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";

const props = defineProps<{
  cleaningGroup: CleaningGroup;
}>();

const emit = defineEmits<{
  close: [];
  deleted: [];
}>();

const { deleteCleaningGroup } = useCleaningGroups();
const { successNotification, errorNotification } = useNotification();

const deleteCleaningGroupRequest = useRequest({
  promise: () => deleteCleaningGroup(props.cleaningGroup.id),
  onSuccess: () => {
    successNotification("cleaning_groups.delete.success");
    emit("deleted");
    emit("close");
  },
  onFailure: () => {
    errorNotification("cleaning_groups.delete.error");
  },
});
</script>
