import createOwner from "@/clients/owners/CreateOwner/CreateOwner";
import deleteOwner from "@/clients/owners/DeleteOwner/DeleteOwner";
import searchOwners from "@/clients/owners/SearchOwners/SearchOwners";
import updateOwner from "@/clients/owners/UpdateOwner/UpdateOwner";

export default {
  searchOwners,
  createOwner,
  updateOwner,
  deleteOwner,
};
