import type UpdateAccountSeasonRequest from "@/clients/account-seasons/UpdateAccountSeasons/request/UpdateAccountSeasonRequest";
import type UpdateAccountSeasonsResponse from "@/clients/account-seasons/UpdateAccountSeasons/response/UpdateAccountSeasonsResponse";
import type { AccountSeason, AccountSeasonType, UnsavedAccountSeason } from "@/contexts/accounts/models/AccountSeason";

const toUpdateAccountSeasonRequest = (season: UnsavedAccountSeason): UpdateAccountSeasonRequest => {
  return {
    type: season.type,
    amount: season.amount,
  };
};

const fromUpdateAccountSeasonsResponse = (response: UpdateAccountSeasonsResponse): AccountSeason => {
  return {
    id: response.data.id,
    year: response.data.year,
    week: response.data.week,
    type: response.data.type as AccountSeasonType,
    amount: response.data.amount,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  toUpdateAccountSeasonRequest,
  fromUpdateAccountSeasonsResponse,
};
