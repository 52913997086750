import type UserResponse from "@/clients/auth/User/response/UserResponse";
import axios from "@/libs/axios";

export default async function user(): Promise<UserResponse> {
  try {
    const { data } = await axios.get<UserResponse>("/user");
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
