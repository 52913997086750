import type BookingCleaningsByTypeResponse from "@/clients/analytics/BookingCleaningsByType/response/BookingCleaningsByTypeResponse";
import axios from "@/libs/axios";

export default async function bookingCleaningsByType(): Promise<BookingCleaningsByTypeResponse> {
  try {
    const { data, headers } = await axios.get<BookingCleaningsByTypeResponse>("/analytics/bookings-cleanings/by-type");
    return Promise.resolve({ ...data, total: Number(headers["x-total-count"] ?? 0) });
  } catch (e) {
    return Promise.reject(e);
  }
}
