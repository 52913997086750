import type CreateAccommodationPhotoResponse from "@/clients/accommodation-photos/CreateAccommodationPhoto/response/CreateAccommodationPhotoResponse";
import type SortAccommodationPhotosRequest from "@/clients/accommodation-photos/SortAccommodationPhotos/request/SortAccommodationPhotosRequest";
import axios from "@/libs/axios";

export default async function sortAccommodationPhotos(accommodationId: string, request: SortAccommodationPhotosRequest): Promise<void> {
  try {
    await axios.put<CreateAccommodationPhotoResponse>(`/accommodations/${accommodationId}/photos/sort`, request);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
