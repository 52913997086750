import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { Owner } from "@/contexts/owners/models/Owner";
import type { paths } from "@/libs/api/schema";

const fromShowOwnerResponse = (response: paths["/owners/{owner}"]["get"]["responses"][200]["content"]["application/json"] | undefined): Owner => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return {
    id: response.data.id,
    name: response.data.name,
    email: response.data.email,
    language: response.data.language,
    phones: response.data.phones ?? [],
    notes: response.data.notes,
    default_bank_account_id: response.data.default_bank_account_id,
    bank_accounts_count: response.data.bank_accounts_count,
    accommodations_count: response.data.accommodations_count,
    notifications_count: response.data.notifications_count,
    read_notifications_count: response.data.read_notifications_count,
    tokens_count: response.data.tokens_count,
    unread_notifications_count: response.data.unread_notifications_count,
    accommodations: response.data.accommodations?.map((item) => accommodationFromResponse(item)),
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  fromShowOwnerResponse,
};
