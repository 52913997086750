<template>
  <form
    class="w-52 space-y-2.5"
    @submit.prevent="$emit('submit', form)"
  >
    <FormGroup>
      <FormLabel>{{ $t("accounts.seasons.props.type") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="form.type"
          :options="typeOptions"
          name="type"
        />
      </FormInput>
    </FormGroup>
    <FormGroup>
      <FormLabel>{{ $t("accounts.seasons.props.amount") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="form.amount"
          :max="100"
          :min="0"
          name="amount"
        />
      </FormInput>
    </FormGroup>
    <div class="flex justify-end">
      <button
        class="btn-primary"
        type="submit"
        @click.prevent="$emit('submit', form)"
      >
        {{ $t("shared.form.save") }}
      </button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import type { AccountSeason, UnsavedAccountSeason } from "@/contexts/accounts/models/AccountSeason";
import type { Option } from "@/contexts/shared/models/Select";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";

const props = defineProps<{
  year: number;
  week: number;
  season: AccountSeason | null;
}>();

defineEmits<{
  submit: [value: UnsavedAccountSeason];
}>();

const { t: $t } = useI18n();

const form = ref<UnsavedAccountSeason>({ type: props.season?.type ?? "f", year: props.season?.year ?? props.year, week: props.season?.week ?? props.week, amount: props.season?.amount ?? 50 });

const typeOptions = computed<Option[]>(() => [
  { value: "a", label: $t("accounts.seasons.type.a") },
  { value: "b", label: $t("accounts.seasons.type.b") },
  { value: "c", label: $t("accounts.seasons.type.c") },
  { value: "d", label: $t("accounts.seasons.type.d") },
  { value: "e", label: $t("accounts.seasons.type.e") },
  { value: "f", label: $t("accounts.seasons.type.f") },
]);
</script>
