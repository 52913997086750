<template>
  <div class="border border-gray-200">
    <div class="m-2">
      <label class="block text-sm font-medium text-gray-700">{{ $t("shared.map.search") }}</label>
      <input
        ref="input"
        class="mt-1 block w-full rounded border-gray-300 px-2 py-1 text-sm shadow-sm focus:border-primary focus:ring-primary"
        placeholder=""
        type="text"
      />
    </div>
    <MapRoot
      ref="map"
      class="h-80"
    />
  </div>
</template>

<script lang="ts" setup>
import type { GMapsAddress, GMapsCoordinates } from "@/contexts/shared/composables/useMaps";
import useMaps from "@/contexts/shared/composables/useMaps";
import MapRoot from "@/contexts/shared/ui/components/map/MapRoot.vue";
import type { MapRootInstance } from "@/types/instances";

const emit = defineEmits<{
  select: [value: GMapsAddress];
}>();

const { t: $t } = useI18n();
const { searchMapInput, mapLatLngBounds } = useMaps();

const input = ref<HTMLInputElement>();
const map = ref<MapRootInstance>();

const load = (coordinates?: GMapsCoordinates) => {
  map.value?.load();
  if (coordinates) {
    map.value?.setCenter(coordinates);
    map.value?.setZoom(16);
  }
  map.value?.loader.loadCallback(() => {
    const searchBox = searchMapInput(input.value as HTMLInputElement);
    const address: GMapsAddress = {};
    map.value?.map?.addListener("bounds_changed", () => {
      searchBox.setBounds(mapLatLngBounds());
    });
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places?.length === 0) return;
      map.value?.removeAllMarkers();
      const bounds = mapLatLngBounds();
      if (places) {
        for (const place of places) {
          if (!place.geometry || !place.geometry.location) continue;

          address.lat = place.geometry.location.lat();
          address.lng = place.geometry.location.lng();
          address.street = place.address_components?.find((item) => item.types.includes("route"))?.long_name ?? undefined;

          if (address.street && place.address_components?.find((item) => item.types.includes("street_number"))) {
            address.street = `${address.street}, ${place.address_components?.find((item) => item.types.includes("street_number"))?.long_name}`;
          }

          address.city = place.address_components?.find((item) => item.types.includes("locality") || item.types.includes("postal_town"))?.long_name ?? undefined;
          address.number = place.address_components?.find((item) => item.types.includes("street_number"))?.long_name ?? undefined;
          address.postal_code = place.address_components?.find((item) => item.types.includes("postal_code"))?.long_name ?? undefined;
          address.country_code = place.address_components?.find((item) => item.types.includes("country"))?.short_name?.toLowerCase() ?? undefined;

          map.value?.addMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }, place.name);
          map.value?.setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
          map.value?.setZoom(16);

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }

          emit("select", address);
        }
      }
      map.value?.map?.fitBounds(bounds);
    });
  });
};

defineExpose({
  load,
  setZoom: (zoom: number) => map.value?.setZoom(zoom),
  setCenter: (coordinates: GMapsCoordinates) => map.value?.setCenter(coordinates),
  addMarker: async (coordinates: GMapsCoordinates, title?: string) => await map.value?.addMarker(coordinates, title),
});
</script>
