import AccommodationPhotosClient from "@/clients/accommodation-photos";
import CreateAccommodationPhotoFactory from "@/contexts/accommodation-photos/composables/factories/CreateAccommodationPhotoFactory";
import ExportAccommodationPhotosFactory from "@/contexts/accommodation-photos/composables/factories/ExportAccommodationPhotosFactory";
import GetAccommodationPhotosFactory from "@/contexts/accommodation-photos/composables/factories/GetAccommodationPhotosFactory";
import SortAccommodationPhotosFactory from "@/contexts/accommodation-photos/composables/factories/SortAccommodationPhotosFactory";
import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";

export default function useAccommodationPhotos() {
  const createAccommodationPhoto = async (accommodationId: string, file: File): Promise<AccommodationPhoto> => {
    return CreateAccommodationPhotoFactory.fromCreateAccommodationPhotoResponse(await AccommodationPhotosClient.createAccommodationPhoto(accommodationId, file));
  };
  const getAccommodationPhotos = async (accommodationId: string): Promise<AccommodationPhoto[]> => {
    return GetAccommodationPhotosFactory.fromGetAccommodationPhotosResponse(await AccommodationPhotosClient.getAccommodationPhotos(accommodationId));
  };
  const exportAccommodationPhotos = async (accommodationId: string): Promise<string> => {
    return ExportAccommodationPhotosFactory.fromExportAccommodationPhotosResponse(await AccommodationPhotosClient.exportAccommodationPhotos(accommodationId));
  };
  const sortAccommodationPhotos = async (accommodationId: string, photos: AccommodationPhoto[]): Promise<void> => {
    return await AccommodationPhotosClient.sortAccommodationPhotos(accommodationId, SortAccommodationPhotosFactory.toSortAccommodationPhotosRequest(photos));
  };
  const deleteAccommodationPhoto = async (accommodationId: string, id: string): Promise<void> => {
    return await AccommodationPhotosClient.deleteAccommodationPhoto(accommodationId, id);
  };

  return {
    createAccommodationPhoto,
    getAccommodationPhotos,
    deleteAccommodationPhoto,
    sortAccommodationPhotos,
    exportAccommodationPhotos,
  };
}
