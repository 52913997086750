import type CreateAccommodationPhotoResponse from "@/clients/accommodation-photos/CreateAccommodationPhoto/response/CreateAccommodationPhotoResponse";
import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";

const fromCreateAccommodationPhotoResponse = (response: CreateAccommodationPhotoResponse): AccommodationPhoto => {
  return {
    id: response.data.id,
    accommodation_id: response.data.accommodation_id,
    url: response.data.url,
    name: response.data.name,
    extension: response.data.extension,
    size: response.data.size,
    order: response.data.order,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  fromCreateAccommodationPhotoResponse,
};
