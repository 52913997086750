import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import SearchBookingCleaningsPlannerFactory from "@/contexts/booking-cleanings/composables/factories/SearchBookingCleaningsPlannerFactory";
import type { SearchBookingCleaningsPlannerCriteria } from "@/contexts/booking-cleanings/models/SearchBookingCleaningsPlannerCriteria";
import type { Booking } from "@/contexts/bookings/models/Booking";
import api from "@/libs/api";

export default function useBookingCleaningsPlanner() {
  const searchBookingCleaningsPlanner = async (criteria: SearchBookingCleaningsPlannerCriteria): Promise<{ accommodations: Accommodation[]; bookings: Booking[] }> => {
    const { data } = await api.GET("/booking-cleanings/planner", { params: { query: SearchBookingCleaningsPlannerFactory.toSearchBookingCleaningsPlannerRequest(criteria) } });
    return Promise.resolve(SearchBookingCleaningsPlannerFactory.fromSearchBookingCleaningsPlannerResponse(data));
  };

  return {
    searchBookingCleaningsPlanner,
  };
}
