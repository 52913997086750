<template>
  <div class="flex items-start justify-between space-x-2 border-b border-gray-100 pb-3 sm:items-center">
    <div class="space-y-2 sm:flex sm:items-center sm:space-x-2 sm:space-y-0">
      <slot />
    </div>
    <button
      :disabled="isEmpty"
      class="flex items-center space-x-1.5 whitespace-nowrap text-sm text-gray-600 enabled:hover:text-gray-800 disabled:text-gray-400"
      @click.prevent="$emit('clear')"
    >
      <FontAwesomeIcon
        class="h-3.5 w-3.5 fill-current"
        icon="xmark-solid"
      />
      <div>{{ $t("shared.filters.clear") }}</div>
    </button>
  </div>
</template>

<script lang="ts" setup>
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

defineProps<{
  isEmpty: boolean;
}>();

defineEmits<{
  clear: [];
}>();

const { t: $t } = useI18n();
</script>
