import AuthLayout from "@/contexts/shared/ui/layouts/AuthLayout.vue";
import DashboardLayout from "@/contexts/shared/ui/layouts/DashboardLayout.vue";
import EmptyLayout from "@/contexts/shared/ui/layouts/EmptyLayout.vue";
import type { DefineComponent } from "vue";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
// biome-ignore lint/complexity/noBannedTypes: <explanation>
export const layouts: Record<string, DefineComponent<{}, {}, any>> = {
  dashboard: DashboardLayout,
  auth: AuthLayout,
  empty: EmptyLayout,
};

export type LayoutTypes = keyof typeof layouts;
