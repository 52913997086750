<template>
  <div :class="cn('relative flex items-start gap-2', { 'flex-row-reverse': reversed })">
    <div class="min-w-0 flex-1 text-sm">
      <label
        :for="id"
        class="select-none font-medium text-gray-700"
      >
        <slot />
      </label>
    </div>
    <div class="flex h-5 items-center">
      <FormCheckbox
        :id="id"
        v-model="modelValue"
        :name="name"
        :value="value"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import FormCheckbox from "@/contexts/shared/ui/components/form/FormCheckbox.vue";
import cn from "@/contexts/shared/utils/cn";
import { v4 as uuid } from "uuid";

const props = defineProps<{
  id: string;
  name: string;
  errors?: string[];
  value?: string | number | boolean;
  reversed?: boolean;
}>();

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const modelValue = defineModel<any[] | Set<any> | boolean | undefined>({ default: [] });

provide<string>("id", props.id ?? uuid());
</script>
