import axios from "@/libs/axios";

export default async function csrf(): Promise<void> {
  try {
    await axios.get("/sanctum/csrf-cookie");
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
