import type { AccommodationExtra } from "@/contexts/accommodations/models/AccommodationExtra";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { components } from "@/libs/api/schema";

export const accommodationExtraFromResponse = (response: components["schemas"]["AccommodationExtra"]): AccommodationExtra => {
  return {
    id: response.id,
    name: response.name,
    mode: response.mode,
    type: response.type,
    payment_time: response.payment_time,
    price: response.price,
    conditions: {
      applicable_period: response.conditions.applicable_period
        ? {
            only_applicable_from: response.conditions.applicable_period.only_applicable_from ? new Date(response.conditions.applicable_period.only_applicable_from) : null,
            only_applicable_until: response.conditions.applicable_period.only_applicable_until ? new Date(response.conditions.applicable_period.only_applicable_until) : null,
          }
        : null,
      number_of_nights: response.conditions.number_of_nights
        ? {
            from_stay_length_in_nights: response.conditions.number_of_nights.from_stay_length_in_nights ?? null,
            to_stay_length_in_nights: response.conditions.number_of_nights.to_stay_length_in_nights ?? null,
          }
        : null,
    },
    maximum_units: response.maximum_units,
    accommodation_id: response.accommodation_id,
    accommodation: response.accommodation ? accommodationFromResponse(response.accommodation) : undefined,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
