import type { paths } from "@/libs/api/schema";
import { isArray } from "lodash-es";

const toConfirmBookingsRequest = (id: string | string[]): paths["/bookings/confirm"]["patch"]["requestBody"]["content"]["application/json"] => {
  return {
    id: isArray(id) ? id : [id],
  };
};

export default {
  toConfirmBookingsRequest,
};
