<template>
  <div ref="target">
    <template v-if="searchOwnerBankAccountsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchOwnerBankAccountsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchOwnerBankAccountsRequest.data.length === 0">
      <SectionEmptyState>
        {{ $t("owners.manage.bank_accounts.empty") }}
      </SectionEmptyState>
    </template>
    <template v-else>
      <OwnerBankAccountsTable
        :bank-accounts="searchOwnerBankAccountsRequest.data"
        :default-bank-account-id="owner.default_bank_account_id"
        @updated="searchOwnerBankAccountsRequest.fetch()"
        @deleted="searchOwnerBankAccountsRequest.fetch()"
        @selected-default="$emit('selectedDefault', $event.id)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import useOwnerBankAccounts from "@/contexts/owner-bank-accounts/composables/useOwnerBankAccounts";
import type { OwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import OwnerBankAccountsTable from "@/contexts/owner-bank-accounts/ui/components/OwnerBankAccountsTable.vue";
import type { Owner } from "@/contexts/owners/models/Owner";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

const props = defineProps<{
  owner: Owner;
}>();

defineEmits<{
  selectedDefault: [id: string];
}>();

const { t: $t } = useI18n();
const { searchOwnerBankAccounts } = useOwnerBankAccounts();

const target = ref<HTMLElement>();
useVisibility(target, () => searchOwnerBankAccountsRequest.fetch());

const searchOwnerBankAccountsRequest: UseRequest<OwnerBankAccount[]> = useRequest<OwnerBankAccount[]>({
  initialLoading: true,
  value: [],
  promise: () => searchOwnerBankAccounts(props.owner.id),
});

defineExpose({ fetch: () => searchOwnerBankAccountsRequest.fetch() });
</script>
