<template>
  <FormGroup :errors="errors">
    <FormLabel>{{ $t("accommodations.prices.bulk.form.weekday_limit") }}</FormLabel>
    <FormInput>
      <div class="flex items-center space-x-3">
        <template
          v-for="weekday in weekdays"
          :key="weekday"
        >
          <FormCheckboxWithLabel
            :id="String(weekday.id)"
            v-model="modelValue"
            reversed
            :value="weekday.id"
            :name="weekday.name"
          >
            {{ $t(`shared.dayjs.weekdays`).split("_")[weekday.id] }}
          </FormCheckboxWithLabel>
        </template>
      </div>
    </FormInput>
  </FormGroup>
</template>

<script setup lang="ts">
import FormCheckboxWithLabel from "@/contexts/shared/ui/components/form/FormCheckboxWithLabel.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";

defineProps<{
  errors?: string[];
}>();

const modelValue = defineModel<(0 | 1 | 2 | 3 | 4 | 5 | 6)[] | undefined>({ default: [0, 1, 2, 3, 4, 5, 6] });

const { t: $t } = useI18n();

watch(modelValue, (newValue) => newValue?.sort((a, b) => a - b));

const weekdays = [
  { id: 1, name: "monday" },
  { id: 2, name: "tuesday" },
  { id: 3, name: "wednesday" },
  { id: 4, name: "thursday" },
  { id: 5, name: "friday" },
  { id: 6, name: "saturday" },
  { id: 0, name: "sunday" },
];
</script>
