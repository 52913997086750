<template>
  <ConfirmDeleteModal
    :is-loading="deleteAccommodationRequest.isLoading"
    description="accommodations.delete.description"
    title="accommodations.delete.title"
    @close="$emit('close')"
    @confirm="deleteAccommodationRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  close: [];
  deleted: [];
}>();

const { deleteAccommodation } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

const deleteAccommodationRequest = useRequest({
  promise: () => deleteAccommodation(props.accommodation.id),
  onSuccess: () => {
    successNotification("accommodations.delete.success");
    emit("deleted");
    emit("close");
  },
  onFailure: () => {
    errorNotification("accommodations.delete.error");
  },
});
</script>
