<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.update.details") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form @submit.prevent="submit">
      <SectionGroup>
        <section>
          <AccommodationsDetailsForm
            v-model="input"
            :errors="updateAccommodationDetailsRequest.errors"
          />
        </section>
      </SectionGroup>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Accommodation, UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import AccommodationsDetailsForm from "@/contexts/accommodations/ui/components/AccommodationsDetailsForm.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import { cloneDeep } from "lodash-es";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: Accommodation];
}>();

const { t: $t } = useI18n();
const { updateAccommodationDetails, empty } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

onMounted(() => {
  input.value = cloneDeep(props.accommodation);
});

const input = ref<UnsavedAccommodation>(empty);

const updateAccommodationDetailsRequest = useRequest({
  promise: () => updateAccommodationDetails(props.accommodation.id, input.value),
  onSuccess: (response) => {
    successNotification("accommodations.update.success");
    emit("updated", response);
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.update.error");
    }
  },
});

const submit = async () => {
  await updateAccommodationDetailsRequest.fetch();
};
</script>
