import type CreateCleaningGroupRequest from "@/clients/cleaning-groups/CreateCleaningGroup/request/CreateCleaningGroupRequest";
import type CreateCleaningGroupResponse from "@/clients/cleaning-groups/CreateCleaningGroup/response/CreateCleaningGroupResponse";
import type { CleaningGroup, UnsavedCleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";

const toCreateCleaningGroupRequest = (cleaningGroup: UnsavedCleaningGroup): CreateCleaningGroupRequest => {
  return {
    name: cleaningGroup.name,
  };
};

const fromCreateCleaningGroupResponse = (response: CreateCleaningGroupResponse): CleaningGroup => {
  return {
    id: response.data.id,
    name: response.data.name,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
    archived_at: response.data.archived_at ? new Date(response.data.archived_at) : null,
  };
};

export default {
  toCreateCleaningGroupRequest,
  fromCreateCleaningGroupResponse,
};
