import type GetAccommodationPhotosResponse from "@/clients/accommodation-photos/GetAccommodationPhotos/response/GetAccommodationPhotosResponse";
import axios from "@/libs/axios";

export default async function getAccommodationPhotos(accommodationId: string): Promise<GetAccommodationPhotosResponse> {
  try {
    const { data } = await axios.get<GetAccommodationPhotosResponse>(`/accommodations/${accommodationId}/photos`);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
