import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { UnsavedAccommodationBedroom } from "@/contexts/accommodations/models/AccommodationRooms";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { paths } from "@/libs/api/schema";

const toUpdateAccommodationBedroomsRequest = (bedrooms: UnsavedAccommodationBedroom[]): paths["/accommodations/{accommodation}/bedrooms"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    bedrooms: bedrooms.map((item) => ({
      id: item.id ?? null,
      type: item.type,
      floor: item.floor,
      single_beds: item.single_beds,
      double_beds: item.double_beds,
      suite_beds: item.suite_beds,
      bunk_beds: item.bunk_beds,
    })),
  };
};

const fromUpdateAccommodationBedroomsResponse = (response: paths["/accommodations/{accommodation}/bedrooms"]["put"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  toUpdateAccommodationBedroomsRequest,
  fromUpdateAccommodationBedroomsResponse,
};
