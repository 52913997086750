import type CreateAccommodationRequest from "@/clients/accommodations/CreateAccommodation/request/CreateAccommodationRequest";
import axios from "@/libs/axios";

export default async function createAccommodation(request: CreateAccommodationRequest): Promise<void> {
  try {
    await axios.post("/accommodations", request);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
