import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { UnsavedAccommodationExtra } from "@/contexts/accommodations/models/AccommodationExtra";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { paths } from "@/libs/api/schema";
import dayjs from "dayjs";

const toUpdateAccommodationExtrasRequest = (extras: UnsavedAccommodationExtra[]): paths["/accommodations/{accommodation}/extras"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    extras: extras.map((item) => ({
      id: item.id ?? null,
      name: item.name,
      mode: item.mode,
      type: item.type,
      payment_time: item.payment_time,
      price: item.price,
      conditions: {
        applicable_period:
          item.conditions.applicable_period?.only_applicable_from || item.conditions.applicable_period?.only_applicable_until
            ? {
                only_applicable_from: item.conditions.applicable_period.only_applicable_from ? dayjs(item.conditions.applicable_period.only_applicable_from).format("YYYY-MM-DD") : null,
                only_applicable_until: item.conditions.applicable_period.only_applicable_until ? dayjs(item.conditions.applicable_period.only_applicable_until).format("YYYY-MM-DD") : null,
              }
            : null,
        number_of_nights:
          item.conditions.number_of_nights?.from_stay_length_in_nights || item.conditions.number_of_nights?.to_stay_length_in_nights
            ? {
                from_stay_length_in_nights: item.conditions.number_of_nights.from_stay_length_in_nights ?? null,
                to_stay_length_in_nights: item.conditions.number_of_nights.to_stay_length_in_nights ?? null,
              }
            : null,
      },
      maximum_units: item.maximum_units,
    })),
  };
};

const fromUpdateAccommodationExtrasResponse = (response: paths["/accommodations/{accommodation}/extras"]["put"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  toUpdateAccommodationExtrasRequest,
  fromUpdateAccommodationExtrasResponse,
};
