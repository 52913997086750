import archiveCleaningGroup from "@/clients/cleaning-groups/ArchiveCleaningGroup/ArchiveCleaningGroup";
import createCleaningGroup from "@/clients/cleaning-groups/CreateCleaningGroup/CreateCleaningGroup";
import deleteCleaningGroup from "@/clients/cleaning-groups/DeleteCleaningGroup/DeleteCleaningGroup";
import searchCleaningGroups from "@/clients/cleaning-groups/SearchCleaningGroups/SearchCleaningGroups";
import showCleaningGroup from "@/clients/cleaning-groups/ShowCleaningGroup/ShowCleaningGroup";
import unarchiveCleaningGroup from "@/clients/cleaning-groups/UnarchiveCleaningGroup/UnarchiveCleaningGroup";
import updateCleaningGroup from "@/clients/cleaning-groups/UpdateCleaningGroup/UpdateCleaningGroup";

export default {
  searchCleaningGroups,
  createCleaningGroup,
  showCleaningGroup,
  updateCleaningGroup,
  deleteCleaningGroup,
  archiveCleaningGroup,
  unarchiveCleaningGroup,
};
