<template>
  <DropdownMenu>
    <template #button>
      <slot />
    </template>
    <DropdownMenuItemButton @click="updateAccountServiceModal?.open()">
      {{ $t("accounts.services.actions.edit") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      class="text-red-600 hover:text-red-700"
      @click="deleteAccountServiceModal?.open()"
    >
      {{ $t("accounts.services.actions.delete") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    ref="updateAccountServiceModal"
    v-slot="{ close }"
  >
    <AccountServicesUpdateModal
      :account-service="accountService"
      @close="close"
      @updated="$emit('updated')"
    />
  </ModalDialog>
  <ModalDialog
    ref="deleteAccountServiceModal"
    v-slot="{ close }"
  >
    <AccountServicesDeleteModal
      :account-service="accountService"
      @close="close"
      @deleted="$emit('deleted')"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import type { AccountService } from "@/contexts/accounts/models/AccountService";
import AccountServicesDeleteModal from "@/contexts/accounts/ui/modals/AccountServicesDeleteModal.vue";
import AccountServicesUpdateModal from "@/contexts/accounts/ui/modals/AccountServicesUpdateModal.vue";
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import type { ModalDialogInstance } from "@/types/instances";

defineProps<{
  accountService: AccountService;
}>();

defineEmits<{
  updated: [];
  deleted: [];
}>();

const { t: $t } = useI18n();

const updateAccountServiceModal = ref<ModalDialogInstance>();
const deleteAccountServiceModal = ref<ModalDialogInstance>();
</script>
