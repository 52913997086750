import createAccommodationPhoto from "@/clients/accommodation-photos/CreateAccommodationPhoto/CreateAccommodationPhoto";
import deleteAccommodationPhoto from "@/clients/accommodation-photos/DeleteAccommodationPhoto/DeleteAccommodationPhoto";
import exportAccommodationPhotos from "@/clients/accommodation-photos/ExportAccommodationPhotos/ExportAccommodationPhotos";
import getAccommodationPhotos from "@/clients/accommodation-photos/GetAccommodationPhotos/GetAccommodationPhotos";
import sortAccommodationPhotos from "@/clients/accommodation-photos/SortAccommodationPhotos/SortAccommodationPhotos";

export default {
  createAccommodationPhoto,
  getAccommodationPhotos,
  deleteAccommodationPhoto,
  sortAccommodationPhotos,
  exportAccommodationPhotos,
};
