<template>
  <ConfirmModal
    :is-loading="approveAccommodationContractRequest.isLoading"
    description="accommodation_contracts.approve.description"
    title="accommodation_contracts.approve.title"
    @close="$emit('close')"
    @confirm="approveAccommodationContractRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useAccommodationContracts from "@/contexts/accommodation-contracts/composables/useAccommodationContracts";
import type { AccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ConfirmModal from "@/contexts/shared/ui/modals/ConfirmModal.vue";

const props = defineProps<{
  contract: AccommodationContract;
}>();

const emit = defineEmits<{
  close: [];
  approved: [];
}>();

const { approveAccommodationContract } = useAccommodationContracts();
const { successNotification, errorNotification } = useNotification();

const approveAccommodationContractRequest = useRequest({
  keepLoading: true,
  promise: () => approveAccommodationContract(props.contract.accommodation_id, props.contract.id),
  onSuccess: () => {
    successNotification("accommodation_contracts.approve.success");
    emit("approved");
    emit("close");
  },
  onFailure: () => {
    errorNotification("accommodation_contracts.approve.error");
  },
});
</script>
