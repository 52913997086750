<template>
  <header>
    <div class="w-full border-b border-gray-100 bg-gray-50 text-gray-800">
      <div class="mx-auto flex max-w-screen-2xl items-center justify-between px-4 py-1.5 md:px-10">
        <div class="-ml-2 flex items-center lg:ml-0">
          <button
            class="mr-2 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary lg:hidden"
            type="button"
            @click="sidebarOpen = true"
          >
            <FontAwesomeIcon
              class="h-4 w-4 fill-gray-800"
              icon="bars-solid"
            />
          </button>
          <MenuRoot
            as="div"
            class="relative"
          >
            <MenuButton class="flex items-center rounded-lg p-1 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <img
                v-if="authenticatedAccount?.icon"
                :src="authenticatedAccount.icon"
                alt="Account icon"
                class="h-7 w-7 rounded"
              />
              <div
                v-else
                class="h-7 w-7 rounded bg-gray-200 text-center text-xl font-semibold text-primary"
              >
                {{ authenticatedAccount?.name.toUpperCase().charAt(0) }}
              </div>
              <div class="ml-2 max-w-[11rem] truncate text-left text-sm font-medium text-gray-800">
                {{ authenticatedAccount?.name }}
              </div>
              <FontAwesomeIcon
                class="ml-3 h-3 w-3 fill-gray-800"
                icon="chevron-down-solid"
              />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems class="absolute left-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <template
                  v-for="(account, index) in accounts"
                  :key="index"
                >
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[active ? 'bg-gray-50' : '', 'flex w-full cursor-pointer items-center px-3 py-2 first:rounded-t-md']"
                      type="button"
                      @click="selectAccount(account)"
                    >
                      <img
                        v-if="account.icon"
                        :src="account.icon"
                        alt="Account icon"
                        class="h-7 w-7 rounded"
                      />
                      <div
                        v-else
                        class="h-7 w-7 rounded bg-gray-200 text-center text-xl font-semibold text-primary"
                      >
                        {{ account.name.toUpperCase().charAt(0) }}
                      </div>
                      <div class="ml-3.5 max-w-[11rem] truncate text-left text-sm text-gray-800">
                        {{ account.name }}
                      </div>
                    </button>
                  </MenuItem>
                </template>
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[active ? 'bg-gray-50' : '', 'flex w-full cursor-pointer items-center rounded-b-md px-3 py-2']"
                    type="button"
                    @click="createAccountModal?.open()"
                  >
                    <div class="flex items-center">
                      <div class="flex h-7 w-7 rounded-full bg-gray-200">
                        <FontAwesomeIcon
                          class="m-auto mr-1.5 h-3.5 w-3.5 fill-current"
                          icon="plus-solid"
                        />
                      </div>
                      <div class="ml-3.5 text-left text-sm text-gray-800">
                        {{ $t("accounts.create.title") }}
                      </div>
                    </div>
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </MenuRoot>
        </div>
        <div class="flex md:items-center md:space-x-2">
          <button
            class="rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
            type="button"
          >
            <FontAwesomeIcon
              class="h-4 w-4 fill-gray-800"
              icon="magnifying-glass-solid"
            />
          </button>
          <RouterLink
            :to="{ name: 'settings' }"
            class="rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
          >
            <FontAwesomeIcon
              :class="isActiveRoute('settings') ? 'fill-primary' : 'fill-gray-800 hover:fill-gray-900'"
              class="h-4 w-4"
              icon="gear-solid"
            />
          </RouterLink>
          <MenuRoot
            as="div"
            class="relative"
          >
            <MenuButton as="div">
              <button class="rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                <FontAwesomeIcon
                  :class="isActiveRoute('profile') ? 'fill-primary' : 'fill-gray-800 hover:fill-gray-900'"
                  class="h-4 w-4"
                  icon="user-large-solid"
                />
              </button>
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems class="absolute right-0 z-10 mt-3 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="border-b border-gray-100 px-4 py-2">
                  <div class="truncate font-semibold text-gray-800">
                    {{ authenticatedUser?.name }}
                  </div>
                  <div class="mb-1 truncate text-sm text-gray-800">
                    {{ authenticatedUser?.email }}
                  </div>
                </div>
                <template v-if="needRefresh">
                  <MenuItem v-slot="{ active }">
                    <button
                      type="button"
                      :class="[active ? 'bg-gray-50' : '', 'block w-full px-4 py-2 text-left text-sm text-gray-800']"
                      @click="updateServiceWorker()"
                    >
                      {{ $t("shared.update") }}
                    </button>
                  </MenuItem>
                </template>
                <MenuItem v-slot="{ active }">
                  <RouterLink
                    :class="[active ? 'bg-gray-50' : '', 'block w-full px-4 py-2 text-left text-sm text-gray-800']"
                    :to="{ name: 'profile' }"
                  >
                    {{ $t("profile.title") }}
                  </RouterLink>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[active ? 'bg-gray-50' : '', 'block w-full px-4 py-2 text-left text-sm text-gray-800']"
                    type="button"
                    @click="signOut"
                  >
                    {{ $t("shared.sign_out") }}
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </MenuRoot>
        </div>
      </div>
    </div>
    <div class="hidden w-full border-b border-gray-100 bg-white text-gray-800 md:block">
      <div class="mx-auto flex max-w-screen-2xl items-center justify-between px-4 py-1.5 md:px-10">
        <div class="flex items-center space-x-3">
          <template
            v-for="(item, index) in navigation.filter((i) => !(i.disabled ?? false))"
            :key="index"
          >
            <RouterLink
              :class="isActiveRoute(item.group) ? 'bg-primary text-white hover:bg-opacity-80' : 'hover:bg-gray-200'"
              :to="item.to"
              class="inline-flex items-center whitespace-nowrap rounded-2xl px-2.5 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              {{ $t(item.text) }}
            </RouterLink>
          </template>
        </div>
      </div>
    </div>
    <TransitionRoot
      :show="sidebarOpen"
      as="template"
    >
      <DialogRoot
        as="div"
        class="relative z-40 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute right-0 top-0 -mr-9 pt-2">
                  <button
                    class="relative ml-1 flex items-center justify-center rounded-lg p-1 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    type="button"
                    @click="sidebarOpen = false"
                  >
                    <span class="absolute -inset-0.5" />
                    <FontAwesomeIcon
                      class="h-5 w-5 fill-white"
                      icon="xmark-solid"
                    />
                  </button>
                </div>
              </TransitionChild>
              <div class="w-full space-y-3">
                <div class="flex w-full items-center border-b border-gray-100 bg-gray-50 px-4 py-3">
                  <img
                    v-if="authenticatedAccount?.icon"
                    :src="authenticatedAccount.icon"
                    alt="Account icon"
                    class="h-8 w-8 rounded"
                  />
                  <div
                    v-else
                    class="flex h-8 w-8 rounded bg-gray-200 text-xl font-semibold text-primary"
                  >
                    <div class="m-auto">{{ authenticatedAccount?.name.toUpperCase().charAt(0) }}</div>
                  </div>
                  <div class="ml-4 truncate text-left font-medium text-gray-800">
                    {{ authenticatedAccount?.name }}
                  </div>
                </div>
                <div class="flex flex-col space-y-2 px-2.5">
                  <template
                    v-for="(item, index) in navigation.filter((i) => !(i.disabled ?? false))"
                    :key="index"
                  >
                    <RouterLink
                      :class="isActiveRoute(item.group) ? 'bg-gray-50/80 font-semibold text-primary hover:bg-opacity-80' : 'font-medium text-gray-700 hover:bg-gray-50 hover:text-primary'"
                      :to="item.to"
                      class="inline-flex items-center whitespace-nowrap rounded-md px-2.5 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      @click="sidebarOpen = false"
                    >
                      <FontAwesomeIcon
                        :icon="item.icon"
                        class="h-5 w-5 fill-current"
                      />
                      <div class="ml-4">{{ $t(item.text) }}</div>
                    </RouterLink>
                  </template>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </DialogRoot>
    </TransitionRoot>
  </header>
  <div class="mx-auto mb-6 max-w-screen-2xl space-y-5 px-4 md:mb-10 md:px-10">
    <slot />
  </div>
  <ModalDialog
    ref="createAccountModal"
    v-slot="{ close }"
  >
    <AccountsCreateModal @close="close" />
  </ModalDialog>
</template>

<script lang="ts" setup>
import { useRegisterSW } from "virtual:pwa-register/vue";
import type { Account } from "@/contexts/accounts/models/Account";
import AccountsCreateModal from "@/contexts/accounts/ui/modals/AccountsCreateModal.vue";
import useAuth from "@/contexts/auth/composables/useAuth";
import type { FontAwesomeIconTypes } from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import type { ModalDialogInstance } from "@/types/instances";
import { DialogPanel, Dialog as DialogRoot, MenuButton, MenuItem, MenuItems, Menu as MenuRoot, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useEventBus } from "@vueuse/core";
import type { RouteLocationRaw } from "vue-router";

const route = useRoute();
const router = useRouter();
const { t: $t } = useI18n();
const accountBus = useEventBus<string>("account");
const { needRefresh, updateServiceWorker } = useRegisterSW();
const { logout, accounts, changeAccount, authenticatedUser, authenticatedAccount, authenticatedAccountId } = useAuth();

const sidebarOpen = ref<boolean>(false);
const createAccountModal = ref<ModalDialogInstance>();

const navigation = computed<{ to: RouteLocationRaw; text: string; group: string; icon: FontAwesomeIconTypes; disabled?: boolean }[]>(() => [
  { to: { name: "dashboard" }, text: "dashboard.title", group: "dashboard", icon: "gauge-solid" },
  { to: { name: "bookings" }, text: "bookings.title", group: "bookings", icon: "calendar-days-solid" },
  { to: { name: "booking_cleanings" }, text: "booking_cleanings.title", group: "booking_cleanings", icon: "calendar-week-solid" },
  { to: { name: "accommodations" }, text: "accommodations.title", group: "accommodations", icon: "house-building-solid" },
  { to: { name: "owners" }, text: "owners.title", group: "owners", icon: "building-user-solid" },
  { to: { name: "cleaning_groups" }, text: "cleaning_groups.title", group: "cleaning_groups", icon: "broom-solid" },
]);
const isActiveRoute = (group: string): boolean => {
  return route.meta?.group === group;
};
const selectAccount = (account: Account) => {
  if (account.id !== authenticatedAccountId.value) {
    changeAccount(account);
    accountBus.emit(account.id);
  }
};
const signOut = () => {
  logout();
  router.push({ name: "login" });
};
</script>
