<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("booking_cleanings.manage.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form
      class="space-y-4"
      @submit.prevent="submit"
    >
      <!--<template v-if="!bookingCleaning?.type || !bookingCleaning?.datetime">-->
      <!--  <div class="flex items-center rounded border-2 border-primary bg-primary/10 px-3 py-2 text-sm shadow-sm">-->
      <!--    <div class="w-full text-primary">-->
      <!--      {{ $t("booking_cleanings.manage.ai.description") }}-->
      <!--    </div>-->
      <!--    <div class="flex-none">-->
      <!--      <ButtonWithIcon-->
      <!--        class="btn-white text-primary"-->
      <!--        icon="bolt-auto-duotone"-->
      <!--      >-->
      <!--        {{ $t("booking_cleanings.manage.ai.title") }}-->
      <!--      </ButtonWithIcon>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</template>-->
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <FormGroup :errors="createOrUpdateBookingCleaningRequest.errors.type">
          <FormLabel>{{ $t("booking_cleanings.props.type") }}</FormLabel>
          <FormInput>
            <FormSelect
              v-model="form.type"
              :options="cleaningTypeOptions"
              name="type"
            />
          </FormInput>
        </FormGroup>
        <FormGroup :errors="createOrUpdateBookingCleaningRequest.errors.cleaning_group_id">
          <FormLabel>{{ $t("booking_cleanings.props.cleaning_group_id") }}</FormLabel>
          <FormInput>
            <CleaningGroupsSelect
              v-model="form.cleaning_group_id"
              name="cleaning_group_id"
            />
          </FormInput>
        </FormGroup>
        <div class="grid grid-cols-1 gap-4 md:col-span-2">
          <FormGroup :errors="createOrUpdateBookingCleaningRequest.errors.datetime">
            <FormLabel>{{ $t("booking_cleanings.props.datetime") }}</FormLabel>
            <FormInput>
              <FormDatePicker
                v-model="form.datetime"
                name="datetime"
                mode="dateTime"
                :min-date="new Date()"
                :rules="{ minutes: { interval: 15 } }"
              />
            </FormInput>
          </FormGroup>
        </div>
        <div class="md:col-span-2">
          <div class="mb-1.5 grid gap-4 sm:grid-cols-2 md:col-span-2 md:grid-cols-4">
            <FormGroup :errors="createOrUpdateBookingCleaningRequest.errors.single_beds">
              <FormLabel>{{ $t("booking_cleanings.props.single_beds") }}&nbsp;({{ accommodationBeds.single_beds }})</FormLabel>
              <FormInput>
                <FormInteger
                  v-model="form.single_beds"
                  :min="0"
                  :max="accommodationBeds.single_beds"
                  name="single_beds"
                />
              </FormInput>
            </FormGroup>
            <FormGroup :errors="createOrUpdateBookingCleaningRequest.errors.double_beds">
              <FormLabel>{{ $t("booking_cleanings.props.double_beds") }}&nbsp;({{ accommodationBeds.double_beds }})</FormLabel>
              <FormInput>
                <FormInteger
                  v-model="form.double_beds"
                  :min="0"
                  :max="accommodationBeds.double_beds"
                  name="double_beds"
                />
              </FormInput>
            </FormGroup>
            <FormGroup :errors="createOrUpdateBookingCleaningRequest.errors.suite_beds">
              <FormLabel>{{ $t("booking_cleanings.props.suite_beds") }}&nbsp;({{ accommodationBeds.suite_beds }})</FormLabel>
              <FormInput>
                <FormInteger
                  v-model="form.suite_beds"
                  :min="0"
                  :max="accommodationBeds.suite_beds"
                  name="suite_beds"
                />
              </FormInput>
            </FormGroup>
            <FormGroup :errors="createOrUpdateBookingCleaningRequest.errors.bunk_beds">
              <FormLabel>{{ $t("booking_cleanings.props.bunk_beds") }}&nbsp;({{ accommodationBeds.bunk_beds }})</FormLabel>
              <FormInput>
                <FormInteger
                  v-model="form.bunk_beds"
                  :min="0"
                  :max="accommodationBeds.bunk_beds"
                  name="bunk_beds"
                />
              </FormInput>
            </FormGroup>
          </div>
          <template v-if="bedlinen > 0">
            <PillBadge class="pill-badge-green">
              <FontAwesomeIcon
                class="mr-2 h-4 w-4 fill-green-500"
                icon="circle-check-solid"
              />
              {{ $t("booking_cleanings.paid_bedlinen", bedlinen) }}
            </PillBadge>
          </template>
          <template v-else>
            <PillBadge class="pill-badge-red">
              <FontAwesomeIcon
                class="mr-2 h-4 w-4 fill-red-500"
                icon="circle-xmark-solid"
              />
              {{ $t("booking_cleanings.not_paid_bedlinen") }}
            </PillBadge>
          </template>
        </div>
        <div class="grid grid-cols-1 gap-4 md:col-span-2">
          <FormGroup :errors="createOrUpdateBookingCleaningRequest.errors.notes">
            <FormLabel>{{ $t("booking_cleanings.props.notes") }}</FormLabel>
            <FormInput>
              <FormTextarea
                v-model="form.notes"
                name="notes"
              />
            </FormInput>
          </FormGroup>
        </div>
      </div>
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="createOrUpdateBookingCleaningRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script setup lang="ts">
import useBookingCleanings from "@/contexts/booking-cleanings/composables/useBookingCleanings";
import type { BookingCleaning, BookingCleaningAssoc, UnsavedBookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import type { Booking } from "@/contexts/bookings/models/Booking";
import type { BookingExtra } from "@/contexts/bookings/models/BookingExtra";
import CleaningGroupsSelect from "@/contexts/cleaning-groups/ui/components/CleaningGroupsSelect.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import PillBadge from "@/contexts/shared/ui/components/badge/PillBadge.vue";
import FormDatePicker from "@/contexts/shared/ui/components/form/FormDatePicker.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormTextarea from "@/contexts/shared/ui/components/form/FormTextarea.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";

const props = defineProps<{
  assoc: BookingCleaningAssoc;
  booking: Booking;
  bookingCleaning: BookingCleaning | undefined;
}>();

const emit = defineEmits<{
  close: [];
  updatedBookingCleaning: [value: BookingCleaning];
}>();

const { t: $t } = useI18n();
const { successNotification, errorNotification } = useNotification();
const { createBookingCleaning, updateBookingCleaning, cleaningTypeOptions } = useBookingCleanings();

const form = ref<UnsavedBookingCleaning>({
  booking_id: props.booking.id,
  assoc: props.assoc,
  type: props.bookingCleaning?.type ?? null,
  cleaning_group_id: props.bookingCleaning?.cleaning_group_id ?? null,
  datetime: props.bookingCleaning?.datetime ?? null,
  single_beds: props.bookingCleaning?.single_beds ?? 0,
  double_beds: props.bookingCleaning?.double_beds ?? 0,
  suite_beds: props.bookingCleaning?.suite_beds ?? 0,
  bunk_beds: props.bookingCleaning?.bunk_beds ?? 0,
  notes: props.bookingCleaning?.notes ?? null,
});
const accommodationBeds = computed<Record<"single_beds" | "double_beds" | "suite_beds" | "bunk_beds", number>>(() => ({
  single_beds: props.booking.accommodation?.bedrooms.reduce((sum, item) => sum + item.single_beds, 0) ?? 0,
  double_beds: props.booking.accommodation?.bedrooms.reduce((sum, item) => sum + item.double_beds, 0) ?? 0,
  suite_beds: props.booking.accommodation?.bedrooms.reduce((sum, item) => sum + item.suite_beds, 0) ?? 0,
  bunk_beds: props.booking.accommodation?.bedrooms.reduce((sum, item) => sum + item.bunk_beds, 0) ?? 0,
}));
const extras = computed<BookingExtra[]>(() => props.booking.extras ?? []);
const bedlinen = computed<number>(() => extras.value.find((item) => ["bedlinen", "towel_bedlinen_package"].includes(String(item.accommodation_extra?.name)))?.units ?? 0);

const createOrUpdateBookingCleaningRequest = useRequest({
  promise: () => (props.bookingCleaning?.id ? updateBookingCleaning(props.bookingCleaning?.id, form.value) : createBookingCleaning(form.value)),
  onSuccess: (response) => {
    successNotification("booking_cleanings.manage.save.success");
    emit("close");
    emit("updatedBookingCleaning", response);
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("booking_cleanings.manage.save.error");
    }
  },
});

const submit = () => {
  createOrUpdateBookingCleaningRequest.fetch();
};
</script>
