<template>
  <div
    :class="classes"
    class="group relative flex h-36 w-full rounded border p-2"
  >
    <template v-if="selected || value">
      <img
        :src="String(selected || value)"
        alt="Image"
        class="m-auto h-full w-auto"
      />
      <div class="absolute left-0 top-0 hidden h-full w-full rounded bg-gray-800/40 group-hover:flex">
        <div class="m-auto">
          <ButtonWithIcon
            class="btn-white bg-white"
            icon="plus-solid"
            @click="input?.click()"
          >
            {{ $t("shared.form.image.upload") }}
          </ButtonWithIcon>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="m-auto">
        <ButtonWithIcon
          class="btn-white"
          icon="plus-solid"
          @click="input?.click()"
        >
          {{ $t("shared.form.image.upload") }}
        </ButtonWithIcon>
      </div>
    </template>
    <input
      :id="id"
      ref="input"
      accept="image/*"
      class="sr-only"
      name="file-upload"
      tabindex="-1"
      type="file"
      @input="changeFile"
    />
  </div>
</template>

<script lang="ts" setup>
import useNotification from "@/contexts/shared/composables/useNotification";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import type { Ref } from "vue";

defineProps<{
  modelValue: File | undefined;
  value: string | null | undefined;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: File];
}>();

const { t: $t } = useI18n();
const { errorNotification } = useNotification();

const id = inject<string>("id", "");
const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);

const selected = ref<string>();
const input = ref<HTMLInputElement>();

const classes = computed(() => ({
  "border-gray-300": !hasErrors.value,
  "border-red-500": hasErrors.value,
}));

const changeFile = (event: Event) => {
  const files = <FileList>(<HTMLInputElement>event.target).files;
  if (files.length) {
    if (files[0].type.split("/")[0] !== "image") {
      errorNotification("shared.form.image.invalid");
      return;
    }
    selected.value = URL.createObjectURL(files[0]);
    emit("update:modelValue", files[0]);
  }
};
</script>
