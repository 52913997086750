<template>
  <DashboardHeader>
    <DashboardHeaderTitle>{{ $t("cleaning_groups.title") }}</DashboardHeaderTitle>
    <DashboardHeaderActions>
      <RouterLinkWithIcon
        :to="{ name: 'cleaning_groups.create' }"
        class="btn-primary"
        icon="plus-solid"
      >
        {{ $t("cleaning_groups.create.title") }}
      </RouterLinkWithIcon>
    </DashboardHeaderActions>
  </DashboardHeader>
  <div class="space-y-3">
    <FilterGroup
      :is-empty="criteriaEmpty"
      @clear="clearCriteria"
    >
      <FilterItem
        v-model="criteria.name"
        label="cleaning_groups.filters.name"
        title="cleaning_groups.filters_by.name"
      >
        <FilterText type="contains" />
      </FilterItem>
      <FilterItem
        v-model="criteria.archived"
        label="cleaning_groups.filters.status"
        title="cleaning_groups.filters_by.status"
      >
        <FilterRadio :options="useArchived().archivedOptions" />
      </FilterItem>
    </FilterGroup>
    <template v-if="searchCleaningGroupsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchCleaningGroupsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchCleaningGroupsRequest.data.data.length === 0">
      <ListEmptyState
        description="cleaning_groups.empty.description"
        icon="broom-solid"
        title="cleaning_groups.empty.title"
      >
        <RouterLinkWithIcon
          :to="{ name: 'cleaning_groups.create' }"
          class="btn-primary"
          icon="plus-solid"
        >
          {{ $t("cleaning_groups.create.title") }}
        </RouterLinkWithIcon>
      </ListEmptyState>
    </template>
    <template v-else>
      <CleaningGroupsTable
        :cleaning-groups="searchCleaningGroupsRequest.data.data"
        @archived="changePage()"
        @deleted="changePage()"
        @unarchived="changePage()"
        @updated="searchCleaningGroupsRequest.fetch()"
      />
      <CursorPagination
        :meta="searchCleaningGroupsRequest.data.meta"
        :total="searchCleaningGroupsRequest.data.total"
        @change-page="changePage"
      />
    </template>
  </div>
  <RouterView @fetch-list="changePage" />
</template>

<script lang="ts" setup>
import useCleaningGroups from "@/contexts/cleaning-groups/composables/useCleaningGroups";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import type { SearchCleaningGroupsCriteria } from "@/contexts/cleaning-groups/models/SearchCleaningGroupsCriteria";
import CleaningGroupsTable from "@/contexts/cleaning-groups/ui/components/CleaningGroupsTable.vue";
import useArchived from "@/contexts/shared/composables/useArchived";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { emptyPagination } from "@/contexts/shared/models/Pagination";
import RouterLinkWithIcon from "@/contexts/shared/ui/components/button/RouterLinkWithIcon.vue";
import FilterGroup from "@/contexts/shared/ui/components/filter/FilterGroup.vue";
import FilterItem from "@/contexts/shared/ui/components/filter/FilterItem.vue";
import FilterRadio from "@/contexts/shared/ui/components/filter/FilterRadio.vue";
import FilterText from "@/contexts/shared/ui/components/filter/FilterText.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const accountBus = useEventBus<string>("account");
const { searchCleaningGroups } = useCleaningGroups();

accountBus.on(() => clearCriteria());
useHead({ title: () => `${$t("cleaning_groups.title")} - Hussbook` });

const { criteria, cursor, changePage, criteriaEmpty, clearCriteria } = useCriteria<SearchCleaningGroupsCriteria>({}, () => searchCleaningGroupsRequest.fetch());

onMounted(() => {
  searchCleaningGroupsRequest.fetch();
});

const searchCleaningGroupsRequest: UseRequest<Pagination<CleaningGroup>> = useRequest<Pagination<CleaningGroup>>({
  initialLoading: true,
  value: emptyPagination<CleaningGroup>(),
  promise: () => searchCleaningGroups(criteria.value, cursor.value),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});
</script>
