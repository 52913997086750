<template>
  <div ref="target">
    <LoadingAbsolute :is-loading="calendarAccommodationRequest.isLoading">
      <template v-if="calendarAccommodationRequest.hasErrors">
        <ListErrorState />
      </template>
      <template v-else>
        <div class="flex flex-col items-center space-y-3">
          <div class="w-full">
            <div class="bg-white pb-2.5 pt-1 text-center font-semibold text-gray-800">
              {{ year }}
            </div>
            <div class="space-y-2.5 lg:flex lg:space-x-2.5 lg:space-y-0">
              <AccommodationsCalendarMonth
                :bookings="calendarAccommodationRequest.data.bookings"
                :days="calendarAccommodationRequest.data.days"
                :month="period * 3 - 2"
                :year="year"
              />
              <AccommodationsCalendarMonth
                :bookings="calendarAccommodationRequest.data.bookings"
                :days="calendarAccommodationRequest.data.days"
                :month="period * 3 - 1"
                :year="year"
              />
              <AccommodationsCalendarMonth
                :bookings="calendarAccommodationRequest.data.bookings"
                :days="calendarAccommodationRequest.data.days"
                :month="period * 3"
                :year="year"
              />
            </div>
          </div>
          <div class="w-full space-y-2.5 md:flex md:space-x-2.5 md:space-y-0">
            <BookingAvailabilityLegend />
            <div class="flex w-full flex-1 justify-between space-x-3 sm:justify-end">
              <button
                class="btn btn-white"
                type="button"
                @click="prevPeriod"
              >
                {{ $t("accommodations.calendar.previous") }}
              </button>
              <button
                class="btn btn-white"
                type="button"
                @click="nextPeriod"
              >
                {{ $t("accommodations.calendar.next") }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </LoadingAbsolute>
  </div>
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { AccommodationCalendar } from "@/contexts/accommodations/models/AccommodationCalendar";
import AccommodationsCalendarMonth from "@/contexts/accommodations/ui/components/AccommodationsCalendarMonth.vue";
import BookingAvailabilityLegend from "@/contexts/bookings/ui/components/BookingAvailabilityLegend.vue";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import useRequest from "@/contexts/shared/composables/useRequest";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingAbsolute from "@/contexts/shared/ui/components/loading/LoadingAbsolute.vue";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const { t: $t } = useI18n();
const dayjs = useDayjs();
const { calendarAccommodation } = useAccommodations();

const target = ref<HTMLElement>();
const period = ref<number>(dayjs().quarter());
const year = ref<number>(new Date().getFullYear());
const from = computed<Date>(() =>
  dayjs()
    .set("year", year.value)
    .set("month", period.value * 3 - 2 - 1)
    .startOf("month")
    .toDate(),
);
const to = computed<Date>(() =>
  dayjs()
    .set("year", year.value)
    .set("month", period.value * 3 - 1)
    .endOf("month")
    .toDate(),
);

useVisibility(target, () => calendarAccommodationRequest.fetch());

const calendarAccommodationRequest = useRequest<AccommodationCalendar>({
  initialLoading: true,
  value: {
    days: {},
    bookings: [],
  },
  promise: () => calendarAccommodation(props.accommodation.id, { from: from.value, to: to.value }),
});

const prevPeriod = () => {
  if (period.value === 1) {
    year.value--;
    period.value = 4;
    calendarAccommodationRequest.fetch();
    return;
  }
  period.value--;
  calendarAccommodationRequest.fetch();
};
const nextPeriod = () => {
  if (period.value === 4) {
    year.value++;
    period.value = 1;
    calendarAccommodationRequest.fetch();
    return;
  }
  period.value++;
  calendarAccommodationRequest.fetch();
};

defineExpose({ fetch: () => calendarAccommodationRequest.fetch() });
</script>
