import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { SearchBookingCleaningsPlannerCriteria } from "@/contexts/booking-cleanings/models/SearchBookingCleaningsPlannerCriteria";
import type { Booking } from "@/contexts/bookings/models/Booking";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";
import type { paths } from "@/libs/api/schema";
import dayjs from "dayjs";

const toSearchBookingCleaningsPlannerRequest = (criteria: SearchBookingCleaningsPlannerCriteria): paths["/booking-cleanings/planner"]["get"]["parameters"]["query"] => {
  return {
    "date[from]": criteria.date?.from ? dayjs(criteria.date?.from?.toISOString()).format("YYYY-MM-DD") : null,
    "date[to]": criteria.date?.to ? dayjs(criteria.date?.to?.toISOString()).format("YYYY-MM-DD") : null,
  };
};

const fromSearchBookingCleaningsPlannerResponse = (response: paths["/booking-cleanings/planner"]["get"]["responses"]["200"]["content"]["application/json"] | undefined): { accommodations: Accommodation[]; bookings: Booking[] } => {
  return {
    accommodations: response?.data.accommodations?.map((item) => accommodationFromResponse(item)) ?? [],
    bookings: response?.data?.bookings?.map((item) => bookingFromResponse(item)) ?? [],
  };
};

export default {
  toSearchBookingCleaningsPlannerRequest,
  fromSearchBookingCleaningsPlannerResponse,
};
