<template>
  <ConfirmModal
    :is-loading="defaultAttachmentRequest.isLoading"
    description="accommodation_attachments.default.description"
    title="accommodation_attachments.default.title"
    @close="$emit('close')"
    @confirm="defaultAttachmentRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useAccommodationAttachments from "@/contexts/accommodation-attachments/composables/useAccommodationAttachments";
import type { AccommodationAttachment } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ConfirmModal from "@/contexts/shared/ui/modals/ConfirmModal.vue";

const props = defineProps<{
  attachment: AccommodationAttachment;
}>();

const emit = defineEmits<{
  close: [];
  selectedDefault: [];
}>();

const { defaultAttachment } = useAccommodationAttachments();
const { successNotification, errorNotification } = useNotification();

const defaultAttachmentRequest = useRequest({
  promise: () => defaultAttachment(props.attachment.accommodation_id, props.attachment.id),
  onSuccess: () => {
    successNotification("accommodation_attachments.default.success");
    emit("selectedDefault");
    emit("close");
  },
  onFailure: () => {
    errorNotification("accommodation_attachments.default.error");
  },
});
</script>
