<template>
  <div class="space-y-1.5">
    <template v-for="language in languages">
      <div class="relative">
        <div class="pointer-events-none absolute flex h-full items-center">
          <div class="pl-2 text-xs text-gray-500">{{ $t(`shared.language.${language}`) }}</div>
        </div>
        <FormText
          v-model="input[language.toLowerCase()]"
          :name="`language_${language.toLowerCase()}`"
          class="pl-20"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import useLanguage from "@/contexts/shared/composables/useLanguage";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import { pickBy } from "lodash-es";

const props = defineProps<{
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  modelValue: Record<string, any>;
}>();

const emit = defineEmits<{
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  "update:modelValue": [value: Record<string, any>];
}>();

const { t: $t } = useI18n();
const { languageOptions } = useLanguage();

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const input = computed<Record<string, any>>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", pickBy(value)),
});
const languages = computed<string[]>(() => languageOptions.map((item) => String(item.value)));
</script>
