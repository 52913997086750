import App from "@/App.vue";
import "@/assets/css/tailwind.css";
import i18n from "@/i18n";
import auth from "@/plugins/auth";
import dayjs from "@/plugins/dayjs";
import router from "@/router";
import pinia from "@/store";
import * as Sentry from "@sentry/vue";
import { createHead } from "@unhead/vue";

const app = createApp(App);
const head = createHead();

Sentry.init({
  app,
  enabled: !!import.meta.env.VITE_SENTRY_DSN,
  dsn: String(import.meta.env.VITE_SENTRY_DSN),
  integrations: [Sentry.browserTracingIntegration({ router })],
  tracePropagationTargets: ["https://console.hussbook.com"],
  ignoreErrors: ["AxiosError"],
});

app.use(i18n);
app.use(head);
app.use(pinia);
app.use(router);
app.use(dayjs);
app.use(auth);
app.mount("#app");
