import type SearchCleaningGroupsRequest from "@/clients/cleaning-groups/SearchCleaningGroups/request/SearchCleaningGroupsRequest";
import type SearchCleaningGroupsResponse from "@/clients/cleaning-groups/SearchCleaningGroups/response/SearchCleaningGroupsResponse";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import type { SearchCleaningGroupsCriteria } from "@/contexts/cleaning-groups/models/SearchCleaningGroupsCriteria";
import type { Pagination } from "@/contexts/shared/models/Pagination";

const toSearchCleaningGroupRequest = (criteria: SearchCleaningGroupsCriteria, cursor?: string): SearchCleaningGroupsRequest => {
  return {
    name: criteria.name ?? undefined,
    archived: criteria.archived !== undefined ? (criteria.archived ? "1" : "0") : undefined,
    order_by: criteria.order_by ?? undefined,
    sort: criteria.sort ?? undefined,
    per_page: criteria.per_page ?? undefined,
    cursor: cursor ?? undefined,
  };
};
const fromSearchCleaningGroupResponse = (response: SearchCleaningGroupsResponse): Pagination<CleaningGroup> => {
  return {
    data: response.data.map((item) => ({
      id: item.id,
      name: item.name,
      created_at: new Date(item.created_at),
      updated_at: new Date(item.updated_at),
      archived_at: item.archived_at ? new Date(item.archived_at) : null,
    })),
    meta: {
      next_cursor: response.meta.next_cursor,
      path: response.meta.path,
      per_page: response.meta.per_page,
      prev_cursor: response.meta.prev_cursor,
    },
    total: response.total,
  };
};

export default {
  toSearchCleaningGroupRequest,
  fromSearchCleaningGroupResponse,
};
