<template>
  <DashboardHeader>
    <DashboardHeaderTitle>{{ $t("booking_cleanings.title") }}</DashboardHeaderTitle>
    <DashboardHeaderActions>
      <RouterLinkWithIcon
        :to="{ name: 'booking_cleanings.planner' }"
        class="btn-primary"
        icon="calendar-week-solid"
      >
        {{ $t("booking_cleanings.planner.title") }}
      </RouterLinkWithIcon>
    </DashboardHeaderActions>
  </DashboardHeader>
  <div class="space-y-3">
    <FilterGroup
      :is-empty="criteriaEmpty"
      @clear="clearCriteria"
    >
      <FilterItem
        v-model="criteria['type[]']"
        label="booking_cleanings.filters.type"
        title="booking_cleanings.filters_by.type"
      >
        <FilterOptions :options="cleaningTypeOptions" />
      </FilterItem>
      <FilterItem
        v-model="criteria.datetime"
        label="booking_cleanings.filters.datetime"
        title="booking_cleanings.filters_by.datetime"
      >
        <FilterDateRange />
      </FilterItem>
      <FilterItem
        v-model="criteria.check_in"
        label="booking_cleanings.filters.check_in"
        title="booking_cleanings.filters_by.check_in"
      >
        <FilterDateRange />
      </FilterItem>
      <FilterItem
        v-model="criteria.check_out"
        label="booking_cleanings.filters.check_out"
        title="booking_cleanings.filters_by.check_out"
      >
        <FilterDateRange />
      </FilterItem>
      <FilterItem
        v-model="criteria.accommodation_id"
        label="booking_cleanings.filters.accommodation"
        title="booking_cleanings.filters_by.accommodation"
      >
        <AccommodationsFilter />
      </FilterItem>
      <FilterItem
        v-model="criteria.cleaning_group_id"
        label="booking_cleanings.filters.cleaning_group"
        title="booking_cleanings.filters_by.cleaning_group"
      >
        <CleaningGroupsFilter />
      </FilterItem>
    </FilterGroup>
    <FilterQuick :filters="quickFilters" />
    <template v-if="searchBookingCleaningsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchBookingCleaningsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchBookingCleaningsRequest.data.data.length === 0">
      <ListEmptyState
        description="booking_cleanings.empty.description"
        icon="calendar-week-solid"
        title="booking_cleanings.empty.title"
      >
        <RouterLinkWithIcon
          :to="{ name: 'booking_cleanings.planner' }"
          class="btn-primary"
          icon="calendar-week-solid"
        >
          {{ $t("booking_cleanings.planner.title") }}
        </RouterLinkWithIcon>
      </ListEmptyState>
    </template>
    <template v-else>
      <ListTableGroup>
        <ListTableActions :selected="selected">
          <ButtonWithIcon
            class="btn-primary"
            icon="print-solid"
            @click="bookingCleaningDetailsPreviewModal?.open()"
          >
            {{ $t("booking_cleanings.details.selected", selected?.length ?? 0) }}
          </ButtonWithIcon>
          <ButtonWithIcon
            class="btn-primary"
            icon="print-solid"
            @click="bookingCleaningStickerPreviewModal?.open()"
          >
            {{ $t("booking_cleanings.sticker.selected", selected?.length ?? 0) }}
          </ButtonWithIcon>
        </ListTableActions>
        <BookingCleaningsTable
          v-model:selected="selected"
          :cleanings="searchBookingCleaningsRequest.data.data"
          @fetch-list="searchBookingCleaningsRequest.fetch()"
        />
      </ListTableGroup>
      <CursorPagination
        :meta="searchBookingCleaningsRequest.data.meta"
        :total="searchBookingCleaningsRequest.data.total"
        @change-page="changePage"
      />
    </template>
  </div>
  <ModalDialog
    ref="bookingCleaningDetailsPreviewModal"
    size="3xl"
    @opened="pdfBookingCleaningDetailsRequest.fetch()"
    @close="pdfBookingCleaningDetailsRequest.reset()"
  >
    <PreviewPdfModal
      :file="pdfBookingCleaningDetailsRequest.data"
      :has-errors="pdfBookingCleaningDetailsRequest.hasErrors"
      :is-loading="pdfBookingCleaningDetailsRequest.isLoading"
      :name="`cleanings_details.pdf`"
      title="booking_cleanings.details.title"
    />
  </ModalDialog>
  <ModalDialog
    ref="bookingCleaningStickerPreviewModal"
    size="3xl"
    @opened="pdfBookingCleaningStickerRequest.fetch()"
    @close="pdfBookingCleaningStickerRequest.reset()"
  >
    <PreviewPdfModal
      :file="pdfBookingCleaningStickerRequest.data"
      :has-errors="pdfBookingCleaningStickerRequest.hasErrors"
      :is-loading="pdfBookingCleaningStickerRequest.isLoading"
      :name="`cleanings_stickers.pdf`"
      title="booking_cleanings.sticker.title"
    />
  </ModalDialog>
</template>

<script setup lang="ts">
import AccommodationsFilter from "@/contexts/accommodations/ui/components/AccommodationsFilter.vue";
import useBookingCleanings from "@/contexts/booking-cleanings/composables/useBookingCleanings";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import type { SearchBookingCleaningsCriteria } from "@/contexts/booking-cleanings/models/SearchBookingCleaningsCriteria";
import BookingCleaningsTable from "@/contexts/booking-cleanings/ui/components/BookingCleaningsTable.vue";
import CleaningGroupsFilter from "@/contexts/cleaning-groups/ui/components/CleaningGroupsFilter.vue";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import useSelect from "@/contexts/shared/composables/useSelect";
import { type Pagination, emptyPagination } from "@/contexts/shared/models/Pagination";
import type { QuickFilter } from "@/contexts/shared/models/QuickFilter";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import RouterLinkWithIcon from "@/contexts/shared/ui/components/button/RouterLinkWithIcon.vue";
import FilterDateRange from "@/contexts/shared/ui/components/filter/FilterDateRange.vue";
import FilterGroup from "@/contexts/shared/ui/components/filter/FilterGroup.vue";
import FilterItem from "@/contexts/shared/ui/components/filter/FilterItem.vue";
import FilterOptions from "@/contexts/shared/ui/components/filter/FilterOptions.vue";
import FilterQuick from "@/contexts/shared/ui/components/filter/FilterQuick.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import ListTableActions from "@/contexts/shared/ui/components/list/ListTableActions.vue";
import ListTableGroup from "@/contexts/shared/ui/components/list/ListTableGroup.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import PreviewPdfModal from "@/contexts/shared/ui/modals/PreviewPdfModal.vue";
import { calculateDateRange } from "@/contexts/shared/utils/calculateDaterange";
import type { ModalDialogInstance } from "@/types/instances";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const dayjs = useDayjs();
const { selected } = useSelect();
const { errorNotification } = useNotification();
const accountBus = useEventBus<string>("account");
const { searchBookingCleanings, pdfBookingCleaningDetails, pdfBookingCleaningSticker, cleaningTypeOptions } = useBookingCleanings();

accountBus.on(() => clearCriteria());
useHead({ title: () => `${$t("booking_cleanings.title")} - Hussbook` });

const { criteria, cursor, changePage, criteriaEmpty, clearCriteria } = useCriteria<SearchBookingCleaningsCriteria>({ datetime: { from: dayjs().startOf("day").toDate(), to: dayjs().add(7, "day").endOf("day").toDate() } }, () => searchBookingCleaningsRequest.fetch());

const bookingCleaningDetailsPreviewModal = ref<ModalDialogInstance>();
const bookingCleaningStickerPreviewModal = ref<ModalDialogInstance>();

onMounted(() => {
  searchBookingCleaningsRequest.fetch();
});

const searchBookingCleaningsRequest: UseRequest<Pagination<BookingCleaning>> = useRequest<Pagination<BookingCleaning>>({
  initialLoading: true,
  value: emptyPagination<BookingCleaning>(),
  promise: () => searchBookingCleanings(criteria.value, cursor.value),
  onFetch: () => {
    selected.value = [];
  },
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});

const pdfBookingCleaningDetailsRequest = useRequest({
  initialLoading: true,
  promise: () => pdfBookingCleaningDetails(selected.value),
  onFailure: () => errorNotification("booking_cleanings.details.error"),
});
const pdfBookingCleaningStickerRequest = useRequest({
  initialLoading: true,
  promise: () => pdfBookingCleaningSticker(selected.value),
  onFailure: () => errorNotification("booking_cleanings.sticker.error"),
});

const isDateRange = (start: number, end: number) =>
  computed(() => {
    const range = calculateDateRange(start, end);
    return dayjs(criteria.value.datetime?.from).isSame(range.from, "day") && dayjs(criteria.value.datetime?.to).isSame(range.to, "day");
  });

const quickFilters: QuickFilter[] = [
  {
    label: "booking_cleanings.filters_quick.current_7_days",
    active: isDateRange(0, 7),
    action: () => {
      criteria.value.datetime = calculateDateRange(0, 7);
    },
  },
  {
    label: "booking_cleanings.filters_quick.current_7_to_14_days",
    active: isDateRange(7, 14),
    action: () => {
      criteria.value.datetime = calculateDateRange(7, 14);
    },
  },
];
</script>
