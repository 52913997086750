import type { OwnerBankAccount, UnsavedOwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import { ownerBankAccountFromResponse } from "@/contexts/owner-bank-accounts/utils/ownerBankAccountFromResponse";
import type { paths } from "@/libs/api/schema";

const toCreateOwnerBankAccountRequest = (ownerBankAccount: UnsavedOwnerBankAccount): paths["/owners/{owner}/bank-accounts"]["post"]["requestBody"]["content"]["application/json"] => {
  return {
    address: ownerBankAccount.address,
    country_code: String(ownerBankAccount.country_code),
    id_number: ownerBankAccount.id_number,
    bank_name: ownerBankAccount.bank_name,
    bank_account: String(ownerBankAccount.bank_account),
    bank_swift_bic: ownerBankAccount.bank_swift_bic,
  };
};

const fromCreateOwnerBankAccountResponse = (response: paths["/owners/{owner}/bank-accounts"]["post"]["responses"][200]["content"]["application/json"] | undefined): OwnerBankAccount => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return ownerBankAccountFromResponse(response.data);
};

export default {
  toCreateOwnerBankAccountRequest,
  fromCreateOwnerBankAccountResponse,
};
