import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { paths } from "@/libs/api/schema";

const fromShowAccommodationResponse = (response: paths["/accommodations/{accommodation}"]["get"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  fromShowAccommodationResponse,
};
