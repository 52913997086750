<template>
  <div>
    <template v-if="payments && payments?.length > 0">
      <ListTable>
        <ListTableHeader>
          <ListTableHeaderCell primary>
            {{ $t("bookings.payments.props.amount") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell>
            {{ $t("bookings.payments.props.status") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.payments.props.method") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell>
            {{ $t("bookings.payments.props.due_date") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.payments.props.paid_at") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.payments.props.created_at") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.payments.props.updated_at") }}
          </ListTableHeaderCell>
        </ListTableHeader>
        <ListTableBody>
          <template
            v-for="payment in payments"
            :key="payment.id"
          >
            <ListTableBodyRow>
              <ListTableBodyCell class="w-full">
                {{ `${Number(payment.amount / 100).toFixed(2)}€` }}
              </ListTableBodyCell>
              <ListTableBodyCell>
                <BookingsPaymentsStatusBadge :status="payment.status" />
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                <BookingsPaymentsMethodBadge :method="payment.method" />
              </ListTableBodyCell>
              <ListTableBodyCell>
                {{ payment.due_date?.toLocaleDateString() || "-" }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ payment.paid_at?.toLocaleString() || "-" }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ payment.created_at.toLocaleString() }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ payment.updated_at.toLocaleString() }}
              </ListTableBodyCell>
            </ListTableBodyRow>
          </template>
        </ListTableBody>
      </ListTable>
    </template>
    <SectionEmptyState v-else>
      {{ $t("bookings.payments.empty") }}
    </SectionEmptyState>
  </div>
</template>

<script lang="ts" setup>
import type { BookingPayment } from "@/contexts/bookings/models/BookingPayment";
import BookingsPaymentsMethodBadge from "@/contexts/bookings/ui/components/BookingsPaymentsMethodBadge.vue";
import BookingsPaymentsStatusBadge from "@/contexts/bookings/ui/components/BookingsPaymentsStatusBadge.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

defineProps<{
  payments: BookingPayment[] | undefined;
}>();

const { t: $t } = useI18n();
</script>
