import type FacilitiesAccommodationResponse from "@/clients/accommodations/FacilitiesAccommodation/response/FacilitiesAccommodationResponse";
import axios from "@/libs/axios";

export default async function facilitiesAccommodation() {
  try {
    const { data } = await axios.get<FacilitiesAccommodationResponse>("/accommodations/facilities");
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
