import { cloneDeep, isEqual, mergeWith, omit, pick, pickBy } from "lodash-es";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export default function useCriteria<T extends Record<string, any>>(value: T, fetch: () => void, ignore?: (keyof T)[]) {
  const cursor = shallowRef<string>();
  const criteria = ref<T>(value);
  const defaults = ref<T>(cloneDeep(value));

  const criteriaEmpty = computed(() => isEqual(omit(pickBy(criteria.value ?? {}), ignore ?? []), omit(pickBy(defaults.value ?? {}), ignore ?? [])));

  watch(
    () => criteria.value,
    () => {
      cursor.value = undefined;
      fetch();
    },
    { deep: true },
  );

  const changePage = (value?: string) => {
    cursor.value = value;
    fetch();
  };

  const clearCriteria = () => {
    cursor.value = undefined;
    criteria.value = cloneDeep(mergeWith(defaults.value, pick(criteria.value, ignore ?? [])));
  };

  return {
    cursor,
    criteria,
    changePage,
    clearCriteria,
    criteriaEmpty,
  };
}
