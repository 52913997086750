import type CreateOwnerRequest from "@/clients/owners/CreateOwner/request/CreateOwnerRequest";
import type CreateOwnerResponse from "@/clients/owners/CreateOwner/response/CreateOwnerResponse";
import type { Owner, UnsavedOwner } from "@/contexts/owners/models/Owner";

const toCreateOwnerRequest = (owner: UnsavedOwner): CreateOwnerRequest => {
  return {
    name: owner.name,
    email: owner.email,
    password: String(owner.password),
    language: owner.language,
    phones: owner.phones.length > 0 ? owner.phones : null,
    notes: owner.notes,
  };
};

const fromCreateOwnerResponse = (response: CreateOwnerResponse): Owner => {
  return {
    id: response.data.id,
    name: response.data.name,
    email: response.data.email,
    language: response.data.language,
    phones: response.data.phones ?? [],
    notes: response.data.notes,
    default_bank_account_id: response.data.default_bank_account_id,
    bank_accounts_count: response.data.bank_accounts_count,
    accommodations_count: response.data.accommodations_count,
    notifications_count: response.data.notifications_count,
    read_notifications_count: response.data.read_notifications_count,
    tokens_count: response.data.tokens_count,
    unread_notifications_count: response.data.unread_notifications_count,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  toCreateOwnerRequest,
  fromCreateOwnerResponse,
};
