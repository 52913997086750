import type { paths } from "@/libs/api/schema";
import { isArray } from "lodash-es";

const toPdfBookingCleaningStickerRequest = (id: string | string[]): paths["/booking-cleanings/sticker/pdf"]["get"]["parameters"]["query"] => {
  return {
    "id[]": isArray(id) ? id : [id],
  };
};

const fromPdfBookingCleaningStickerResponse = (response: paths["/booking-cleanings/sticker/pdf"]["get"]["responses"]["200"]["content"]["application/json"] | undefined): string => {
  if (!response) {
    throw new Error("Invalid response");
  }

  return response.data;
};

export default {
  toPdfBookingCleaningStickerRequest,
  fromPdfBookingCleaningStickerResponse,
};
