<template>
  <ConfirmDeleteModal
    :is-loading="deleteAttachmentRequest.isLoading"
    description="accommodation_attachments.delete.description"
    title="accommodation_attachments.delete.title"
    @close="$emit('close')"
    @confirm="deleteAttachmentRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useAccommodationAttachments from "@/contexts/accommodation-attachments/composables/useAccommodationAttachments";
import type { AccommodationAttachment } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";

const props = defineProps<{
  attachment: AccommodationAttachment;
}>();

const emit = defineEmits<{
  close: [];
  deleted: [];
}>();

const { deleteAttachment } = useAccommodationAttachments();
const { successNotification, errorNotification } = useNotification();

const deleteAttachmentRequest = useRequest({
  promise: () => deleteAttachment(props.attachment.accommodation_id, props.attachment.id),
  onSuccess: () => {
    successNotification("accommodation_attachments.delete.success");
    emit("deleted");
    emit("close");
  },
  onFailure: () => {
    errorNotification("accommodation_attachments.delete.error");
  },
});
</script>
