<template>
  <ConfirmModal
    :is-loading="defaultOwnerBankAccountRequest.isLoading"
    description="owner_bank_accounts.default.description"
    title="owner_bank_accounts.default.title"
    @close="$emit('close')"
    @confirm="defaultOwnerBankAccountRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useOwnerBankAccounts from "@/contexts/owner-bank-accounts/composables/useOwnerBankAccounts";
import type { OwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ConfirmModal from "@/contexts/shared/ui/modals/ConfirmModal.vue";

const props = defineProps<{
  bankAccount: OwnerBankAccount;
}>();

const emit = defineEmits<{
  close: [];
  selectedDefault: [];
}>();

const { defaultOwnerBankAccount } = useOwnerBankAccounts();
const { successNotification, errorNotification } = useNotification();

const defaultOwnerBankAccountRequest = useRequest({
  promise: () => defaultOwnerBankAccount(props.bankAccount.owner_id, props.bankAccount.id),
  onSuccess: () => {
    successNotification("owner_bank_accounts.default.success");
    emit("selectedDefault");
    emit("close");
  },
  onFailure: () => {
    errorNotification("owner_bank_accounts.default.error");
  },
});
</script>
