import type { BookingCleaning, UnsavedBookingCleaningUpdated } from "@/contexts/booking-cleanings/models/BookingCleaning";
import { bookingCleaningFromResponse } from "@/contexts/booking-cleanings/utils/bookingCleaningFromResponse";
import type { paths } from "@/libs/api/schema";

const toUpdateBookingCleaningRequest = (bookingCleaning: UnsavedBookingCleaningUpdated): paths["/booking-cleanings/{cleaning}"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    type: bookingCleaning.type,
    datetime: bookingCleaning.datetime?.toISOString() ?? null,
    single_beds: bookingCleaning.single_beds,
    double_beds: bookingCleaning.double_beds,
    suite_beds: bookingCleaning.suite_beds,
    bunk_beds: bookingCleaning.bunk_beds,
    notes: bookingCleaning.notes,
    cleaning_group_id: bookingCleaning.cleaning_group_id,
  };
};

const fromUpdateBookingCleaningResponse = (response: paths["/booking-cleanings/{cleaning}"]["put"]["responses"]["200"]["content"]["application/json"] | undefined): BookingCleaning => {
  if (!response) {
    throw new Error("Invalid response");
  }

  return bookingCleaningFromResponse(response.data);
};

export default {
  toUpdateBookingCleaningRequest,
  fromUpdateBookingCleaningResponse,
};
