<template>
  <FilterItemButton @clear="clear">
    <div
      v-if="!empty"
      class="whitespace-nowrap pl-2 text-primary"
    >
      {{ selected?.label }}
    </div>
  </FilterItemButton>
  <FilterContent v-slot="{ close }">
    <div class="space-y-3">
      <template
        v-for="option in options"
        :key="option.value"
      >
        <div class="flex items-center space-x-2.5">
          <FormRadio
            :id="String(option.value)"
            v-model="select"
            :value="option.value"
            name="select"
          />
          <label
            :for="String(option.value)"
            class="cursor-pointer text-sm text-gray-700"
          >
            {{ option.label }}
          </label>
        </div>
      </template>
    </div>
    <button
      class="btn-primary flex w-full items-center py-1"
      type="submit"
      @click="apply(close)"
    >
      <div class="mx-auto">
        {{ $t("shared.filters.apply") }}
      </div>
    </button>
  </FilterContent>
</template>

<script lang="ts" setup>
import type { Option } from "@/contexts/shared/models/Select";
import FilterContent from "@/contexts/shared/ui/components/filter/FilterContent.vue";
import FilterItemButton from "@/contexts/shared/ui/components/filter/FilterItemButton.vue";
import FormRadio from "@/contexts/shared/ui/components/form/FormRadio.vue";
import type { ComputedRef, WritableComputedRef } from "vue";

const props = defineProps<{
  options: Option[];
}>();

const { t: $t } = useI18n();

const { options } = toRefs(props);

const empty = inject<ComputedRef<boolean>>("empty");
const input = inject<WritableComputedRef<string | number | boolean | undefined>>("input") as WritableComputedRef<string | number | boolean | undefined>;

const select = ref<string | number | boolean | undefined>(input.value ?? undefined);
const selected = computed<Option | undefined>(() => options.value.find((item) => select.value === item.value));

watch(input, (value) => {
  select.value = value ?? undefined;
});

const apply = (close: () => void) => {
  input.value = select.value;
  close();
};
const clear = () => {
  input.value = undefined;
};
</script>
