import { useRegisterSW } from "virtual:pwa-register/vue";
import AuthClient from "@/clients/auth";
import type { Account } from "@/contexts/accounts/models/Account";
import LoginFactory from "@/contexts/auth/composables/factories/LoginFactory";
import OAuthFactory from "@/contexts/auth/composables/factories/OAuthFactory";
import UserFactory from "@/contexts/auth/composables/factories/UserFactory";
import type { AuthOAuthProvider } from "@/contexts/auth/model/AuthOAuthProvider";
import { useAuthStore } from "@/contexts/auth/store";
import type { User } from "@/contexts/profile/models/User";
import api from "@/libs/api";

export default function useAuth() {
  const store = useAuthStore();
  const { needRefresh, updateServiceWorker } = useRegisterSW();

  const login = async (email: string, password: string): Promise<void> => {
    await AuthClient.csrf();
    await AuthClient.login(LoginFactory.toLoginRequest(email, password));
    if (needRefresh.value) {
      await updateServiceWorker();
    }
  };
  const logout = () => {
    clearAuth();
    AuthClient.logout();
  };
  const register = async (): Promise<void> => {
    console.log("Do register");
    //
  };
  const forgotPassword = async (email: string): Promise<void> => {
    console.log("Do forgot password");
    //
  };
  const resetPassword = async (): Promise<void> => {
    console.log("Do reset password");
    //
  };
  const oAuthLogin = async (provider: AuthOAuthProvider): Promise<string> => {
    const { data } = await api.GET("/oauth/{provider}/redirect", { params: { path: { provider } } });
    return Promise.resolve(OAuthFactory.fromOAuthRedirectResponse(data));
  };
  const fetchUser = async () => {
    try {
      const user = UserFactory.fromUserResponse(await AuthClient.user());
      store.setAuthenticatedUser(user);
      store.changeAccount(store.accounts.length > 0 ? (store.authenticatedAccountId ? store.accounts.find((item) => item.id === store.authenticatedAccountId) : (store.accounts[0] ?? store.accounts[0])) : undefined);
    } catch (e) {
      console.error(e);
      throw new Error("User must be authenticated");
    }
  };
  const clearAuth = () => {
    document.documentElement.style.setProperty("--color-primary", "57, 79, 225");
    document.documentElement.style.setProperty("--color-secondary", "28, 231, 178");
    store.setAuthenticatedUser();
  };

  return {
    login,
    logout,
    register,
    fetchUser,
    clearAuth,
    oAuthLogin,
    resetPassword,
    forgotPassword,
    changeAccount: store.changeAccount,
    accounts: computed(() => store.accounts),
    authenticatedId: computed<string | null>(() => store.authenticatedId),
    isAuthenticated: computed<boolean>(() => store.isAuthenticated),
    authenticatedUser: computed<User | undefined>(() => store.authenticatedUser),
    authenticatedAccount: computed<Account | null>(() => store.authenticatedAccount),
    authenticatedAccountId: computed<string | null>(() => store.authenticatedAccountId),
  };
}
