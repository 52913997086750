<template>
  <MapSearch
    ref="map"
    @select="selectAddress"
  />
</template>

<script lang="ts" setup>
import type { UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import type { GMapsAddress, GMapsCoordinates } from "@/contexts/shared/composables/useMaps";
import useMaps from "@/contexts/shared/composables/useMaps";
import MapSearch from "@/contexts/shared/ui/components/map/MapSearch.vue";
import type { MapSearchInstance } from "@/types/instances";

const props = defineProps<{
  modelValue: UnsavedAccommodation;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: UnsavedAccommodation];
}>();

const { getCoordinates } = useMaps();

const map = ref<MapSearchInstance>();

const input = computed<UnsavedAccommodation>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
const coordinates = computed<GMapsCoordinates | undefined>(() => getCoordinates(props.modelValue.latitude, props.modelValue.longitude));

const loaded = () => {
  map.value?.load();
  if (coordinates.value) {
    map?.value?.setZoom(16);
    map?.value?.setCenter(coordinates.value);
    map?.value?.addMarker(coordinates.value);
  }
};
const selectAddress = (address: GMapsAddress) => {
  input.value.street = address.street ?? null;
  input.value.city = address.city ?? null;
  input.value.postal_code = address.postal_code ?? null;
  input.value.country_code = address.country_code ?? null;
  input.value.latitude = address.lat ?? null;
  input.value.longitude = address.lng ?? null;
};

defineExpose({
  loaded,
});
</script>
