import AccommodationsContractManagePage from "@/contexts/accommodations/ui/pages/AccommodationsContractManagePage.vue";
import AccommodationsCreatePage from "@/contexts/accommodations/ui/pages/AccommodationsCreatePage.vue";
import AccommodationsManagePage from "@/contexts/accommodations/ui/pages/AccommodationsManagePage.vue";
import AccommodationsPage from "@/contexts/accommodations/ui/pages/AccommodationsPage.vue";
import Middleware from "@/contexts/shared/models/Middleware";
import type { RouteRecordRaw, Router } from "vue-router";
import AccommodationsPricesBulkPage from "./ui/pages/AccommodationsPricesBulkPage.vue";
import AccommodationsPricesPage from "./ui/pages/AccommodationsPricesPage.vue";

const routes: RouteRecordRaw[] = [
  {
    name: "accommodations",
    path: "/accommodations",
    component: AccommodationsPage,
    meta: {
      layout: "dashboard",
      group: "accommodations",
      middleware: [Middleware.auth],
    },
    children: [
      {
        name: "accommodations.create",
        path: "/accommodations/create",
        component: AccommodationsCreatePage,
        meta: {
          layout: "dashboard",
          group: "accommodations",
          middleware: [Middleware.auth],
        },
      },
    ],
  },
  {
    name: "accommodations.prices",
    path: "/accommodations/prices",
    component: AccommodationsPricesPage,
    meta: {
      layout: "dashboard",
      group: "accommodations",
      middleware: [Middleware.auth],
    },
    children: [
      {
        name: "accommodations.prices.bulk",
        path: "/accommodations/prices/bulk",
        component: AccommodationsPricesBulkPage,
        meta: {
          layout: "dashboard",
          group: "accommodations",
          middleware: [Middleware.auth],
        },
      },
    ],
  },
  {
    name: "accommodations.manage",
    path: "/accommodations/:id",
    component: AccommodationsManagePage,
    meta: {
      layout: "dashboard",
      group: "accommodations",
      middleware: [Middleware.auth],
    },
    children: [
      {
        name: "accommodations.manage.contract.manage",
        path: "/accommodations/:id/contracts/:contractId",
        component: AccommodationsContractManagePage,
        meta: {
          layout: "dashboard",
          group: "accommodations",
          middleware: [Middleware.auth],
        },
      },
    ],
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
