import type { OwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import { ownerFromResponse } from "@/contexts/owners/utils/ownerFromResponse";
import type { components } from "@/libs/api/schema";

export const ownerBankAccountFromResponse = (response: components["schemas"]["OwnerBankAccount"]): OwnerBankAccount => {
  return {
    id: response.id,
    address: response.address,
    country_code: response.country_code,
    id_number: response.id_number,
    bank_name: response.bank_name,
    bank_account: response.bank_account,
    bank_swift_bic: response.bank_swift_bic,
    owner_id: response.owner_id,
    owner: response.owner ? ownerFromResponse(response.owner) : undefined,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
