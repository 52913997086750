import type { AccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { components } from "@/libs/api/schema";

export const accommodationContractFromResponse = (response: components["schemas"]["AccommodationContract"]): AccommodationContract => {
  return {
    id: response.id,
    year: response.year,
    status: response.status,
    reference_price: response.reference_price,
    weeks: response.weeks,
    notes: response.notes,

    accommodation_id: response.accommodation_id,

    accommodation: response.accommodation ? accommodationFromResponse(response.accommodation) : undefined,

    confirmed_at: response.confirmed_at ? new Date(response.confirmed_at) : null,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
