import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import { bookingCleaningFromResponse } from "@/contexts/booking-cleanings/utils/bookingCleaningFromResponse";
import { bookingGuestFromResponse } from "@/contexts/booking-guests/utils/bookingGuestFromResponse";
import { bookingImportFromResponse } from "@/contexts/booking-imports/utils/bookingImportFromResponse";
import type { Booking, BookingChannel, BookingProvider, BookingStatus } from "@/contexts/bookings/models/Booking";
import { bookingExtraFromResponse } from "@/contexts/bookings/utils/bookingExtraFromResponse";
import { bookingPaymentFromResponse } from "@/contexts/bookings/utils/bookingPaymentFromResponse";
import type { components } from "@/libs/api/schema";

export const bookingFromResponse = (response: components["schemas"]["Booking"]): Booking => {
  return {
    id: response.id,
    code: response.code,
    status: response.status as BookingStatus,
    price: response.price,
    language: response.language,
    check_in: new Date(response.check_in),
    check_out: new Date(response.check_out),
    adults: response.adults,
    children: response.children,
    is_youngsters: response.is_youngsters,
    has_pets: response.has_pets,
    security_deposit: response.security_deposit,
    notes: response.notes,
    channel: response.channel as BookingChannel,
    provider: response.provider as BookingProvider | null,

    accommodation_id: response.accommodation_id,
    guest_id: response.guest_id,
    import_id: response.import_id,

    booking_cleanings_count: response.booking_cleanings_count,
    booking_payments_count: response.booking_payments_count,
    extras_count: response.extras_count,

    accommodation: response.accommodation ? accommodationFromResponse(response.accommodation) : undefined,
    guest: response.guest ? bookingGuestFromResponse(response.guest) : undefined,
    booking_cleanings: response.booking_cleanings?.map((item) => bookingCleaningFromResponse(item)),
    booking_payments: response.booking_payments?.map((item) => bookingPaymentFromResponse(item)),
    extras: response.extras?.map((item) => bookingExtraFromResponse(item)),
    import: response.import ? bookingImportFromResponse(response.import) : undefined,

    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
