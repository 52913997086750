import SettingsAccountPage from "@/contexts/settings/ui/pages/SettingsAccountPage.vue";
import SettingsAccountSeasonsPage from "@/contexts/settings/ui/pages/SettingsAccountSeasonsPage.vue";
import SettingsAccountServicesPage from "@/contexts/settings/ui/pages/SettingsAccountServicesPage.vue";
import SettingsIndexPage from "@/contexts/settings/ui/pages/SettingsIndexPage.vue";
import SettingsIntegrationsPage from "@/contexts/settings/ui/pages/SettingsIntegrationsPage.vue";
import Middleware from "@/contexts/shared/models/Middleware";
import type { RouteRecordRaw, Router } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    name: "settings",
    path: "/settings",
    component: SettingsIndexPage,
    meta: {
      layout: "dashboard",
      group: "settings",
      middleware: [Middleware.auth],
    },
  },
  {
    name: "settings.account",
    path: "/settings/account",
    component: SettingsAccountPage,
    meta: {
      layout: "dashboard",
      group: "settings",
      middleware: [Middleware.auth],
    },
  },
  {
    name: "settings.account.services",
    path: "/settings/account/services",
    component: SettingsAccountServicesPage,
    meta: {
      layout: "dashboard",
      group: "settings",
      middleware: [Middleware.auth],
    },
  },
  {
    name: "settings.account.seasons",
    path: "/settings/account/seasons",
    component: SettingsAccountSeasonsPage,
    meta: {
      layout: "dashboard",
      group: "settings",
      middleware: [Middleware.auth],
    },
  },
  {
    name: "settings.integrations",
    path: "/settings/integrations",
    component: SettingsIntegrationsPage,
    meta: {
      layout: "dashboard",
      group: "settings",
      middleware: [Middleware.auth],
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
