import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { Owner } from "@/contexts/owners/models/Owner";
import type { components } from "@/libs/api/schema";

export const ownerFromResponse = (response: components["schemas"]["Owner"]): Owner => {
  return {
    id: response.id,
    name: response.name,
    email: response.email,
    language: response.language,
    phones: response.phones ?? [],
    notes: response.notes,
    default_bank_account_id: response.default_bank_account_id,
    bank_accounts_count: response.bank_accounts_count,
    accommodations_count: response.accommodations_count,
    notifications_count: response.notifications_count,
    read_notifications_count: response.read_notifications_count,
    tokens_count: response.tokens_count,
    unread_notifications_count: response.unread_notifications_count,
    accommodations: response.accommodations ? response.accommodations.map((item) => accommodationFromResponse(item)) : undefined,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
