<template>
  <ListTable>
    <ListTableHeader>
      <ListTableHeaderCell small>
        <FormCheckbox
          id="select_all"
          v-model="selectAll"
          name="select_all"
        />
      </ListTableHeaderCell>
      <ListTableHeaderCell primary>
        {{ $t("booking_cleanings.props.type") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell>
        {{ $t("booking_cleanings.props.datetime") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell>
        {{ $t("booking_cleanings.props.cleaning_group_id") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell>
        {{ $t("booking_cleanings.props.booking_id") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell>
        {{ $t("booking_cleanings.props.accommodation_id") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("booking_cleanings.props.single_beds") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("booking_cleanings.props.double_beds") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("booking_cleanings.props.suite_beds") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("booking_cleanings.props.bunk_beds") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell small />
    </ListTableHeader>
    <ListTableBody>
      <template
        v-for="cleaning in cleanings"
        :key="cleaning.id"
      >
        <ListTableBodyRow>
          <ListTableBodyCell
            :padding="false"
            :truncate="false"
            class="pr-2.5"
          >
            <FormCheckbox
              :id="cleaning.id"
              v-model="input"
              :value="cleaning.id"
              name="selected"
            />
          </ListTableBodyCell>
          <ListTableBodyCell class="w-full">
            <BookingCleaningsTypeIcon :type="cleaning.type" />
          </ListTableBodyCell>
          <ListTableBodyCell>
            {{ cleaning.datetime?.toLocaleString() ?? "-" }}
          </ListTableBodyCell>
          <ListTableBodyCell>
            <CleaningGroupsDetailsLink :cleaning-group="cleaning?.cleaning_group" />
          </ListTableBodyCell>
          <ListTableBodyCell>
            <BookingsDetailsLink :booking="cleaning?.booking" />
          </ListTableBodyCell>
          <ListTableBodyCell>
            <AccommodationsDetailsLink :accommodation="cleaning?.booking?.accommodation" />
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ cleaning.single_beds }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ cleaning.double_beds }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ cleaning.suite_beds }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ cleaning.bunk_beds }}
          </ListTableBodyCell>
          <ListTableBodyCell
            :padding="false"
            :truncate="false"
            class="pl-2.5"
          >
            <BookingCleaningsDropdown :cleaning="cleaning" />
          </ListTableBodyCell>
        </ListTableBodyRow>
      </template>
    </ListTableBody>
  </ListTable>
</template>

<script setup lang="ts">
import AccommodationsDetailsLink from "@/contexts/accommodations/ui/components/AccommodationsDetailsLink.vue";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import BookingCleaningsDropdown from "@/contexts/booking-cleanings/ui/components/BookingCleaningsDropdown.vue";
import BookingCleaningsTypeIcon from "@/contexts/booking-cleanings/ui/components/BookingCleaningsTypeIcon.vue";
import BookingsDetailsLink from "@/contexts/bookings/ui/components/BookingsDetailsLink.vue";
import CleaningGroupsDetailsLink from "@/contexts/cleaning-groups/ui/components/CleaningGroupsDetailsLink.vue";
import FormCheckbox from "@/contexts/shared/ui/components/form/FormCheckbox.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";

const props = defineProps<{
  cleanings: BookingCleaning[];
  selected?: string[];
}>();

const emit = defineEmits<{
  fetchList: [];
  "update:selected": [value: string[]];
}>();

const { t: $t } = useI18n();

const input = computed<string[]>({
  get: () => props.selected ?? [],
  set: (value) => emit("update:selected", value),
});
const selectAll = computed<boolean>({
  get: () => props.selected?.length === props.cleanings.length,
  set: (value) => {
    input.value = value ? props.cleanings.map((item) => item.id) : [];
  },
});
</script>
