import CreateBookingCleaningFactory from "@/contexts/booking-cleanings/composables/factories/CreateBookingCleaningFactory";
import PdfBookingCleaningDetailsFactory from "@/contexts/booking-cleanings/composables/factories/PdfBookingCleaningDetailsFactory";
import PdfBookingCleaningStickerFactory from "@/contexts/booking-cleanings/composables/factories/PdfBookingCleaningStickerFactory";
import SearchBookingCleaningsFactory from "@/contexts/booking-cleanings/composables/factories/SearchBookingCleaningsFactory";
import UpdateBookingCleaningFactory from "@/contexts/booking-cleanings/composables/factories/UpdateBookingCleaningFactory";
import type { BookingCleaning, UnsavedBookingCleaning, UnsavedBookingCleaningUpdated } from "@/contexts/booking-cleanings/models/BookingCleaning";
import type { SearchBookingCleaningsCriteria } from "@/contexts/booking-cleanings/models/SearchBookingCleaningsCriteria";
import CleaningTypeProvider from "@/contexts/booking-cleanings/providers/cleaningTypeProvider";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { Option } from "@/contexts/shared/models/Select";
import api from "@/libs/api";

export default function useBookingCleanings() {
  const { t: $t } = useI18n();

  const searchBookingCleanings = async (criteria: SearchBookingCleaningsCriteria, cursor?: string): Promise<Pagination<BookingCleaning>> => {
    const { data, response } = await api.GET("/booking-cleanings", { params: { query: SearchBookingCleaningsFactory.toSearchBookingCleaningsRequest(criteria, cursor) } });
    return Promise.resolve(SearchBookingCleaningsFactory.fromSearchBookingCleaningsResponse(data, response.headers));
  };
  const createBookingCleaning = async (bookingCleaning: UnsavedBookingCleaning): Promise<BookingCleaning> => {
    const { data } = await api.POST("/booking-cleanings", { body: CreateBookingCleaningFactory.toCreateBookingCleaningRequest(bookingCleaning) });
    return Promise.resolve(CreateBookingCleaningFactory.fromCreateBookingCleaningResponse(data));
  };
  const updateBookingCleaning = async (id: string, bookingCleaning: UnsavedBookingCleaningUpdated): Promise<BookingCleaning> => {
    const { data } = await api.PUT("/booking-cleanings/{cleaning}", { params: { path: { cleaning: id } }, body: UpdateBookingCleaningFactory.toUpdateBookingCleaningRequest(bookingCleaning) });
    return Promise.resolve(UpdateBookingCleaningFactory.fromUpdateBookingCleaningResponse(data));
  };
  const pdfBookingCleaningDetails = async (id: string | string[]): Promise<string> => {
    const { data } = await api.GET("/booking-cleanings/details/pdf", { params: { query: PdfBookingCleaningDetailsFactory.toPdfBookingCleaningDetailsRequest(id) } });
    return PdfBookingCleaningDetailsFactory.fromPdfBookingCleaningDetailsResponse(data);
  };
  const pdfBookingCleaningSticker = async (id: string | string[]): Promise<string> => {
    const { data } = await api.GET("/booking-cleanings/sticker/pdf", { params: { query: PdfBookingCleaningStickerFactory.toPdfBookingCleaningStickerRequest(id) } });
    return PdfBookingCleaningStickerFactory.fromPdfBookingCleaningStickerResponse(data);
  };

  const cleaningTypeOptions: Option[] = CleaningTypeProvider.get().map((item) => ({ value: item, label: $t(`booking_cleanings.cleaning_type.${item}`) }));

  return {
    searchBookingCleanings,
    createBookingCleaning,
    updateBookingCleaning,
    pdfBookingCleaningDetails,
    pdfBookingCleaningSticker,
    cleaningTypeOptions,
  };
}
