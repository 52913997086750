import createAccommodationAttachment from "@/clients/accommodation-attachments/CreateAccommodationAttachment/CreateAccommodationAttachment";
import defaultAccommodationAttachment from "@/clients/accommodation-attachments/DefaultAccommodationAttachment/DefaultAccommodationAttachment";
import deleteAccommodationAttachment from "@/clients/accommodation-attachments/DeleteAccommodationAttachment/DeleteAccommodationAttachment";
import getAccommodationAttachments from "@/clients/accommodation-attachments/GetAccommodationAttachments/GetAccommodationAttachments";
import showAccommodationAttachment from "@/clients/accommodation-attachments/ShowAccommodationAttachment/ShowAccommodationAttachment";

export default {
  getAccommodationAttachments,
  createAccommodationAttachment,
  showAccommodationAttachment,
  defaultAccommodationAttachment,
  deleteAccommodationAttachment,
};
