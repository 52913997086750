import type { paths } from "@/libs/api/schema";
import { isArray } from "lodash-es";

const toCancelBookingsRequest = (id: string | string[]): paths["/bookings/cancel"]["patch"]["requestBody"]["content"]["application/json"] => {
  return {
    id: isArray(id) ? id : [id],
  };
};

export default {
  toCancelBookingsRequest,
};
