import { accommodationContractFromResponse } from "@/contexts/accommodation-contracts/utils/accommodationContractFromResponse";
import type { AccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import type { SearchAccommodationContractsCriteria } from "@/contexts/accommodations/models/SearchAccommodationContractsCriteria";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { paginationMetaFromResponse } from "@/contexts/shared/utils/paginationMetaFromResponse";
import type { paths } from "@/libs/api/schema";

const toSearchAccommodationContractsRequest = (criteria: SearchAccommodationContractsCriteria, cursor?: string): paths["/accommodations/{accommodation}/contracts"]["get"]["parameters"]["query"] => {
  return {
    cursor: cursor ?? undefined,
    order_by: criteria?.order_by ?? undefined,
    sort: criteria?.sort ?? undefined,
    per_page: criteria?.per_page ?? undefined,
  };
};

const fromSearchAccommodationContractsResponse = (response: paths["/accommodations/{accommodation}/contracts"]["get"]["responses"][200]["content"]["application/json"] | undefined, headers: Headers): Pagination<AccommodationContract> => {
  return {
    data: response?.data?.map((item) => accommodationContractFromResponse(item)) ?? [],
    meta: paginationMetaFromResponse(response?.meta),
    total: Number(headers.get("x-total-count") ?? 0),
  };
};

export default {
  toSearchAccommodationContractsRequest,
  fromSearchAccommodationContractsResponse,
};
