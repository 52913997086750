import type SearchCleaningGroupsRequest from "@/clients/cleaning-groups/SearchCleaningGroups/request/SearchCleaningGroupsRequest";
import type SearchCleaningGroupsResponse from "@/clients/cleaning-groups/SearchCleaningGroups/response/SearchCleaningGroupsResponse";
import axios from "@/libs/axios";
import { pickBy } from "lodash-es";

export default async function searchCleaningGroups(request: SearchCleaningGroupsRequest): Promise<SearchCleaningGroupsResponse> {
  try {
    const { data, headers } = await axios.get<SearchCleaningGroupsResponse>("/cleaning-groups", { params: pickBy(request) });
    return Promise.resolve({ ...data, total: Number(headers["x-total-count"] ?? 0) });
  } catch (e) {
    return Promise.reject(e);
  }
}
