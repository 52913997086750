import accountServicesClient from "@/clients/account-services";
import CreateAccountServiceFactory from "@/contexts/accounts/composables/factories/CreateAccountServiceFactory";
import SearchAccountServicesFactory from "@/contexts/accounts/composables/factories/SearchAccountServicesFactory";
import UpdateAccountServiceFactory from "@/contexts/accounts/composables/factories/UpdateAccountServiceFactory";
import type { UnsavedAccountService } from "@/contexts/accounts/models/AccountService";
import type { SearchAccountServicesCriteria } from "@/contexts/accounts/models/SearchAccountServicesCriteria";

export default function useAccountServices() {
  const searchAccountServices = async (criteria: SearchAccountServicesCriteria, cursor?: string) => {
    return SearchAccountServicesFactory.fromSearchAccountServicesResponse(await accountServicesClient.searchAccountServices(SearchAccountServicesFactory.toSearchAccountServicesRequest(criteria, cursor)));
  };
  const createAccountService = async (accountService: UnsavedAccountService) => {
    return await accountServicesClient.createAccountService(CreateAccountServiceFactory.toCreateAccountServiceRequest(accountService));
  };
  const updateAccountService = async (id: string, accountService: UnsavedAccountService) => {
    return await accountServicesClient.updateAccountService(id, UpdateAccountServiceFactory.toUpdateAccountServiceRequest(accountService));
  };
  const deleteAccountService = async (id: string) => {
    return await accountServicesClient.deleteAccountService(id);
  };

  return {
    searchAccountServices,
    createAccountService,
    updateAccountService,
    deleteAccountService,
  };
}
