<template>
  <FormAsyncSelect
    v-model:search="criteria.name"
    :model-value="modelValue"
    :pagination="searchCleaningGroupsRequest.data"
    :with-create="showCreateCleaningGroup"
    index="id"
    label="name"
    @create="submitCreateCleaningGroup(String(criteria.name))"
    @fetch="searchCleaningGroupsRequest.fetch()"
    @update:modelValue="$emit('update:modelValue', $event)"
    @fetch:item="showSelectedCleaningGroup"
  >
    <template #footer="{ close }">
      <div v-if="showCreateCleaningGroup">
        <button
          class="flex w-full items-center space-x-2 px-3.5 py-2 text-left text-sm leading-4 text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
          type="button"
          @click="submitCreateCleaningGroup(String(criteria.name), close)"
        >
          <FontAwesomeIcon
            class="h-3.5 w-3.5 flex-none fill-gray-700"
            icon="plus-solid"
          />
          <span class="truncate">{{ $t("shared.form.select.create", { name: criteria.name }) }}</span>
        </button>
      </div>
    </template>
  </FormAsyncSelect>
</template>

<script lang="ts" setup>
import useCleaningGroups from "@/contexts/cleaning-groups/composables/useCleaningGroups";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import type { SearchCleaningGroupsCriteria } from "@/contexts/cleaning-groups/models/SearchCleaningGroupsCriteria";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { emptyPagination } from "@/contexts/shared/models/Pagination";
import FormAsyncSelect from "@/contexts/shared/ui/components/form/FormAsyncSelect.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

defineProps<{
  modelValue: string | null;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string | null];
}>();

const { t: $t } = useI18n();
const { successNotification, errorNotification } = useNotification();
const { createCleaningGroup, searchCleaningGroups, showCleaningGroup } = useCleaningGroups();

const criteria = ref<SearchCleaningGroupsCriteria>({ name: "" });
const showCreateCleaningGroup = computed<boolean>(() => !!criteria.value.name && !searchCleaningGroupsRequest.data.data.find((item) => item.name === criteria.value.name));

const searchCleaningGroupsRequest = useRequest<Pagination<CleaningGroup>>({
  initialLoading: true,
  value: emptyPagination<CleaningGroup>(),
  promise: () => searchCleaningGroups(criteria.value),
});
const showSelectedCleaningGroup = (id: string, onSelected: (item: CleaningGroup) => void) => showCleaningGroup(id).then((response) => onSelected(response));
const submitCreateCleaningGroup = async (name: string, close?: () => void) => {
  try {
    const cleaningGroup = await createCleaningGroup({ name: name });
    successNotification("cleaning_groups.create.success");
    emit("update:modelValue", cleaningGroup.id);
    close ? close() : void 0;
  } catch (e) {
    errorNotification("cleaning_groups.create.error");
  }
};
</script>
