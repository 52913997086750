import type SortAccommodationPhotosRequest from "@/clients/accommodation-photos/SortAccommodationPhotos/request/SortAccommodationPhotosRequest";
import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";

const toSortAccommodationPhotosRequest = (photos: AccommodationPhoto[]): SortAccommodationPhotosRequest => {
  return {
    photos: photos.map((item) => item.id),
  };
};

export default {
  toSortAccommodationPhotosRequest,
};
