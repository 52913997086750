import useAuth from "@/contexts/auth/composables/useAuth";
import { useAuthStore } from "@/contexts/auth/store";
import i18n from "@/i18n";
import router from "@/router";
import axios from "axios";

const instance = axios.create({
  baseURL: String(import.meta.env.VITE_API_URL),
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  withXSRFToken: true,
  withCredentials: true,
});

instance.interceptors.request.use((request) => {
  if (i18n.global.locale.value) {
    request.headers.Locale = String(i18n.global.locale.value);
  }
  const { authenticatedId, authenticatedAccountId } = useAuth();
  if (authenticatedId.value && authenticatedAccountId.value && request.headers) {
    request.headers["Account-Id"] = String(authenticatedAccountId.value);
  }
  return request;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      router.push({ name: "login", query: { redirect: !["/login", "/", ""].includes(window.location.pathname) ? window.location.pathname : undefined } });
      useAuth().clearAuth();
    }
    return Promise.reject(error);
  },
);

export default instance;
