<template>
  <ListDetails>
    <ListDetailsColumn>
      <ListDetailsCell label="accommodations.props.id">
        {{ accommodation?.id }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.acronym">
        {{ accommodation?.acronym }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.name">
        {{ accommodation?.name }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.featured">
        <AccommodationsFeaturedBadge :featured="accommodation?.featured" />
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.type">
        {{ $t(`accommodations.type.${accommodation?.type}`) }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.registration_code">
        {{ accommodation?.registration_code ?? "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.land_registration_code">
        {{ accommodation?.land_registration_code ?? "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.indoor_area">
        {{ accommodation?.indoor_area ?? "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.total_area">
        {{ accommodation?.total_area ?? "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.floors">
        {{ accommodation?.floors ?? "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.owner">
        <OwnersLink :owner="accommodation?.owner" />
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.cleaning_group">
        <CleaningGroupsDetailsLink :cleaning-group="accommodation?.cleaning_group" />
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.notes">
        {{ accommodation?.notes ?? "-" }}
      </ListDetailsCell>
    </ListDetailsColumn>
    <ListDetailsColumn>
      <ListDetailsCell label="accommodations.props.capacity">
        {{ $t("accommodations.manage.capacity", accommodation?.capacity ?? 0) }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.bedrooms">
        {{ $t("accommodations.manage.bedrooms.count.bedrooms", accommodation?.bedrooms_count ?? 0) }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.bathrooms">
        {{ $t("accommodations.manage.bathrooms.count", accommodation?.bathrooms_count ?? 0) }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.kitchens">
        {{ $t("accommodations.manage.kitchens.count", accommodation?.kitchens_count ?? 0) }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.check_in_time_from">
        {{ accommodation?.check_in_time_from ?? "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.check_in_time_to">
        {{ accommodation?.check_in_time_to ?? "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.check_out_time">
        {{ accommodation?.check_out_time ?? "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.channels">
        <AccommodationsChannels :channels="accommodation?.channels" />
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.updated_at">
        {{ accommodation?.updated_at?.toLocaleString() }}
      </ListDetailsCell>
      <ListDetailsCell label="accommodations.props.created_at">
        {{ accommodation?.created_at?.toLocaleString() }}
      </ListDetailsCell>
    </ListDetailsColumn>
  </ListDetails>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import AccommodationsChannels from "@/contexts/accommodations/ui/components/AccommodationsChannels.vue";
import AccommodationsFeaturedBadge from "@/contexts/accommodations/ui/components/AccommodationsFeaturedBadge.vue";
import CleaningGroupsDetailsLink from "@/contexts/cleaning-groups/ui/components/CleaningGroupsDetailsLink.vue";
import OwnersLink from "@/contexts/owners/ui/components/OwnersDetailsLink.vue";
import ListDetails from "@/contexts/shared/ui/components/list/ListDetails.vue";
import ListDetailsCell from "@/contexts/shared/ui/components/list/ListDetailsCell.vue";
import ListDetailsColumn from "@/contexts/shared/ui/components/list/ListDetailsColumn.vue";

defineProps<{
  accommodation: Accommodation | undefined;
}>();

const { t: $t } = useI18n();
</script>
