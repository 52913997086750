import type { BookingImport } from "@/contexts/booking-imports/models/BookingImport";
import type { components } from "@/libs/api/schema";

export const bookingImportFromResponse = (response: components["schemas"]["BookingImport"]): BookingImport => {
  return {
    id: response.id,
    provider: response.provider,
    type: response.type,
    status: response.status,
    errors: response.errors,
    warnings: response.warnings,
    bookings: response.bookings,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
