<template>
  <DropdownMenu>
    <template #button>
      <slot />
    </template>
    <DropdownMenuItemButton
      v-if="!hideEdit"
      @click="updateCleaningGroupModal?.open()"
    >
      {{ $t("cleaning_groups.actions.edit") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      v-if="!cleaningGroup.archived_at"
      :disabled="archiveCleaningGroupRequest.isLoading"
      @click="archiveCleaningGroupRequest.fetch()"
    >
      {{ $t("cleaning_groups.actions.archive") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      v-else
      :disabled="unarchiveCleaningGroupRequest.isLoading"
      @click="unarchiveCleaningGroupRequest.fetch()"
    >
      {{ $t("cleaning_groups.actions.unarchive") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      class="text-red-600 hover:text-red-700"
      @click="deleteCleaningGroupModal?.open()"
    >
      {{ $t("cleaning_groups.delete.title") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    ref="updateCleaningGroupModal"
    v-slot="{ close }"
  >
    <CleaningGroupsUpdateModal
      :cleaning-group="cleaningGroup"
      @close="close"
      @updated="$emit('updated')"
    />
  </ModalDialog>
  <ModalDialog
    ref="deleteCleaningGroupModal"
    v-slot="{ close }"
  >
    <CleaningGroupsDeleteModal
      :cleaning-group="cleaningGroup"
      @close="close"
      @deleted="$emit('deleted')"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import useCleaningGroups from "@/contexts/cleaning-groups/composables/useCleaningGroups";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import CleaningGroupsDeleteModal from "@/contexts/cleaning-groups/ui/modals/CleaningGroupsDeleteModal.vue";
import CleaningGroupsUpdateModal from "@/contexts/cleaning-groups/ui/modals/CleaningGroupsUpdateModal.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import type { ModalDialogInstance } from "@/types/instances";

const props = defineProps<{
  cleaningGroup: CleaningGroup;
  hideEdit?: boolean;
}>();

const emit = defineEmits<{
  updated: [];
  archived: [];
  unarchived: [];
  deleted: [];
}>();

const { t: $t } = useI18n();
const { successNotification, errorNotification } = useNotification();
const { archiveCleaningGroup, unarchiveCleaningGroup } = useCleaningGroups();

const updateCleaningGroupModal = ref<ModalDialogInstance>();
const deleteCleaningGroupModal = ref<ModalDialogInstance>();

const archiveCleaningGroupRequest = useRequest({
  promise: () => archiveCleaningGroup(props.cleaningGroup.id),
  onSuccess: () => {
    successNotification("cleaning_groups.archive.success");
    emit("archived");
  },
  onFailure: () => {
    errorNotification("cleaning_groups.archive.error");
  },
});
const unarchiveCleaningGroupRequest = useRequest({
  promise: () => unarchiveCleaningGroup(props.cleaningGroup.id),
  onSuccess: () => {
    successNotification("cleaning_groups.unarchive.success");
    emit("unarchived");
  },
  onFailure: () => {
    errorNotification("cleaning_groups.unarchive.error");
  },
});
</script>
