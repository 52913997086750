import createAccountService from "@/clients/account-services/CreateAccountService/CreateAccountService";
import deleteAccountService from "@/clients/account-services/DeleteAccountService/DeleteAccountService";
import searchAccountServices from "@/clients/account-services/SearchAccountServices/SearchAccountServices";
import updateAccountService from "@/clients/account-services/UpdateAccountService/UpdateAccountService";

export default {
  searchAccountServices,
  createAccountService,
  updateAccountService,
  deleteAccountService,
};
