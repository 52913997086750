<template>
  <SlideOver
    ref="slideOverElement"
    :parent-route="{ name: 'accommodations' }"
    route
    size="full"
    @opened="opened"
  >
    <SlideOverHeader>
      <SlideOverTitle>{{ $t("accommodations.create.title") }}</SlideOverTitle>
      <SlideOverHeaderActions>
        <button
          class="btn-white"
          type="button"
          @click.prevent="slideOverElement?.close()"
        >
          {{ $t("shared.form.cancel") }}
        </button>
        <button
          :disabled="createAccommodationRequest.isLoading"
          class="btn-primary"
          type="submit"
          @click.prevent="submit"
        >
          {{ $t("shared.form.save") }}
        </button>
      </SlideOverHeaderActions>
    </SlideOverHeader>
    <SlideOverBody>
      <form
        class="space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0 lg:grid-cols-3"
        @submit.prevent="submit"
      >
        <SectionGroup class="lg:col-span-2">
          <section>
            <SectionHeader>
              <SectionTitle>{{ $t("accommodations.manage.details.title") }}</SectionTitle>
            </SectionHeader>
            <AccommodationsDetailsForm
              v-model="accommodation"
              :errors="createAccommodationRequest.errors"
            />
          </section>
        </SectionGroup>
        <SectionGroup>
          <AccommodationsMapForm
            ref="accommodationMapForm"
            v-model="accommodation"
          />
          <section>
            <SectionHeader>
              <SectionTitle>{{ $t("accommodations.manage.address.title") }}</SectionTitle>
            </SectionHeader>
            <AccommodationsAddressForm
              v-model="accommodation"
              :errors="createAccommodationRequest.errors"
            />
          </section>
        </SectionGroup>
        <input
          class="hidden"
          type="submit"
        />
      </form>
    </SlideOverBody>
  </SlideOver>
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import AccommodationsAddressForm from "@/contexts/accommodations/ui/components/AccommodationsAddressForm.vue";
import AccommodationsDetailsForm from "@/contexts/accommodations/ui/components/AccommodationsDetailsForm.vue";
import AccommodationsMapForm from "@/contexts/accommodations/ui/components/AccommodationsMapForm.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import SectionHeader from "@/contexts/shared/ui/components/section/SectionHeader.vue";
import SectionTitle from "@/contexts/shared/ui/components/section/SectionTitle.vue";
import SlideOver from "@/contexts/shared/ui/components/slide-over/SlideOver.vue";
import SlideOverBody from "@/contexts/shared/ui/components/slide-over/SlideOverBody.vue";
import SlideOverHeader from "@/contexts/shared/ui/components/slide-over/SlideOverHeader.vue";
import SlideOverHeaderActions from "@/contexts/shared/ui/components/slide-over/SlideOverHeaderActions.vue";
import SlideOverTitle from "@/contexts/shared/ui/components/slide-over/SlideOverTitle.vue";
import type { AccommodationsMapFormInstance, SlideOverInstance } from "@/types/instances";

const emit = defineEmits<{
  fetchList: [];
}>();

const { t: $t } = useI18n();
const { createAccommodation, empty } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

useHead({ title: () => `${$t("accommodations.create.title")} - Hussbook` });

const slideOverElement = ref<SlideOverInstance>();
const accommodationMapForm = ref<AccommodationsMapFormInstance>();
const opened = () => accommodationMapForm.value?.loaded();
const accommodation = ref<UnsavedAccommodation>(empty);

const createAccommodationRequest = useRequest({
  promise: () => createAccommodation(accommodation.value),
  onSuccess: () => {
    successNotification("accommodations.create.success");
    emit("fetchList");
    slideOverElement.value?.close();
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.create.error");
    }
  },
});

const submit = async () => {
  await createAccommodationRequest.fetch();
};
</script>
