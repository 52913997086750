<template>
  <div class="space-y-3">
    <ListTable>
      <ListTableHeader>
        <ListTableHeaderCell primary>{{ $t("owner_bank_accounts.props.bank_account") }}</ListTableHeaderCell>
        <ListTableHeaderCell>{{ $t("owner_bank_accounts.props.bank_name") }}</ListTableHeaderCell>
        <ListTableHeaderCell>{{ $t("owner_bank_accounts.props.bank_swift_bic") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("owner_bank_accounts.props.id_number") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("owner_bank_accounts.props.address") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("owner_bank_accounts.props.country_code") }}</ListTableHeaderCell>
        <ListTableHeaderCell>{{ $t("owner_bank_accounts.props.updated_at") }}</ListTableHeaderCell>
        <ListTableHeaderCell small />
      </ListTableHeader>
      <ListTableBody>
        <template
          v-for="bankAccount in bankAccounts"
          :key="bankAccount.id"
        >
          <ListTableBodyRow>
            <ListTableBodyCell>
              {{ bankAccount.bank_account }}
              <Badge
                v-if="bankAccount.id === defaultBankAccountId"
                class="badge-primary ml-2"
              >
                {{ $t("owner_bank_accounts.props.is_default") }}
              </Badge>
            </ListTableBodyCell>
            <ListTableBodyCell>{{ bankAccount.bank_name || "-" }}</ListTableBodyCell>
            <ListTableBodyCell>{{ bankAccount.bank_swift_bic || "-" }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ bankAccount.id_number || "-" }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ bankAccount.address || "-" }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ bankAccount.country_code ? $t(`shared.country.${bankAccount.country_code}`) : "-" }}</ListTableBodyCell>
            <ListTableBodyCell>{{ bankAccount.updated_at.toLocaleString() }}</ListTableBodyCell>
            <ListTableBodyCell
              :padding="false"
              :truncate="false"
              class="pl-2.5"
            >
              <OwnerBankAccountsDropdown
                :bank-account="bankAccount"
                :is-default="bankAccount.id === defaultBankAccountId"
                @updated="$emit('updated')"
                @deleted="$emit('deleted')"
                @selected-default="$emit('selectedDefault', bankAccount)"
              />
            </ListTableBodyCell>
          </ListTableBodyRow>
        </template>
      </ListTableBody>
    </ListTable>
  </div>
</template>

<script setup lang="ts">
import type { OwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import OwnerBankAccountsDropdown from "@/contexts/owner-bank-accounts/ui/components/OwnerBankAccountsDropdown.vue";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";

defineProps<{
  bankAccounts: OwnerBankAccount[] | undefined;
  defaultBankAccountId: string | null;
}>();

defineEmits<{
  updated: [];
  deleted: [];
  selectedDefault: [bankAccount: OwnerBankAccount];
}>();

const { t: $t } = useI18n();
</script>
