<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.update.descriptions") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form @submit.prevent="submit">
      <div class="space-y-2.5">
        <template
          v-for="(item, index) in input"
          :key="index"
        >
          <div class="relative gap-2 rounded border border-gray-200 p-3">
            <ButtonWithIcon
              class="absolute right-0 top-0 m-1 p-2"
              icon="trash-can-solid"
              @click="removeDescription(index)"
            />
            <div class="grid grid-cols-2 gap-2">
              <FormGroup :errors="updateAccommodationDescriptionsRequest.errors?.[`descriptions.${index}.language`]">
                <FormLabel>{{ $t("accommodations.descriptions.props.language") }}</FormLabel>
                <FormInput>
                  <FormSelect
                    v-model="input[index].language"
                    :options="languageOptions"
                    name="language"
                    :disabled="!!input[index].id"
                  />
                </FormInput>
              </FormGroup>
              <div class="col-span-2 grid gap-2">
                <FormGroup :errors="updateAccommodationDescriptionsRequest.errors?.[`descriptions.${index}.summary`]">
                  <FormLabel>{{ $t("accommodations.descriptions.props.summary") }}</FormLabel>
                  <FormInput>
                    <FormTextarea
                      v-model="input[index].summary"
                      name="summary"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationDescriptionsRequest.errors?.[`descriptions.${index}.overview`]">
                  <FormLabel>{{ $t("accommodations.descriptions.props.overview") }}</FormLabel>
                  <FormInput>
                    <FormTextarea
                      v-model="input[index].overview"
                      name="overview"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationDescriptionsRequest.errors?.[`descriptions.${index}.services`]">
                  <FormLabel>{{ $t("accommodations.descriptions.props.services") }}</FormLabel>
                  <FormInput>
                    <FormTextarea
                      v-model="input[index].services"
                      name="services"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationDescriptionsRequest.errors?.[`descriptions.${index}.additional_features`]">
                  <FormLabel>{{ $t("accommodations.descriptions.props.additional_features") }}</FormLabel>
                  <FormInput>
                    <FormTextarea
                      v-model="input[index].additional_features"
                      name="additional_features"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationDescriptionsRequest.errors?.[`descriptions.${index}.additional_rules`]">
                  <FormLabel>{{ $t("accommodations.descriptions.props.additional_rules") }}</FormLabel>
                  <FormInput>
                    <FormTextarea
                      v-model="input[index].additional_rules"
                      name="additional_rules"
                    />
                  </FormInput>
                </FormGroup>
              </div>
            </div>
          </div>
        </template>
        <div class="flex justify-center md:col-span-2">
          <ButtonWithIcon
            class="btn-primary"
            icon="plus-solid"
            @click="addDescription"
          >
            {{ $t("accommodations.manage.descriptions.add") }}
          </ButtonWithIcon>
        </div>
      </div>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { AccommodationDescription, UnsavedAccommodationDescription } from "@/contexts/accommodations/models/AccommodationDescription";
import useLanguage from "@/contexts/shared/composables/useLanguage";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormTextarea from "@/contexts/shared/ui/components/form/FormTextarea.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import { cloneDeep } from "lodash-es";

const props = defineProps<{
  accommodation: Accommodation;
  descriptions: AccommodationDescription[];
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: Accommodation];
}>();

const { t: $t } = useI18n();
const { updateAccommodationDescriptions } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

onMounted(() => {
  input.value = cloneDeep(props.descriptions);
});

const input = ref<UnsavedAccommodationDescription[]>([]);
const languageOptions = computed(() => useLanguage().languageOptions.map((item) => ({ ...item, disabled: input.value.some((i) => i.language === item.value) })));

const updateAccommodationDescriptionsRequest = useRequest({
  promise: () => updateAccommodationDescriptions(props.accommodation.id, input.value),
  onSuccess: (response) => {
    successNotification("accommodations.update.success");
    emit("updated", response);
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.update.error");
    }
  },
});

const addDescription = () => {
  input.value.push({ language: "", summary: "", overview: "", services: "", additional_features: "", additional_rules: "" });
};
const removeDescription = (index: number) => {
  input.value.splice(index, 1);
};
const submit = async () => {
  await updateAccommodationDescriptionsRequest.fetch();
};
</script>
