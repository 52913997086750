<template>
  <div v-if="(descriptions?.length ?? 0) > 0">
    <ListTable>
      <ListTableHeader>
        <ListTableHeaderCell primary>{{ $t("accommodations.descriptions.props.id") }}</ListTableHeaderCell>
        <ListTableHeaderCell>{{ $t("accommodations.descriptions.props.language") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("accommodations.descriptions.props.created_at") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("accommodations.descriptions.props.updated_at") }}</ListTableHeaderCell>
      </ListTableHeader>
      <ListTableBody>
        <template
          v-for="description in descriptions"
          :key="description.id"
        >
          <ListTableBodyRow>
            <ListTableBodyCell>{{ description.id }}</ListTableBodyCell>
            <ListTableBodyCell>{{ $t(`shared.language.${description.language.toLowerCase()}`) }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ description.created_at.toLocaleString() }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ description.updated_at.toLocaleString() }}</ListTableBodyCell>
          </ListTableBodyRow>
        </template>
      </ListTableBody>
    </ListTable>
  </div>
  <SectionEmptyState v-else>
    {{ $t("accommodations.manage.descriptions.empty") }}
  </SectionEmptyState>
</template>

<script setup lang="ts">
import type { AccommodationDescription } from "@/contexts/accommodations/models/AccommodationDescription";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

defineProps<{
  descriptions: AccommodationDescription[] | undefined;
}>();

const { t: $t } = useI18n();
</script>
