<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.update.facilities") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      <nav class="hidden flex-col md:flex">
        <ul
          class="space-y-1"
          role="list"
        >
          <li v-for="group in Object.keys(facilitiesAccommodationRequest.data) as (keyof AccommodationFacilities)[]">
            <button
              :class="[selectedGroup === group ? 'bg-gray-50 text-primary' : 'text-gray-700 hover:bg-gray-50 hover:text-primary']"
              class="group flex w-full gap-x-2 rounded-md p-2 pl-3 text-sm font-medium leading-6"
              type="button"
              @click="selectedGroup = group"
            >
              {{ $t(`accommodations.facilities.group.${group}`) }}
              <div class="ml-auto w-8 min-w-max whitespace-nowrap rounded-full bg-white px-2 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                {{ facilitiesAccommodationRequest.data[group].filter((item) => input.includes(item)).length ?? 0 }}
              </div>
            </button>
          </li>
        </ul>
      </nav>
      <div class="md:hidden">
        <FormGroup>
          <FormLabel>{{ $t("accommodations.facilities.props.group") }}</FormLabel>
          <FormInput>
            <FormSelect
              :model-value="selectedGroup"
              :options="Object.keys(facilitiesAccommodationRequest.data).map((item) => ({ label: $t(`accommodations.facilities.group.${item}`), value: item }))"
              name="group"
              @update:model-value="selectedGroup = $event as keyof AccommodationFacilities"
            />
          </FormInput>
        </FormGroup>
      </div>
      <div class="md:col-span-2 lg:col-span-3 xl:col-span-4">
        <LoadingAbsolute :is-loading="facilitiesAccommodationRequest.isLoading || updateAccommodationFacilitiesRequest.isLoading">
          <div class="min-h-[55.5rem]">
            <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <template
                v-for="(facility, index) in facilitiesAccommodationRequest.data[selectedGroup]"
                :key="index"
              >
                <div class="group flex items-center text-sm">
                  <FormCheckbox
                    :id="facility"
                    v-model="input"
                    :name="facility"
                    :value="facility"
                  />
                  <label
                    :for="facility"
                    class="ml-2.5 select-none truncate box-decoration-clone pr-2 font-medium text-gray-700 group-hover:z-20 group-hover:overflow-visible group-hover:whitespace-nowrap group-hover:bg-white"
                  >
                    {{ $t(`accommodations.facilities.facility.${facility}`) }}
                  </label>
                </div>
              </template>
            </div>
          </div>
        </LoadingAbsolute>
      </div>
    </div>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { AccommodationFacilities } from "@/contexts/accommodations/models/AccommodationFacilities";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import FormCheckbox from "@/contexts/shared/ui/components/form/FormCheckbox.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import LoadingAbsolute from "@/contexts/shared/ui/components/loading/LoadingAbsolute.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import { cloneDeep } from "lodash-es";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: Accommodation];
}>();

const { t: $t } = useI18n();
const { successNotification, errorNotification } = useNotification();
const { facilitiesAccommodation, updateAccommodationFacilities } = useAccommodations();

const input = ref<string[]>([]);
const selectedGroup = ref<keyof AccommodationFacilities>("equipment");

onMounted(() => {
  facilitiesAccommodationRequest.fetch();
  input.value = cloneDeep(props.accommodation.facilities);
});

const facilitiesAccommodationRequest = useRequest({
  value: { equipment: [], services: [], surroundings: [], location: [], restrictions: [], recommendations: [], accessibility: [], sustainability: [] },
  initialLoading: true,
  promise: () => facilitiesAccommodation(),
});
const updateAccommodationFacilitiesRequest = useRequest({
  keepLoading: true,
  promise: () => updateAccommodationFacilities(props.accommodation.id, input.value),
  onSuccess: (response) => {
    successNotification("accommodations.update.success");
    emit("updated", response);
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.update.error");
    }
  },
});

const submit = () => {
  updateAccommodationFacilitiesRequest.fetch();
};
</script>
