import BookingCleaningsPage from "@/contexts/booking-cleanings/ui/pages/BookingCleaningsPage.vue";
import BookingCleaningsPlannerPage from "@/contexts/booking-cleanings/ui/pages/BookingCleaningsPlannerPage.vue";
import Middleware from "@/contexts/shared/models/Middleware";
import type { RouteRecordRaw, Router } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    name: "booking_cleanings",
    path: "/booking-cleanings",
    component: BookingCleaningsPage,
    meta: {
      layout: "dashboard",
      group: "booking_cleanings",
      middleware: [Middleware.auth],
    },
  },
  {
    name: "booking_cleanings.planner",
    path: "/booking-cleanings/planner",
    component: BookingCleaningsPlannerPage,
    meta: {
      layout: "dashboard",
      group: "booking_cleanings",
      middleware: [Middleware.auth],
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
