<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t(title) }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <slot>
      <p class="text-sm text-gray-500">
        {{ $t(String(description)) }}
      </p>
    </slot>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="isLoading"
      class="btn-primary"
      tabindex="1"
      type="button"
      @click.prevent="$emit('confirm')"
    >
      {{ $t("shared.form.confirm") }}
    </button>
    <button
      class="btn-white"
      tabindex="2"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";

defineProps<{
  title: string;
  description?: string;
  isLoading: boolean;
}>();

defineEmits<{
  close: [];
  confirm: [];
}>();

const { t: $t } = useI18n();
</script>
