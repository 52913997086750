import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { UnsavedAccommodationKitchen } from "@/contexts/accommodations/models/AccommodationRooms";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { paths } from "@/libs/api/schema";

const toUpdateAccommodationKitchensRequest = (kitchens: UnsavedAccommodationKitchen[]): paths["/accommodations/{accommodation}/kitchens"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    kitchens: kitchens.map((item) => ({
      id: item.id ?? null,
      type: item.type,
      floor: item.floor,
    })),
  };
};

const fromUpdateAccommodationKitchensResponse = (response: paths["/accommodations/{accommodation}/kitchens"]["put"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  toUpdateAccommodationKitchensRequest,
  fromUpdateAccommodationKitchensResponse,
};
