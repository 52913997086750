<template>
  <div :class="classes">
    <template
      v-for="(option, index) in options"
      :key="index"
    >
      <button
        type="button"
        :aria-selected="modelValue === option.value"
        :disabled="option.disabled"
        class="rounded border border-gray-200 bg-white p-2 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50 aria-selected:border-primary aria-selected:ring-1 aria-selected:ring-primary"
        @click="modelValue = option.value"
      >
        <slot v-bind="{ option }" />
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Choice } from "@/contexts/shared/models/Choice";
import type { Ref } from "vue";

const props = withDefaults(
  defineProps<{
    options: Choice[];
    grid?: 1 | 2 | 3 | 4 | 5 | 6;
  }>(),
  {
    grid: 2,
  },
);

const modelValue = defineModel<string | number | null>();

const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);

const classes = computed(() => ({
  "grid grid-cols-1 gap-3 rounded": true,
  "md:grid-cols-2": props.grid === 2,
  "md:grid-cols-3": props.grid === 3,
  "md:grid-cols-4": props.grid === 4,
  "md:grid-cols-5": props.grid === 5,
  "md:grid-cols-6": props.grid === 6,
  "ring-1 ring-offset-2 ring-red-500 my-1": hasErrors.value,
}));
</script>
