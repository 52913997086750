import OwnersCreatePage from "@/contexts/owners/ui/pages/OwnersCreatePage.vue";
import OwnersManagePage from "@/contexts/owners/ui/pages/OwnersManagePage.vue";
import OwnersPage from "@/contexts/owners/ui/pages/OwnersPage.vue";
import Middleware from "@/contexts/shared/models/Middleware";
import type { RouteRecordRaw, Router } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    name: "owners",
    path: "/owners",
    component: OwnersPage,
    meta: {
      layout: "dashboard",
      group: "owners",
      middleware: [Middleware.auth],
    },
    children: [
      {
        name: "owners.create",
        path: "/owners/create",
        component: OwnersCreatePage,
        meta: {
          layout: "dashboard",
          group: "owners",
          middleware: [Middleware.auth],
        },
      },
    ],
  },
  {
    name: "owners.manage",
    path: "/owners/:id",
    component: OwnersManagePage,
    meta: {
      layout: "dashboard",
      group: "owners",
      middleware: [Middleware.auth],
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
