<template>
  <button
    :data-testid="id"
    type="button"
    class="btn-white relative flex w-full items-center justify-center gap-3 px-4 py-2 disabled:cursor-progress"
    :disabled="isLoading"
  >
    <div
      v-if="isLoading"
      class="absolute top-0 flex h-full w-full items-center justify-center rounded-md bg-gray-200/80"
    >
      <FontAwesomeIcon
        class="h-5 w-5 animate-spin fill-gray-500"
        icon="spinner-third-duotone"
      />
    </div>
    <ProviderIcon
      :icon="provider"
      class="size-5"
    />
    <span class="font-semibold">{{ $t(`shared.providers.${provider}`) }}</span>
  </button>
</template>

<script setup lang="ts">
import type { AuthOAuthProvider } from "@/contexts/auth/model/AuthOAuthProvider";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import ProviderIcon from "@/contexts/shared/ui/components/icon/ProviderIcon.vue";

defineProps<{
  id?: string;
  provider: AuthOAuthProvider;
  isLoading: boolean;
}>();

const { t: $t } = useI18n();
</script>
