import type { BookingCleaning, UnsavedBookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import { bookingCleaningFromResponse } from "@/contexts/booking-cleanings/utils/bookingCleaningFromResponse";
import type { paths } from "@/libs/api/schema";

const toCreateBookingCleaningRequest = (bookingCleaning: UnsavedBookingCleaning): paths["/booking-cleanings"]["post"]["requestBody"]["content"]["application/json"] => {
  return {
    assoc: bookingCleaning.assoc,
    type: bookingCleaning.type,
    datetime: bookingCleaning.datetime?.toISOString() ?? null,
    single_beds: bookingCleaning.single_beds,
    double_beds: bookingCleaning.double_beds,
    suite_beds: bookingCleaning.suite_beds,
    bunk_beds: bookingCleaning.bunk_beds,
    notes: bookingCleaning.notes,
    booking_id: bookingCleaning.booking_id,
    cleaning_group_id: bookingCleaning.cleaning_group_id,
  };
};

const fromCreateBookingCleaningResponse = (response: paths["/booking-cleanings"]["post"]["responses"]["200"]["content"]["application/json"] | undefined): BookingCleaning => {
  if (!response) {
    throw new Error("Invalid response");
  }

  return bookingCleaningFromResponse(response.data);
};

export default {
  toCreateBookingCleaningRequest,
  fromCreateBookingCleaningResponse,
};
