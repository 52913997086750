import axios from "@/libs/axios";

export default async function logout(): Promise<void> {
  try {
    await axios.post("/logout");
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
