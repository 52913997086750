import type { AccommodationChannelRequirements } from "@/contexts/accommodations/models/AccommodationChannel";
import type { paths } from "@/libs/api/schema";

const fromGetAccommodationChannelResponse = (response: paths["/accommodations/{accommodation}/channels/{channel}"]["get"]["responses"][200]["content"]["application/json"] | undefined): Record<AccommodationChannelRequirements, boolean> => {
  return {
    photos: response?.data.photos ?? false,
    descriptions: response?.data.descriptions ?? false,
    bedrooms: response?.data.bedrooms ?? false,
    bathrooms: response?.data.bathrooms ?? false,
    kitchens: response?.data.kitchens ?? false,
    facilities: response?.data.facilities ?? false,
    capacity: response?.data.capacity ?? false,
    address: response?.data.address ?? false,
    coordinates: response?.data.coordinates ?? false,
    registration_code: response?.data.registration_code ?? false,
  };
};

export default {
  fromShowAccommodationChannelResponse: fromGetAccommodationChannelResponse,
};
