<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodation_attachments.preview.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent class="flex h-[78vh]">
    <template v-if="showAttachmentRequest.isLoading">
      <LoadingSection class="m-auto" />
    </template>
    <template v-else-if="showAttachmentRequest.hasErrors">
      <ListErrorState class="m-auto" />
    </template>
    <template v-else>
      <div
        v-if="viewerEnabled"
        class="relative h-full w-full border border-gray-200"
      >
        <div class="absolute m-4 space-x-2.5 rounded-lg">
          <ButtonWithIcon
            class="btn-primary"
            icon="arrow-down-to-line-solid"
            @click="download()"
          >
            {{ $t("shared.preview.download") }}
          </ButtonWithIcon>
        </div>
        <iframe
          :src="`${showAttachmentRequest.data.url}#toolbar=0`"
          class="h-full w-full"
          type="application/pdf"
        />
      </div>
      <div
        v-else
        class="flex h-full w-full border border-gray-200"
      >
        <div class="m-auto text-center">
          <FileIcon
            :icon="showAttachmentRequest.data.extension === 'pdf' ? 'pdf' : 'default'"
            class="mx-auto h-14 w-14"
          />
          <div class="my-4 w-full">
            <div class="truncate text-sm font-medium leading-6 text-gray-700">
              {{ showAttachmentRequest.data.name }}
            </div>
            <div class="text-xs text-gray-500">
              {{ bytesToHuman(showAttachmentRequest.data.size) }}
            </div>
          </div>
          <ButtonWithIcon
            class="btn-white"
            icon="arrow-down-to-line-solid"
            @click="download()"
          >
            {{ $t("shared.preview.download") }}
          </ButtonWithIcon>
        </div>
      </div>
    </template>
  </ModalDialogContent>
</template>

<script lang="ts" setup>
import useAccommodationAttachments from "@/contexts/accommodation-attachments/composables/useAccommodationAttachments";
import type { AccommodationAttachmentWithUrl } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";
import useFormatter from "@/contexts/shared/composables/useFormatter";
import useRequest from "@/contexts/shared/composables/useRequest";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import FileIcon from "@/contexts/shared/ui/components/icon/FileIcon.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";

const props = defineProps<{
  accommodationId: string;
  id: string;
}>();

defineEmits<{
  close: [];
}>();

const { t: $t } = useI18n();
const { bytesToHuman } = useFormatter();
const { showAttachment } = useAccommodationAttachments();

onMounted(() => {
  showAttachmentRequest.fetch();
});

const viewerEnabled = computed(() => navigator.pdfViewerEnabled && showAttachmentRequest.data?.extension === "pdf" && !(!/Chrome|Android|CriOS|FxiOS|EdgiOS/i.test(navigator.userAgent) && /Mac|iPhone|iPod|iPad/i.test(navigator.userAgent) && /Safari/i.test(navigator.userAgent)));

const showAttachmentRequest = useRequest<AccommodationAttachmentWithUrl>({
  initialLoading: true,
  promise: () => showAttachment(props.accommodationId, props.id),
});

const download = () => {
  const link = document.createElement("a");
  link.href = showAttachmentRequest.data.url;
  link.download = `${showAttachmentRequest.data.name}`;
  link.target = "_blank";
  link.click();
  link.remove();
};
</script>
