import NotFoundPage from "@/contexts/shared/ui/pages/NotFoundPage.vue";
import type { RouteRecordRaw, Router } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    name: "404",
    path: "/:catchAll(.*)*",
    component: NotFoundPage,
    meta: {
      layout: "empty",
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
