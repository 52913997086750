import type { BookingsAccommodationsCriteria } from "@/contexts/accommodations/models/BookingsAccommodationsCriteria";
import type { Booking } from "@/contexts/bookings/models/Booking";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { paginationMetaFromResponse } from "@/contexts/shared/utils/paginationMetaFromResponse";
import type { paths } from "@/libs/api/schema";

const toBookingsAccommodationRequest = (criteria: BookingsAccommodationsCriteria, cursor?: string): paths["/accommodations/{accommodation}/bookings"]["get"]["parameters"]["query"] => {
  return {
    cursor: cursor ?? undefined,
    order_by: criteria.order_by ?? undefined,
    sort: criteria.sort ?? undefined,
    per_page: criteria.per_page ?? undefined,
  };
};

const fromBookingsAccommodationResponse = (response: paths["/accommodations/{accommodation}/bookings"]["get"]["responses"][200]["content"]["application/json"] | undefined, headers: Headers): Pagination<Booking> => {
  return {
    data: response?.data?.map((item) => bookingFromResponse(item)) ?? [],
    meta: paginationMetaFromResponse(response?.meta),
    total: Number(headers.get("x-total-count") ?? 0),
  };
};

export default {
  toBookingsAccommodationRequest,
  fromBookingsAccommodationResponse,
};
