<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodation_photos.create.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <FormImages
      id="accommodation_photos"
      :promise="(file: File) => createAccommodationPhoto(accommodation?.id, file)"
      @uploaded="$emit('uploaded', $event)"
    />
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.close") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import useAccommodationPhotos from "@/contexts/accommodation-photos/composables/useAccommodationPhotos";
import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import FormImages from "@/contexts/shared/ui/components/form/FormImages.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";

defineProps<{
  accommodation: Accommodation;
}>();

defineEmits<{
  close: [];
  uploaded: [value: AccommodationPhoto];
}>();

const { t: $t } = useI18n();
const { createAccommodationPhoto } = useAccommodationPhotos();
</script>
