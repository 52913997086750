import AnalyticsClient from "@/clients/analytics";
import BookingCleaningsByTypeFactory from "@/contexts/analytics/composables/factories/BookingCleaningsByTypeFactory";
import BookingsByWeekFactory from "@/contexts/analytics/composables/factories/BookingsByWeekFactory";
import type { BookingCleaningType } from "@/contexts/booking-cleanings/models/BookingCleaningType";
import api from "@/libs/api";

export default function useAnalytics() {
  const bookingsByWeek = async (): Promise<{ labels: string[]; values: { current: { arrivals: number; departures: number }[]; previous: { arrivals: number; departures: number }[] } }> => {
    const { data } = await api.GET("/analytics/bookings/by-week");
    return BookingsByWeekFactory.fromBookingsByWeekResponse(data);
  };
  const bookingCleaningsByType = async (): Promise<{ data: Partial<Record<BookingCleaningType, { total: number; avg: number }>>; total: number }> => {
    return BookingCleaningsByTypeFactory.fromBookingCleaningsByTypeResponse(await AnalyticsClient.bookingCleaningsByType());
  };

  return {
    bookingsByWeek,
    bookingCleaningsByType,
  };
}
