import type CreateCleaningGroupRequest from "@/clients/cleaning-groups/CreateCleaningGroup/request/CreateCleaningGroupRequest";
import type CreateCleaningGroupResponse from "@/clients/cleaning-groups/CreateCleaningGroup/response/CreateCleaningGroupResponse";
import axios from "@/libs/axios";

export default async function createCleaningGroup(request: CreateCleaningGroupRequest): Promise<CreateCleaningGroupResponse> {
  try {
    const { data } = await axios.post<CreateCleaningGroupResponse>("/cleaning-groups", request);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
