import type LoginRequest from "@/clients/auth/Login/request/LoginRequest";
import axios from "@/libs/axios";

export default async function login(request: LoginRequest): Promise<void> {
  try {
    await axios.post("/login", request);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
