import { accommodationContractFromResponse } from "@/contexts/accommodation-contracts/utils/accommodationContractFromResponse";
import type { AccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import type { paths } from "@/libs/api/schema";

const fromGetAccommodationContractResponse = (response: paths["/accommodations/{accommodation}/contracts/{contract}"]["get"]["responses"][200]["content"]["application/json"] | undefined): AccommodationContract => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationContractFromResponse(response.data);
};

export default {
  fromGetAccommodationContractResponse,
};
