import OwnersClient from "@/clients/owners";
import CreateOwnerFactory from "@/contexts/owners/composables/factories/CreateOwnerFactory";
import SearchOwnersFactory from "@/contexts/owners/composables/factories/SearchOwnersFactory";
import ShowOwnerFactory from "@/contexts/owners/composables/factories/ShowOwnerFactory";
import UpdateOwnerFactory from "@/contexts/owners/composables/factories/UpdateOwnerFactory";
import type { Owner, UnsavedOwner } from "@/contexts/owners/models/Owner";
import type { SearchOwnersCriteria } from "@/contexts/owners/models/SearchOwnersCriteria";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import api from "@/libs/api";

export default function useOwners() {
  const searchOwners = async (criteria: SearchOwnersCriteria, cursor?: string): Promise<Pagination<Owner>> => {
    return SearchOwnersFactory.fromSearchOwnersResponse(await OwnersClient.searchOwners(SearchOwnersFactory.toSearchOwnersRequest(criteria, cursor)));
  };
  const createOwner = async (owner: UnsavedOwner): Promise<Owner> => {
    return CreateOwnerFactory.fromCreateOwnerResponse(await OwnersClient.createOwner(CreateOwnerFactory.toCreateOwnerRequest(owner)));
  };
  const showOwner = async (id: string): Promise<Owner> => {
    const { data } = await api.GET("/owners/{owner}", { params: { path: { owner: id } } });
    return ShowOwnerFactory.fromShowOwnerResponse(data);
  };
  const updateOwner = async (id: string, owner: UnsavedOwner): Promise<void> => {
    await OwnersClient.updateOwner(id, UpdateOwnerFactory.toUpdateOwnerRequest(owner));
  };
  const deleteOwner = async (id: string): Promise<void> => {
    return OwnersClient.deleteOwner(id);
  };

  return {
    searchOwners,
    createOwner,
    showOwner,
    updateOwner,
    deleteOwner,
  };
}
