import type SearchOwnersRequest from "@/clients/owners/SearchOwners/request/SearchOwnersRequest";
import type SearchOwnersResponse from "@/clients/owners/SearchOwners/response/SearchOwnersResponse";
import type { Owner } from "@/contexts/owners/models/Owner";
import type { SearchOwnersCriteria } from "@/contexts/owners/models/SearchOwnersCriteria";
import type { Pagination } from "@/contexts/shared/models/Pagination";

const toSearchOwnersRequest = (criteria: SearchOwnersCriteria, cursor?: string): SearchOwnersRequest => {
  return {
    search: criteria.search ?? undefined,
    name: criteria.name ?? undefined,
    email: criteria.email ?? undefined,
    phone: criteria.phone ?? undefined,
    language: criteria.language ?? undefined,
    cursor: cursor ?? undefined,
    order_by: criteria.order_by ?? undefined,
    sort: criteria.sort ?? undefined,
    per_page: criteria.per_page ?? undefined,
  };
};

const fromSearchOwnersResponse = (response: SearchOwnersResponse): Pagination<Owner> => {
  return {
    data: response.data.map((item) => ({
      id: item.id,
      name: item.name,
      email: item.email,
      language: item.language,
      phones: item.phones ?? [],
      notes: item.notes,
      default_bank_account_id: item.default_bank_account_id,
      bank_accounts_count: item.bank_accounts_count,
      accommodations_count: item.accommodations_count,
      notifications_count: item.notifications_count,
      read_notifications_count: item.read_notifications_count,
      tokens_count: item.tokens_count,
      unread_notifications_count: item.unread_notifications_count,
      created_at: new Date(item.created_at),
      updated_at: new Date(item.updated_at),
    })),
    meta: {
      next_cursor: response.meta.next_cursor,
      path: response.meta.path,
      per_page: response.meta.per_page,
      prev_cursor: response.meta.prev_cursor,
    },
    total: response.total,
  };
};

export default {
  toSearchOwnersRequest,
  fromSearchOwnersResponse,
};
