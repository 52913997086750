import archiveAccommodation from "@/clients/accommodations/ArchiveAccommodation/ArchiveAccommodation";
import createAccommodation from "@/clients/accommodations/CreateAccommodation/CreateAccommodation";
import deleteAccommodation from "@/clients/accommodations/DeleteAccommodation/DeleteAccommodation";
import facilitiesAccommodation from "@/clients/accommodations/FacilitiesAccommodation/FacilitiesAccommodation";
import unarchiveAccommodation from "@/clients/accommodations/UnrchiveAccommodation/UnarchiveAccommodation";

export default {
  deleteAccommodation,
  createAccommodation,
  archiveAccommodation,
  unarchiveAccommodation,
  facilitiesAccommodation,
};
