<template>
  <template v-if="showOwnerRequest.isLoading">
    <LoadingSection />
  </template>
  <template v-else-if="showOwnerRequest.hasErrors">
    <ListErrorState />
  </template>
  <template v-else-if="showOwnerRequest.data">
    <DashboardHeader>
      <DashboardBackTo :to="{ name: 'owners' }">
        {{ $t("owners.manage.back_to_list") }}
      </DashboardBackTo>
      <DashboardHeaderTitle>{{ $t("owners.manage.title") }}</DashboardHeaderTitle>
      <DashboardHeaderActions>
        <ClipboardCopyId :value="showOwnerRequest.data.id" />
        <OwnersDropdown
          :owner="showOwnerRequest.data"
          hide-edit
          @deleted="router.push({ name: 'owners' })"
        >
          <DropdownMenuButton v-slot="{ toggle }">
            <button
              type="button"
              class="btn-white"
              @click="toggle"
            >
              <FontAwesomeIcon
                class="h-5 w-5 fill-gray-700"
                icon="ellipsis-light"
              />
            </button>
          </DropdownMenuButton>
        </OwnersDropdown>
      </DashboardHeaderActions>
    </DashboardHeader>
    <SectionGroup>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("owners.manage.details.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="pen-solid"
              @click="updateOwnerModal?.open()"
            >
              {{ $t("owners.manage.details.edit") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <ListDetails>
          <ListDetailsColumn>
            <ListDetailsCell label="owners.props.id">
              {{ showOwnerRequest.data.id }}
            </ListDetailsCell>
            <ListDetailsCell label="owners.props.name">
              {{ showOwnerRequest.data.name || "-" }}
            </ListDetailsCell>
            <ListDetailsCell label="owners.props.email">
              {{ showOwnerRequest.data.email || "-" }}
            </ListDetailsCell>
            <ListDetailsCell label="owners.props.language">
              {{ showOwnerRequest.data.language ? $t(`shared.language.${showOwnerRequest.data.language.toLowerCase()}`) : "-" }}
            </ListDetailsCell>
            <ListDetailsCell label="owners.props.phone">
              {{ showOwnerRequest.data.phones?.length > 0 ? showOwnerRequest.data.phones[0] : "-" }}
            </ListDetailsCell>
            <ListDetailsCell label="owners.props.notes">
              {{ showOwnerRequest.data.notes || "-" }}
            </ListDetailsCell>
            <ListDetailsCell label="owners.props.updated_at">
              {{ showOwnerRequest.data.updated_at.toLocaleString() }}
            </ListDetailsCell>
            <ListDetailsCell label="owners.props.created_at">
              {{ showOwnerRequest.data.created_at.toLocaleString() }}
            </ListDetailsCell>
          </ListDetailsColumn>
        </ListDetails>
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("owners.manage.bank_accounts.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="building-columns-solid"
              @click="ownerBankAccountCreateModal?.open()"
            >
              {{ $t("owners.manage.bank_accounts.create") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <OwnersBankAccounts
          ref="ownerBankAccountsElement"
          :owner="showOwnerRequest.data"
          @selected-default="showOwnerRequest.data.default_bank_account_id = $event"
        />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("owners.manage.accommodations.title") }}</SectionTitle>
        </SectionHeader>
        <OwnersAccommodations :accommodations="showOwnerRequest.data.accommodations" />
      </section>
    </SectionGroup>
  </template>
  <ModalDialog
    ref="updateOwnerModal"
    v-slot="{ close }"
  >
    <OwnersUpdateModal
      :owner="showOwnerRequest.data"
      @close="close"
      @updated="showOwnerRequest.fetch()"
    />
  </ModalDialog>
  <ModalDialog
    ref="ownerBankAccountCreateModal"
    v-slot="{ close }"
    size="2xl"
  >
    <OwnerBankAccountsCreateModal
      :owner-id="showOwnerRequest.data.id"
      @close="close"
      @created="ownerBankAccountsElement?.fetch()"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import OwnerBankAccountsCreateModal from "@/contexts/owner-bank-accounts/ui/modals/OwnerBankAccountsCreateModal.vue";
import useOwners from "@/contexts/owners/composables/useOwners";
import type { Owner } from "@/contexts/owners/models/Owner";
import OwnersAccommodations from "@/contexts/owners/ui/components/OwnersAccommodations.vue";
import OwnersBankAccounts from "@/contexts/owners/ui/components/OwnersBankAccounts.vue";
import OwnersDropdown from "@/contexts/owners/ui/components/OwnersDropdown.vue";
import OwnersUpdateModal from "@/contexts/owners/ui/modals/OwnersUpdateModal.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import Errors from "@/contexts/shared/models/Errors";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import ClipboardCopyId from "@/contexts/shared/ui/components/clipboard/ClipboardCopyId.vue";
import DropdownMenuButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuButton.vue";
import DashboardBackTo from "@/contexts/shared/ui/components/header/DashboardBackTo.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import ListDetails from "@/contexts/shared/ui/components/list/ListDetails.vue";
import ListDetailsCell from "@/contexts/shared/ui/components/list/ListDetailsCell.vue";
import ListDetailsColumn from "@/contexts/shared/ui/components/list/ListDetailsColumn.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import SectionHeader from "@/contexts/shared/ui/components/section/SectionHeader.vue";
import SectionTitle from "@/contexts/shared/ui/components/section/SectionTitle.vue";
import type { ModalDialogInstance, OwnersBankAccountsInstance } from "@/types/instances";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const route = useRoute();
const router = useRouter();
const { showOwner } = useOwners();

const accountBus = useEventBus<string>("account");

accountBus.on(() => router.push({ name: "owners" }));
useHead({ title: () => `${$t("owners.manage.title")} - Hussbook` });

const ownerId = computed<string>(() => String(route.params.id));
const updateOwnerModal = ref<ModalDialogInstance>();

const ownerBankAccountCreateModal = ref<ModalDialogInstance>();
const ownerBankAccountsElement = ref<OwnersBankAccountsInstance>();

onMounted(() => {
  showOwnerRequest.fetch();
});

const showOwnerRequest = useRequest<Owner>({
  initialLoading: true,
  promise: () => showOwner(ownerId.value),
  onFailure: (e) => {
    if (e === Errors.NOT_FOUND) {
      router.push({ name: "owners" });
      return;
    }
  },
});
</script>
