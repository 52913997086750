import AccommodationAttachmentsClient from "@/clients/accommodation-attachments";
import GetAccommodationAttachmentsFactory from "@/contexts/accommodation-attachments/composables/factories/GetAccommodationAttachmentsFactory";
import ShowAccommodationAttachmentFactory from "@/contexts/accommodation-attachments/composables/factories/ShowAccommodationAttachmentFactory";
import type { AccommodationAttachment, AccommodationAttachmentWithUrl } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";

export default function useAccommodationAttachments() {
  const createAttachment = async (accommodationId: string, file: File): Promise<void> => {
    return await AccommodationAttachmentsClient.createAccommodationAttachment(accommodationId, file);
  };
  const getAttachments = async (accommodationId: string): Promise<AccommodationAttachment[]> => {
    return GetAccommodationAttachmentsFactory.fromGetAccommodationAttachmentsResponse(await AccommodationAttachmentsClient.getAccommodationAttachments(accommodationId));
  };
  const showAttachment = async (accommodationId: string, id: string): Promise<AccommodationAttachmentWithUrl> => {
    return ShowAccommodationAttachmentFactory.fromShowAccommodationAttachmentResponse(await AccommodationAttachmentsClient.showAccommodationAttachment(accommodationId, id));
  };
  const deleteAttachment = async (accommodationId: string, id: string): Promise<void> => {
    return await AccommodationAttachmentsClient.deleteAccommodationAttachment(accommodationId, id);
  };
  const defaultAttachment = async (accommodationId: string, id: string): Promise<void> => {
    return await AccommodationAttachmentsClient.defaultAccommodationAttachment(accommodationId, id);
  };

  return {
    getAttachments,
    showAttachment,
    createAttachment,
    deleteAttachment,
    defaultAttachment,
  };
}
