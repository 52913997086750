import type UpdateAccountServiceRequest from "@/clients/account-services/UpdateAccountService/request/UpdateAccountServiceRequest";
import type { UnsavedAccountService } from "@/contexts/accounts/models/AccountService";
import { pickBy } from "lodash-es";

const toUpdateAccountServiceRequest = (accountService: UnsavedAccountService): UpdateAccountServiceRequest => {
  return {
    name: pickBy(accountService.name),
    price: accountService.price,
    unit: accountService.unit,
    type: accountService.type,
  };
};

export default {
  toUpdateAccountServiceRequest,
};
