<template>
  <ListTable>
    <ListTableHeader>
      <ListTableHeaderCell primary>
        {{ $t("booking_imports.props.id") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>{{ $t("booking_imports.props.type") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>{{ $t("booking_imports.props.provider") }}</ListTableHeaderCell>
      <ListTableHeaderCell>{{ $t("booking_imports.props.status") }}</ListTableHeaderCell>
      <ListTableHeaderCell>{{ $t("booking_imports.props.bookings") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("booking_imports.props.created_at") }}
      </ListTableHeaderCell>
    </ListTableHeader>
    <ListTableBody>
      <template
        v-for="bookingImport in bookingImports"
        :key="bookingImport.id"
      >
        <ListTableBodyRow>
          <ListTableBodyCell>{{ bookingImport.id }}</ListTableBodyCell>
          <ListTableBodyCell optional>
            <BookingImportsTypeBadge :type="bookingImport.type" />
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            <BookingImportsProviderBadge :provider="bookingImport.provider" />
          </ListTableBodyCell>
          <ListTableBodyCell>
            <BookingImportsStatusBadge
              :status="bookingImport.status"
              :warnings="bookingImport.warnings"
            />
          </ListTableBodyCell>
          <ListTableBodyCell>
            <Badge class="badge-primary">
              {{ bookingImport.bookings?.length ?? 0 }}
            </Badge>
          </ListTableBodyCell>
          <ListTableBodyCell optional>{{ bookingImport.created_at.toLocaleString() }}</ListTableBodyCell>
        </ListTableBodyRow>
      </template>
    </ListTableBody>
  </ListTable>
</template>

<script setup lang="ts">
import type { BookingImport } from "@/contexts/booking-imports/models/BookingImport";
import BookingImportsProviderBadge from "@/contexts/booking-imports/ui/components/BookingImportsProviderBadge.vue";
import BookingImportsStatusBadge from "@/contexts/booking-imports/ui/components/BookingImportsStatusBadge.vue";
import BookingImportsTypeBadge from "@/contexts/booking-imports/ui/components/BookingImportsTypeBadge.vue";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";

defineProps<{
  bookingImports: BookingImport[];
}>();

defineEmits<{
  fetchList: [];
}>();

const { t: $t } = useI18n();
</script>
