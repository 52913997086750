import type UpdateAccountRequest from "@/clients/accounts/UpdateAccount/request/UpdateAccountRequest";
import type UpdateAccountResponse from "@/clients/accounts/UpdateAccount/response/UpdateAccountResponse";
import type { Account, UnsavedUpdateAccount } from "@/contexts/accounts/models/Account";
import { accountFromResponse } from "@/contexts/accounts/utils/accountFromResponse";

const toUpdateAccountRequest = (account: UnsavedUpdateAccount): UpdateAccountRequest => {
  return {
    name: account.name,
    email: account.email,
    icon: account.icon,
    logo: account.logo,
    primary_color: account.primary_color,
    secondary_color: account.secondary_color,
    street: account.street,
    city: account.city,
    postal_code: account.postal_code,
    country_code: account.country_code,
  };
};
const fromUpdateAccountResponse = (response: UpdateAccountResponse): Account => {
  return accountFromResponse(response.data);
};

export default {
  toUpdateAccountRequest,
  fromUpdateAccountResponse,
};
