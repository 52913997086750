import type GetAccountIntegrationsResponse from "@/clients/account-integrations/GetAccountIntegrations/response/GetAccountIntegrationsResponse";
import type { AccountIntegration, AccountIntegrationProvider, AccountIntegrations } from "@/contexts/accounts/models/AccountIntegration";

const fromGetAccountIntegrationsResponse = (response: GetAccountIntegrationsResponse): { credentials: AccountIntegration[]; integrations: AccountIntegrations } => {
  return {
    credentials: response.data.credentials.map((item) => ({
      id: item.id,
      provider: item.provider as AccountIntegrationProvider,
      credentials: item.credentials,
      enabled: item.enabled,
      created_at: new Date(item.created_at),
      updated_at: new Date(item.updated_at),
    })),
    integrations: response.data.integrations.map((item) => ({
      provider: item.provider as AccountIntegrationProvider,
      credentials: item.credentials,
    })),
  };
};

export default {
  fromGetAccountIntegrationsResponse,
};
