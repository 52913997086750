<template>
  <div>
    <ListTable v-if="attachments && attachments?.length > 0">
      <ListTableHeader>
        <ListTableHeaderCell primary>
          {{ $t("accommodation_attachments.props.name") }}
        </ListTableHeaderCell>
        <ListTableHeaderCell>{{ $t("accommodation_attachments.props.size") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>
          {{ $t("accommodations.props.created_at") }}
        </ListTableHeaderCell>
        <ListTableHeaderCell small />
      </ListTableHeader>
      <ListTableBody>
        <template
          v-for="attachment in attachments"
          :key="attachment.id"
        >
          <ListTableBodyRow>
            <ListTableBodyCell>
              <div class="flex items-center">
                <FileIcon
                  :icon="attachment.extension === 'pdf' ? 'pdf' : 'default'"
                  class="mr-2 h-4 w-4"
                />
                <span>{{ attachment.name }}</span>
                <Badge
                  v-if="accommodation?.default_attachment_id === attachment.id"
                  class="badge-primary ml-2"
                >
                  {{ $t("accommodation_attachments.props.is_default") }}
                </Badge>
              </div>
            </ListTableBodyCell>
            <ListTableBodyCell>{{ bytesToHuman(attachment.size) }}</ListTableBodyCell>
            <ListTableBodyCell optional>
              {{ attachment.created_at.toLocaleDateString() }}
            </ListTableBodyCell>
            <ListTableBodyCell
              :padding="false"
              :truncate="false"
              class="pl-2.5"
            >
              <AccommodationAttachmentsDropdown
                :accommodation="accommodation"
                :attachment="attachment"
                @deleted="$emit('deleted')"
                @selected-default="$emit('selectedDefault', attachment.id)"
              />
            </ListTableBodyCell>
          </ListTableBodyRow>
        </template>
      </ListTableBody>
    </ListTable>
    <SectionEmptyState v-else>
      {{ $t("accommodations.manage.attachments.empty") }}
    </SectionEmptyState>
  </div>
</template>

<script lang="ts" setup>
import type { AccommodationAttachment } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";
import AccommodationAttachmentsDropdown from "@/contexts/accommodation-attachments/ui/components/AccommodationAttachmentsDropdown.vue";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useFormatter from "@/contexts/shared/composables/useFormatter";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import FileIcon from "@/contexts/shared/ui/components/icon/FileIcon.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

defineProps<{
  accommodation: Accommodation | undefined;
  attachments: AccommodationAttachment[] | undefined;
}>();

defineEmits<{
  selectedDefault: [id: string];
  deleted: [];
}>();

const { t: $t } = useI18n();

const { bytesToHuman } = useFormatter();
</script>
