import AccountsClient from "@/clients/accounts";
import CreateAccountFactory from "@/contexts/accounts/composables/factories/CreateAccountFactory";
import UpdateAccountFactory from "@/contexts/accounts/composables/factories/UpdateAccountFactory";
import type { Account, UnsavedCreateAccount, UnsavedUpdateAccount } from "@/contexts/accounts/models/Account";

export default function useAccounts() {
  const createAccount = async (account: UnsavedCreateAccount): Promise<Account> => {
    return CreateAccountFactory.fromCreateAccountResponse(await AccountsClient.createAccount(CreateAccountFactory.toCreateAccountRequest(account)));
  };
  const updateAccount = async (id: string, account: UnsavedUpdateAccount): Promise<Account> => {
    return UpdateAccountFactory.fromUpdateAccountResponse(await AccountsClient.updateAccount(id, UpdateAccountFactory.toUpdateAccountRequest(account)));
  };

  return {
    createAccount,
    updateAccount,
  };
}
