<template>
  <DashboardHeader>
    <DashboardHeaderTitle>{{ $t("owners.title") }}</DashboardHeaderTitle>
    <DashboardHeaderActions>
      <RouterLinkWithIcon
        :to="{ name: 'owners.create' }"
        class="btn-primary"
        icon="plus-solid"
      >
        {{ $t("owners.create.title") }}
      </RouterLinkWithIcon>
    </DashboardHeaderActions>
  </DashboardHeader>
  <div class="space-y-3">
    <FilterGroup
      :is-empty="criteriaEmpty"
      @clear="clearCriteria"
    >
      <FilterItem
        v-model="criteria.search"
        label="owners.filters.search"
        title="owners.filters_by.search"
      >
        <FilterText type="contains" />
      </FilterItem>
      <FilterItem
        v-model="criteria.name"
        label="owners.filters.name"
        title="owners.filters_by.name"
      >
        <FilterText type="contains" />
      </FilterItem>
      <FilterItem
        v-model="criteria.email"
        label="owners.filters.email"
        title="owners.filters_by.email"
      >
        <FilterText type="contains" />
      </FilterItem>
      <FilterItem
        v-model="criteria.phone"
        label="owners.filters.phone"
        title="owners.filters_by.phone"
      >
        <FilterText type="contains" />
      </FilterItem>
      <FilterItem
        v-model="criteria.language"
        label="owners.filters.language"
        title="owners.filters_by.language"
      >
        <FilterOptions :options="useLanguage().languageOptions" />
      </FilterItem>
    </FilterGroup>
    <template v-if="searchOwnersRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchOwnersRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchOwnersRequest.data.data.length === 0">
      <ListEmptyState
        description="owners.empty.description"
        icon="building-user-solid"
        title="owners.empty.title"
      >
        <RouterLinkWithIcon
          :to="{ name: 'owners.create' }"
          class="btn-primary"
          icon="plus-solid"
        >
          {{ $t("owners.create.title") }}
        </RouterLinkWithIcon>
      </ListEmptyState>
    </template>
    <template v-else>
      <OwnersTable
        :owners="searchOwnersRequest.data.data"
        @deleted="changePage()"
        @updated="searchOwnersRequest.fetch()"
      />
      <CursorPagination
        :meta="searchOwnersRequest.data.meta"
        :total="searchOwnersRequest.data.total"
        @change-page="changePage"
      />
    </template>
  </div>
  <RouterView @fetch-list="changePage" />
</template>

<script lang="ts" setup>
import useOwners from "@/contexts/owners/composables/useOwners";
import type { Owner } from "@/contexts/owners/models/Owner";
import type { SearchOwnersCriteria } from "@/contexts/owners/models/SearchOwnersCriteria";
import OwnersTable from "@/contexts/owners/ui/components/OwnersTable.vue";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useLanguage from "@/contexts/shared/composables/useLanguage";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import { type Pagination, emptyPagination } from "@/contexts/shared/models/Pagination";
import RouterLinkWithIcon from "@/contexts/shared/ui/components/button/RouterLinkWithIcon.vue";
import FilterGroup from "@/contexts/shared/ui/components/filter/FilterGroup.vue";
import FilterItem from "@/contexts/shared/ui/components/filter/FilterItem.vue";
import FilterOptions from "@/contexts/shared/ui/components/filter/FilterOptions.vue";
import FilterText from "@/contexts/shared/ui/components/filter/FilterText.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const accountBus = useEventBus<string>("account");
const { searchOwners } = useOwners();

accountBus.on(() => clearCriteria());
useHead({ title: () => `${$t("owners.title")} - Hussbook` });

const { criteria, cursor, changePage, criteriaEmpty, clearCriteria } = useCriteria<SearchOwnersCriteria>({}, () => searchOwnersRequest.fetch());

onMounted(() => {
  searchOwnersRequest.fetch();
});

const searchOwnersRequest: UseRequest<Pagination<Owner>> = useRequest<Pagination<Owner>>({
  initialLoading: true,
  value: emptyPagination<Owner>(),
  promise: () => searchOwners(criteria.value, cursor.value),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});
</script>
