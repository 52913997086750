<template>
  <ConfirmDeleteModal
    :is-loading="deleteOwnerBankAccountRequest.isLoading"
    description="owner_bank_accounts.delete.description"
    title="owner_bank_accounts.delete.title"
    @close="$emit('close')"
    @confirm="deleteOwnerBankAccountRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useOwnerBankAccounts from "@/contexts/owner-bank-accounts/composables/useOwnerBankAccounts";
import type { OwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";

const props = defineProps<{
  bankAccount: OwnerBankAccount;
}>();

const emit = defineEmits<{
  close: [];
  deleted: [];
}>();

const { deleteOwnerBankAccount } = useOwnerBankAccounts();
const { successNotification, errorNotification } = useNotification();

const deleteOwnerBankAccountRequest = useRequest({
  promise: () => deleteOwnerBankAccount(props.bankAccount.owner_id, props.bankAccount.id),
  onSuccess: () => {
    successNotification("owner_bank_accounts.delete.success");
    emit("deleted");
    emit("close");
  },
  onFailure: () => {
    errorNotification("owner_bank_accounts.delete.error");
  },
});
</script>
