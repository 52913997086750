<template>
  <FilterItemButton @clear="clear">
    <div
      v-if="!empty"
      class="max-w-[15rem] truncate whitespace-nowrap pl-2 text-primary"
    >
      {{ selectedCleaningGroup?.name }}
    </div>
  </FilterItemButton>
  <FilterContent v-slot="{ close }">
    <div class="flex items-center space-x-2">
      <CleaningGroupsSelect
        v-model="cleaningGroupId"
        class="w-full"
        custom-width
      />
    </div>
    <button
      class="btn-primary flex w-full items-center py-1"
      type="submit"
      @click="apply(close)"
    >
      <div class="mx-auto">
        {{ $t("shared.filters.apply") }}
      </div>
    </button>
  </FilterContent>
</template>

<script lang="ts" setup>
import useCleaningGroups from "@/contexts/cleaning-groups/composables/useCleaningGroups";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import CleaningGroupsSelect from "@/contexts/cleaning-groups/ui/components/CleaningGroupsSelect.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import FilterContent from "@/contexts/shared/ui/components/filter/FilterContent.vue";
import FilterItemButton from "@/contexts/shared/ui/components/filter/FilterItemButton.vue";
import type { ComputedRef, WritableComputedRef } from "vue";

const empty = inject<ComputedRef<boolean>>("empty");
const input = inject<WritableComputedRef<string | undefined>>("input") as WritableComputedRef<string | undefined>;

const cleaningGroupId = ref<string | null>(input.value ?? null);
const selectedCleaningGroup = ref<CleaningGroup>();

const { t: $t } = useI18n();
const { showCleaningGroup } = useCleaningGroups();

onMounted(() => {
  if (cleaningGroupId.value) {
    showSelectedCleaningGroupsRequest.fetch();
  }
});
watch(input, (value) => {
  cleaningGroupId.value = value ?? null;
});

const apply = (close: () => void) => {
  input.value = cleaningGroupId.value ?? undefined;
  if (cleaningGroupId.value) {
    showSelectedCleaningGroupsRequest.fetch();
  } else {
    selectedCleaningGroup.value = undefined;
  }
  close();
};
const clear = () => {
  input.value = undefined;
};

const showSelectedCleaningGroupsRequest = useRequest<CleaningGroup>({
  promise: () => showCleaningGroup(String(cleaningGroupId.value)),
  onSuccess: (response) => {
    selectedCleaningGroup.value = response;
  },
});
</script>
