import type CreateAccommodationPhotoResponse from "@/clients/accommodation-photos/CreateAccommodationPhoto/response/CreateAccommodationPhotoResponse";
import axios from "@/libs/axios";

export default async function createAccommodationPhoto(accommodationId: string, file: File): Promise<CreateAccommodationPhotoResponse> {
  const request = new FormData();
  request.append("file", file);
  try {
    const { data } = await axios.post<CreateAccommodationPhotoResponse>(`/accommodations/${accommodationId}/photos`, request, { headers: { "Content-Type": "multipart/form-data" } });
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
