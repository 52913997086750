<template>
  <main>
    <Component :is="route.meta.layout ? layouts[route.meta.layout] : ('div' ?? 'div')">
      <RouterView />
    </Component>
  </main>
  <Teleport to="body">
    <Toaster />
  </Teleport>
</template>

<script lang="ts" setup>
import { useRegisterSW } from "virtual:pwa-register/vue";
import { layouts } from "@/contexts/shared/models/Layout";
import { Toaster } from "vue-sonner";

const route = useRoute();
const { locale, availableLocales } = useI18n();
const { needRefresh, updateServiceWorker } = useRegisterSW();

onMounted(() => {
  const browserLanguage = navigator.language?.substring(0, 2).toLowerCase();
  if (browserLanguage && availableLocales.includes(browserLanguage)) {
    locale.value = browserLanguage;
  }
  if (needRefresh.value) {
    updateServiceWorker();
  }
});
</script>
