<template>
  <FilterItemButton @clear="clear">
    <div
      v-if="!empty"
      class="max-w-[15rem] truncate whitespace-nowrap pl-2 text-primary"
    >
      {{ input }}
    </div>
  </FilterItemButton>
  <FilterContent v-slot="{ close }">
    <div class="flex items-center space-x-2">
      <div class="whitespace-nowrap text-sm text-gray-600">
        {{ $t(`shared.filters.text.${type}`) }}
      </div>
      <form
        class="w-full"
        @submit.prevent="apply(close)"
      >
        <FormText
          v-model="text"
          name="search"
        />
        <input
          class="hidden"
          type="submit"
        />
      </form>
    </div>
    <button
      class="btn-primary flex w-full items-center py-1"
      type="submit"
      @click="apply(close)"
    >
      <div class="mx-auto">
        {{ $t("shared.filters.apply") }}
      </div>
    </button>
  </FilterContent>
</template>

<script lang="ts" setup>
import FilterContent from "@/contexts/shared/ui/components/filter/FilterContent.vue";
import FilterItemButton from "@/contexts/shared/ui/components/filter/FilterItemButton.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import type { ComputedRef, WritableComputedRef } from "vue";

defineProps<{
  type: "contains" | "equals";
}>();

const { t: $t } = useI18n();

const empty = inject<ComputedRef<boolean>>("empty");
const input = inject<WritableComputedRef<string | undefined>>("input") as WritableComputedRef<string | undefined>;

const text = ref<string>(String(input.value ?? ""));

watch(input, (value) => {
  text.value = value ?? "";
});

const apply = (close: () => void) => {
  input.value = text.value?.trim() !== "" ? text.value : undefined;
  close();
};
const clear = () => {
  input.value = undefined;
};
</script>
