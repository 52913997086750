<template>
  <ConfirmModal
    :is-loading="confirmAccommodationContractRequest.isLoading"
    title="accommodation_contracts.confirm.title"
    @close="$emit('close')"
    @confirm="submit"
  >
    <FormSignature ref="signature" />
  </ConfirmModal>
</template>

<script lang="ts" setup>
import useAccommodationContracts from "@/contexts/accommodation-contracts/composables/useAccommodationContracts";
import type { AccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import FormSignature from "@/contexts/shared/ui/components/form/FormSignature.vue";
import ConfirmModal from "@/contexts/shared/ui/modals/ConfirmModal.vue";
import type { FormSignatureInstance } from "@/types/instances";

const props = defineProps<{
  contract: AccommodationContract;
}>();

const emit = defineEmits<{
  close: [];
  confirmed: [];
}>();

const { confirmAccommodationContract } = useAccommodationContracts();
const { successNotification, errorNotification } = useNotification();

const signature = ref<FormSignatureInstance>();

const confirmAccommodationContractRequest = useRequest({
  keepLoading: true,
  promise: (signature: string) => confirmAccommodationContract(props.contract.accommodation_id, props.contract.id, signature),
  onSuccess: () => {
    successNotification("accommodation_contracts.confirm.success");
    emit("confirmed");
    emit("close");
  },
  onFailure: () => {
    errorNotification("accommodation_contracts.confirm.error");
  },
});

const submit = () => {
  if (signature.value?.isEmpty()) {
    errorNotification("accommodation_contracts.confirm.signature.required");
    return;
  }

  confirmAccommodationContractRequest.fetch(signature.value?.save());
};
</script>
