import type ShowBookingGuestResponse from "@/clients/booking-guests/ShowBookingGuests/response/ShowBookingGuestResponse";
import Errors from "@/contexts/shared/models/Errors";
import axios from "@/libs/axios";
import { AxiosError } from "axios";

export default async function showBookingGuest(id: string): Promise<ShowBookingGuestResponse> {
  try {
    const { data } = await axios.get<ShowBookingGuestResponse>(`/booking-guests/${id}`);
    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return Promise.reject(Errors.NOT_FOUND);
    }
    return Promise.reject(e);
  }
}
