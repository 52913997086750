<template>
  <div>
    <template v-if="extras && extras?.length > 0">
      <ListTable>
        <ListTableHeader>
          <ListTableHeaderCell primary>
            {{ $t("bookings.extras.props.name") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell>
            {{ $t("bookings.extras.props.amount") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell>
            {{ $t("bookings.extras.props.units") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.extras.props.mode") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.extras.props.payment_time") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.extras.props.paid_at") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.extras.props.created_at") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("bookings.extras.props.updated_at") }}
          </ListTableHeaderCell>
        </ListTableHeader>
        <ListTableBody>
          <template
            v-for="extra in extras"
            :key="extra.id"
          >
            <ListTableBodyRow>
              <ListTableBodyCell class="w-full">
                {{ $t(`accommodations.extras.name.${extra.accommodation_extra?.name}`) }}
              </ListTableBodyCell>
              <ListTableBodyCell>
                {{ `${Number(extra.amount / 100).toFixed(2)}€` }}
              </ListTableBodyCell>
              <ListTableBodyCell>
                {{ $t("bookings.extras.units", extra.units) }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ $t(`bookings.extras.mode.${extra.mode}`) }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ $t(`bookings.extras.payment_time.${extra.payment_time}`) }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ extra.paid_at?.toLocaleDateString() || "-" }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ extra.created_at.toLocaleDateString() }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ extra.updated_at.toLocaleDateString() }}
              </ListTableBodyCell>
            </ListTableBodyRow>
          </template>
        </ListTableBody>
      </ListTable>
    </template>
    <SectionEmptyState v-else>
      {{ $t("bookings.extras.empty") }}
    </SectionEmptyState>
  </div>
</template>

<script lang="ts" setup>
import type { BookingExtra } from "@/contexts/bookings/models/BookingExtra";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

defineProps<{
  extras: BookingExtra[] | undefined;
}>();

const { t: $t } = useI18n();
</script>
