import CleaningGroupsCreatePage from "@/contexts/cleaning-groups/ui/pages/CleaningGroupsCreatePage.vue";
import CleaningGroupsManagePage from "@/contexts/cleaning-groups/ui/pages/CleaningGroupsManagePage.vue";
import CleaningGroupsPage from "@/contexts/cleaning-groups/ui/pages/CleaningGroupsPage.vue";
import Middleware from "@/contexts/shared/models/Middleware";
import type { RouteRecordRaw, Router } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    name: "cleaning_groups",
    path: "/cleaning-groups",
    component: CleaningGroupsPage,
    meta: {
      layout: "dashboard",
      group: "cleaning_groups",
      middleware: [Middleware.auth],
    },
    children: [
      {
        name: "cleaning_groups.create",
        path: "/cleaning-groups/create",
        component: CleaningGroupsCreatePage,
        meta: {
          layout: "dashboard",
          group: "cleaning_groups",
          middleware: [Middleware.auth],
        },
      },
    ],
  },
  {
    name: "cleaning_groups.manage",
    path: "/cleaning-groups/:id",
    component: CleaningGroupsManagePage,
    meta: {
      layout: "dashboard",
      group: "cleaning_groups",
      middleware: [Middleware.auth],
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
