<template>
  <div>
    <template v-if="cleanings && cleanings?.length > 0">
      <ListTable>
        <ListTableHeader>
          <ListTableHeaderCell primary>
            {{ $t("booking_cleanings.props.type") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell>
            {{ $t("booking_cleanings.props.datetime") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell>
            {{ $t("booking_cleanings.props.cleaning_group_id") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("booking_cleanings.props.single_beds") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("booking_cleanings.props.double_beds") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("booking_cleanings.props.suite_beds") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell optional>
            {{ $t("booking_cleanings.props.bunk_beds") }}
          </ListTableHeaderCell>
          <ListTableHeaderCell small />
        </ListTableHeader>
        <ListTableBody>
          <template
            v-for="cleaning in cleanings"
            :key="cleaning.id"
          >
            <ListTableBodyRow>
              <ListTableBodyCell class="w-full">
                <BookingCleaningsTypeIcon :type="cleaning.type" />
              </ListTableBodyCell>
              <ListTableBodyCell>
                {{ cleaning.datetime?.toLocaleString() ?? "-" }}
              </ListTableBodyCell>
              <ListTableBodyCell>
                <CleaningGroupsDetailsLink :cleaning-group="cleaning?.cleaning_group" />
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ cleaning.single_beds }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ cleaning.double_beds }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ cleaning.suite_beds }}
              </ListTableBodyCell>
              <ListTableBodyCell optional>
                {{ cleaning.bunk_beds }}
              </ListTableBodyCell>
              <ListTableBodyCell
                :padding="false"
                :truncate="false"
                class="pl-2.5"
              >
                <BookingCleaningsDropdown :cleaning="cleaning" />
              </ListTableBodyCell>
            </ListTableBodyRow>
          </template>
        </ListTableBody>
      </ListTable>
    </template>
    <SectionEmptyState v-else>
      {{ $t("booking_cleanings.empty.title") }}
    </SectionEmptyState>
  </div>
</template>

<script lang="ts" setup>
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import BookingCleaningsDropdown from "@/contexts/booking-cleanings/ui/components/BookingCleaningsDropdown.vue";
import BookingCleaningsTypeIcon from "@/contexts/booking-cleanings/ui/components/BookingCleaningsTypeIcon.vue";
import CleaningGroupsDetailsLink from "@/contexts/cleaning-groups/ui/components/CleaningGroupsDetailsLink.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

defineProps<{
  cleanings: BookingCleaning[] | undefined;
}>();

const { t: $t } = useI18n();
</script>
