import type UpdateAccountSeasonRequest from "@/clients/account-seasons/UpdateAccountSeasons/request/UpdateAccountSeasonRequest";
import type UpdateAccountSeasonsResponse from "@/clients/account-seasons/UpdateAccountSeasons/response/UpdateAccountSeasonsResponse";
import axios from "@/libs/axios";
import { pickBy } from "lodash-es";

export default async function updateAccountSeason(year: number, week: number, request: UpdateAccountSeasonRequest): Promise<UpdateAccountSeasonsResponse> {
  try {
    const { data } = await axios.post<UpdateAccountSeasonsResponse>(`/settings/account/seasons/${year}/${week}`, pickBy(request));
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
