import csrf from "@/clients/auth/Csrf/Csrf";
import login from "@/clients/auth/Login/Login";
import logout from "@/clients/auth/Logout/Logout";
import user from "@/clients/auth/User/User";

export default {
  csrf,
  user,
  login,
  logout,
};
