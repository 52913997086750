import type CreateAccountIntegrationRequest from "@/clients/account-integrations/CreateAccountIntegration/request/CreateAccountIntegrationRequest";
import type CreateAccountIntegrationResponse from "@/clients/account-integrations/CreateAccountIntegration/response/CreateAccountIntegrationResponse";
import type { AccountIntegration, AccountIntegrationProvider, UnsavedAccountIntegration } from "@/contexts/accounts/models/AccountIntegration";

const toCreateAccountIntegrationRequest = (integration: UnsavedAccountIntegration): CreateAccountIntegrationRequest => {
  return {
    provider: integration.provider,
    credentials: integration.credentials,
  };
};
const fromCreateAccountIntegrationResponse = (response: CreateAccountIntegrationResponse): AccountIntegration => {
  return {
    id: response.data.id,
    provider: response.data.provider as AccountIntegrationProvider,
    credentials: response.data.credentials,
    enabled: response.data.enabled,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  toCreateAccountIntegrationRequest,
  fromCreateAccountIntegrationResponse,
};
