<template>
  <DashboardHeader>
    <DashboardBackTo :to="{ name: 'booking_cleanings' }">
      {{ $t("booking_cleanings.planner.back_to_list") }}
    </DashboardBackTo>
    <DashboardHeaderTitle>{{ $t("booking_cleanings.planner.title") }}</DashboardHeaderTitle>
  </DashboardHeader>
  <div class="space-y-3">
    <FilterGroup
      :is-empty="criteriaEmpty"
      @clear="clearCriteria"
    >
      <FilterItem
        v-model="criteria.date"
        required
        label="booking_cleanings.planner.filters.date"
        title="booking_cleanings.planner.filters_by.date"
      >
        <FilterDateRange />
      </FilterItem>
    </FilterGroup>
    <FilterQuick :filters="quickFilters" />
    <template v-if="searchBookingCleaningsPlannerRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchBookingCleaningsPlannerRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchBookingCleaningsPlannerRequest.data.bookings.length === 0">
      <ListEmptyState
        description="booking_cleanings.planner.empty.description"
        icon="calendar-week-solid"
        title="booking_cleanings.planner.empty.title"
      />
    </template>
    <template v-else>
      <BookingCleaningsPlannerTable
        v-model="criteria"
        :accommodations="searchBookingCleaningsPlannerRequest.data?.accommodations"
        :bookings="searchBookingCleaningsPlannerRequest.data?.bookings"
        @updated-booking-cleaning="updatedBookingCleaning($event.booking, $event.cleaning)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useBookingCleaningsPlanner from "@/contexts/booking-cleanings/composables/useBookingCleaningsPlanner";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import type { SearchBookingCleaningsPlannerCriteria } from "@/contexts/booking-cleanings/models/SearchBookingCleaningsPlannerCriteria";
import BookingCleaningsPlannerTable from "@/contexts/booking-cleanings/ui/components/BookingCleaningsPlannerTable.vue";
import type { Booking } from "@/contexts/bookings/models/Booking";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import type { QuickFilter } from "@/contexts/shared/models/QuickFilter";
import FilterDateRange from "@/contexts/shared/ui/components/filter/FilterDateRange.vue";
import FilterGroup from "@/contexts/shared/ui/components/filter/FilterGroup.vue";
import FilterItem from "@/contexts/shared/ui/components/filter/FilterItem.vue";
import FilterQuick from "@/contexts/shared/ui/components/filter/FilterQuick.vue";
import DashboardBackTo from "@/contexts/shared/ui/components/header/DashboardBackTo.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import { calculateDateRange } from "@/contexts/shared/utils/calculateDaterange";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const dayjs = useDayjs();
const accountBus = useEventBus<string>("account");
const { searchBookingCleaningsPlanner } = useBookingCleaningsPlanner();

accountBus.on(() => clearCriteria());
useHead({ title: () => `${$t("booking_cleanings.planner.title")} - Hussbook` });

const { criteria, clearCriteria, criteriaEmpty } = useCriteria<SearchBookingCleaningsPlannerCriteria>({ date: { from: dayjs().startOf("day").toDate(), to: dayjs().add(30, "day").endOf("day").toDate() } }, () => searchBookingCleaningsPlannerRequest.fetch());

onMounted(() => {
  searchBookingCleaningsPlannerRequest.fetch();
});

const searchBookingCleaningsPlannerRequest: UseRequest<{ accommodations: Accommodation[]; bookings: Booking[] }> = useRequest<{ accommodations: Accommodation[]; bookings: Booking[] }>({
  initialLoading: true,
  value: { accommodations: [], bookings: [] },
  promise: () => searchBookingCleaningsPlanner(criteria.value),
});

const updatedBookingCleaning = (booking: Booking, cleaning: BookingCleaning) => {
  const bookingIndex = searchBookingCleaningsPlannerRequest.data.bookings.findIndex((b) => b.id === booking.id);
  searchBookingCleaningsPlannerRequest.data.bookings[bookingIndex].booking_cleanings = [...(searchBookingCleaningsPlannerRequest.data.bookings[bookingIndex].booking_cleanings?.filter((bc) => bc.id !== cleaning.id) ?? []), cleaning];
};

const isDateRange = (start: number, end: number) =>
  computed(() => {
    const range = calculateDateRange(start, end);
    return dayjs(criteria.value.date.from).isSame(range.from, "day") && dayjs(criteria.value.date.to).isSame(range.to, "day");
  });

const quickFilters: QuickFilter[] = [
  {
    label: "booking_cleanings.planner.filters_quick.current_30_days",
    active: isDateRange(0, 30),
    action: () => {
      criteria.value.date = calculateDateRange(0, 30);
    },
  },
  {
    label: "booking_cleanings.planner.filters_quick.current_30_to_60_days",
    active: isDateRange(30, 60),
    action: () => {
      criteria.value.date = calculateDateRange(30, 60);
    },
  },
];
</script>
