import AuthLoginPage from "@/contexts/auth/ui/pages/AuthLoginPage.vue";
import AuthRegisterPage from "@/contexts/auth/ui/pages/AuthRegisterPage.vue";
import AuthResetPassword from "@/contexts/auth/ui/pages/AuthResetPassword.vue";
import Middleware from "@/contexts/shared/models/Middleware";
import type { RouteRecordRaw, Router } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    name: "login",
    path: "/login",
    component: AuthLoginPage,
    meta: {
      layout: "auth",
      middleware: [Middleware.guest],
    },
  },
  {
    name: "register",
    path: "/register",
    component: AuthRegisterPage,
    meta: {
      layout: "auth",
      middleware: [Middleware.guest],
    },
  },
  {
    name: "reset",
    path: "/reset",
    component: AuthResetPassword,
    meta: {
      layout: "auth",
      middleware: [Middleware.guest],
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
