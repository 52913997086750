<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accounts.create.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <AccountsCreateSimpleForm
      v-model="form"
      :errors="createAccountRequest.errors"
      @submit="submit"
    />
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="createAccountRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import useAccounts from "@/contexts/accounts/composables/useAccounts";
import type { Account, UnsavedCreateAccount } from "@/contexts/accounts/models/Account";
import AccountsCreateSimpleForm from "@/contexts/accounts/ui/components/AccountsCreateSimpleForm.vue";
import useAuth from "@/contexts/auth/composables/useAuth";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import { useEventBus } from "@vueuse/core";

const emit = defineEmits<{
  close: [];
}>();

const { t: $t } = useI18n();
const accountBus = useEventBus<string>("account");
const { createAccount } = useAccounts();
const { fetchUser, changeAccount, accounts } = useAuth();
const { successNotification, errorNotification } = useNotification();

const form = ref<UnsavedCreateAccount>({ name: "", email: "" });

const createAccountRequest = useRequest<Account>({
  promise: () => createAccount(form.value),
  onSuccess: async (response) => {
    await fetchUser();
    changeAccount(accounts.value.find((item) => item.id === response.id));
    accountBus.emit(response.id);
    successNotification("accounts.create.success");
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accounts.create.error");
    }
  },
});

const submit = async () => {
  await createAccountRequest.fetch();
};
</script>
