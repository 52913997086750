<template>
  <DashboardHeader>
    <DashboardHeaderTitle>{{ $t("accommodations.title") }}</DashboardHeaderTitle>
    <DashboardHeaderActions>
      <RouterLinkWithIcon
        :to="{ name: 'accommodations.prices' }"
        class="btn-white"
        icon="calendar-lines-pen-solid"
      >
        {{ $t("accommodations.prices.title") }}
      </RouterLinkWithIcon>
      <RouterLinkWithIcon
        :to="{ name: 'accommodations.create' }"
        class="btn-primary"
        icon="plus-solid"
      >
        {{ $t("accommodations.create.title") }}
      </RouterLinkWithIcon>
    </DashboardHeaderActions>
  </DashboardHeader>
  <div class="space-y-3">
    <FilterGroup
      :is-empty="criteriaEmpty"
      @clear="clearCriteria"
    >
      <FilterItem
        v-model="criteria.name"
        label="accommodations.filters.name"
        title="accommodations.filters_by.name"
      >
        <FilterText type="contains" />
      </FilterItem>
      <FilterItem
        v-model="criteria.address"
        label="accommodations.filters.address"
        title="accommodations.filters_by.address"
      >
        <FilterText type="contains" />
      </FilterItem>
      <FilterItem
        v-model="criteria.cleaning_group_id"
        label="accommodations.filters.cleaning_group"
        title="accommodations.filters_by.cleaning_group"
      >
        <CleaningGroupsFilter />
      </FilterItem>
      <FilterItem
        v-model="criteria.archived"
        label="accommodations.filters.status"
        title="accommodations.filters_by.status"
      >
        <FilterRadio :options="useArchived().archivedOptions" />
      </FilterItem>
      <FilterItem
        v-model="criteria.featured"
        label="accommodations.filters.featured"
        title="accommodations.filters_by.featured"
      >
        <FilterRadio :options="useFeatured().featuredOptions" />
      </FilterItem>
    </FilterGroup>
    <template v-if="searchAccommodationsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchAccommodationsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchAccommodationsRequest.data.data.length === 0">
      <ListEmptyState
        description="accommodations.empty.description"
        icon="house-building-solid"
        title="accommodations.empty.title"
      >
        <RouterLinkWithIcon
          :to="{ name: 'accommodations.create' }"
          class="btn-primary"
          icon="plus-solid"
        >
          {{ $t("accommodations.create.title") }}
        </RouterLinkWithIcon>
      </ListEmptyState>
    </template>
    <template v-else>
      <AccommodationsTable
        :accommodations="searchAccommodationsRequest.data.data"
        @fetch-list="searchAccommodationsRequest.fetch()"
      />
      <CursorPagination
        :meta="searchAccommodationsRequest.data.meta"
        :total="searchAccommodationsRequest.data.total"
        @change-page="changePage"
      />
    </template>
  </div>
  <RouterView @fetch-list="changePage" />
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { SearchAccommodationsCriteria } from "@/contexts/accommodations/models/SearchAccommodationsCriteria";
import AccommodationsTable from "@/contexts/accommodations/ui/components/AccommodationsTable.vue";
import CleaningGroupsFilter from "@/contexts/cleaning-groups/ui/components/CleaningGroupsFilter.vue";
import useArchived from "@/contexts/shared/composables/useArchived";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useFeatured from "@/contexts/shared/composables/useFeatured";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { emptyPagination } from "@/contexts/shared/models/Pagination";
import RouterLinkWithIcon from "@/contexts/shared/ui/components/button/RouterLinkWithIcon.vue";
import FilterGroup from "@/contexts/shared/ui/components/filter/FilterGroup.vue";
import FilterItem from "@/contexts/shared/ui/components/filter/FilterItem.vue";
import FilterRadio from "@/contexts/shared/ui/components/filter/FilterRadio.vue";
import FilterText from "@/contexts/shared/ui/components/filter/FilterText.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const accountBus = useEventBus<string>("account");
const accommodationsBus = useEventBus<string>("accommodations");
const { searchAccommodations } = useAccommodations();

accountBus.on(() => clearCriteria());
accommodationsBus.on(() => searchAccommodationsRequest.fetch());
useHead({ title: () => `${$t("accommodations.title")} - Hussbook` });

onMounted(() => {
  searchAccommodationsRequest.fetch();
});

const { criteria, cursor, changePage, criteriaEmpty, clearCriteria } = useCriteria<SearchAccommodationsCriteria>({}, () => searchAccommodationsRequest.fetch());

const searchAccommodationsRequest: UseRequest<Pagination<Accommodation>> = useRequest<Pagination<Accommodation>>({
  initialLoading: true,
  value: emptyPagination<Accommodation>(),
  promise: () => searchAccommodations(criteria.value, cursor.value),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});
</script>
