import CreateAccommodationContractFactory from "@/contexts/accommodation-contracts/composables/factories/CreateAccommodationContractFactory";
import GetAccommodationContractFactory from "@/contexts/accommodation-contracts/composables/factories/GetAccommodationContractFactory";
import PdfAccommodationContractFactory from "@/contexts/accommodation-contracts/composables/factories/PdfAccommodationContractFactory";
import SearchAccommodationContractsFactory from "@/contexts/accommodation-contracts/composables/factories/SearchAccommodationContractsFactory";
import UpdateAccommodationContractFactory from "@/contexts/accommodation-contracts/composables/factories/UpdateAccommodationContractFactory";
import type { AccommodationContract, UnsavedAccommodationContract, UnsavedAccommodationContractUpdated } from "@/contexts/accommodations/models/AccommodationContract";
import type { SearchAccommodationContractsCriteria } from "@/contexts/accommodations/models/SearchAccommodationContractsCriteria";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import api from "@/libs/api";

export default function useAccommodationContracts() {
  const searchAccommodationContracts = async (accommodationId: string, criteria: SearchAccommodationContractsCriteria, cursor?: string): Promise<Pagination<AccommodationContract>> => {
    const { data, response } = await api.GET("/accommodations/{accommodation}/contracts", { params: { path: { accommodation: accommodationId }, query: SearchAccommodationContractsFactory.toSearchAccommodationContractsRequest(criteria, cursor) } });
    return SearchAccommodationContractsFactory.fromSearchAccommodationContractsResponse(data, response.headers);
  };
  const createAccommodationContract = async (accommodationId: string, contract: UnsavedAccommodationContract): Promise<AccommodationContract> => {
    const { data } = await api.POST("/accommodations/{accommodation}/contracts", { params: { path: { accommodation: accommodationId } }, body: CreateAccommodationContractFactory.toCreateAccommodationContractRequest(contract) });
    return Promise.resolve(CreateAccommodationContractFactory.fromCreateAccommodationContractResponse(data));
  };
  const getAccommodationContract = async (accommodationId: string, contractId: string): Promise<AccommodationContract> => {
    const { data } = await api.GET("/accommodations/{accommodation}/contracts/{contract}", { params: { path: { accommodation: accommodationId, contract: contractId } } });
    return GetAccommodationContractFactory.fromGetAccommodationContractResponse(data);
  };
  const updateAccommodationContract = async (accommodationId: string, contractId: string, contract: UnsavedAccommodationContractUpdated): Promise<AccommodationContract> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/contracts/{contract}", { params: { path: { accommodation: accommodationId, contract: contractId } }, body: UpdateAccommodationContractFactory.toUpdateAccommodationContractRequest(contract) });
    return Promise.resolve(UpdateAccommodationContractFactory.fromUpdateAccommodationContractResponse(data));
  };
  const approveAccommodationContract = async (accommodationId: string, contractId: string): Promise<void> => {
    await api.PATCH("/accommodations/{accommodation}/contracts/{contract}/approve", { params: { path: { accommodation: accommodationId, contract: contractId } } });
  };
  const confirmAccommodationContract = async (accommodationId: string, contractId: string, signature: string): Promise<void> => {
    await api.PATCH("/accommodations/{accommodation}/contracts/{contract}/confirm", { params: { path: { accommodation: accommodationId, contract: contractId } }, body: { signature } });
  };
  const pdfAccommodationContract = async (accommodationId: string, contractId: string): Promise<string> => {
    const { data } = await api.GET("/accommodations/{accommodation}/contracts/{contract}/pdf", { params: { path: { accommodation: accommodationId, contract: contractId } } });
    return PdfAccommodationContractFactory.fromPdfAccommodationContractFactory(data);
  };
  const deleteAccommodationContract = async (accommodationId: string, contractId: string): Promise<void> => {
    await api.DELETE("/accommodations/{accommodation}/contracts/{contract}", { params: { path: { accommodation: accommodationId, contract: contractId } } });
  };

  return {
    searchAccommodationContracts,
    createAccommodationContract,
    getAccommodationContract,
    updateAccommodationContract,
    approveAccommodationContract,
    confirmAccommodationContract,
    deleteAccommodationContract,
    pdfAccommodationContract,
  };
}
