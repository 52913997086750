<template>
  <div class="overflow-x-auto border border-gray-200 text-gray-800">
    <div class="flex min-w-full">
      <div class="flex flex-shrink-0 flex-col">
        <div class="max-w-1/3 h-10 border-x border-b border-gray-200 bg-gray-100 md:w-48">
          <div class="flex h-10 w-full border-b border-r border-gray-200" />
          <div class="flex h-8 items-center border-b border-r border-gray-200">
            <div class="px-2 text-sm text-gray-600">{{ $t("accommodations.prices.availability") }}</div>
          </div>
          <div class="flex h-8 items-center border-b border-r border-gray-200">
            <div class="px-2 text-sm text-gray-600">{{ $t("accommodations.prices.minimum_stay") }}</div>
          </div>
          <template
            v-for="item in los"
            :key="item"
          >
            <div class="flex h-10 items-center border-b border-r border-gray-200 last:border-b-0">
              <div class="px-2 text-sm text-gray-600">{{ $t("accommodations.prices.los", Number(item)) }}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="h-full flex-1 overflow-x-auto bg-gray-100">
        <div
          ref="target"
          class="relative flex h-full"
        >
          <template
            v-for="(day, index) in days"
            :key="index"
          >
            <div class="flex select-none flex-col">
              <div class="flex h-10 w-16 border-b border-r border-gray-200">
                <div class="m-auto">
                  <div class="text-center text-xs text-gray-500">
                    {{ $t(`shared.dayjs.weekdays_short`).split("_")[day.getUTCDay()] }}
                  </div>
                  <div class="text-center text-sm text-gray-800">
                    {{ day.getDate() }}
                  </div>
                </div>
              </div>
              <AccommodationsPricesTableDay
                v-for="(price, index) in prices"
                :key="`calendar_day_${index}`"
                :availabilities="price.availabilities"
                :availability="price.availabilities[$dayjs(day).format('YYYY-MM-DD')]"
                :minimum-stay="price.minimum_stays[$dayjs(day).format('YYYY-MM-DD')]"
                :day="$dayjs(day).format('YYYY-MM-DD')"
                :prices="price.prices[$dayjs(day).format('YYYY-MM-DD')]"
                @select="() => (selected = { accommodation: price.accommodation, value: [day, day] })"
                @drag="onDrag(day, price.accommodation)"
              />
            </div>
          </template>
          <template v-if="selected">
            <div
              ref="element"
              :class="cn('absolute top-[4.5rem] z-10 h-[calc(100%-4.5rem)]', { 'pointer-events-none': !!dragging })"
              :style="{ left: `calc(4rem * ${previousDays})`, width: `calc(4rem * ${selectedDays})` }"
            >
              <div class="relative size-full">
                <div class="absolute -left-2 top-1/2 flex -translate-y-1/2 cursor-ew-resize">
                  <AccommodationsPricesTableDragButton
                    @dragstart="dragging = 'left'"
                    @dragend="dragging = undefined"
                  />
                </div>
                <div
                  ref="selection"
                  class="size-full border-2 border-primary bg-primary/25"
                />
                <teleport to="body">
                  <template v-if="!!selected">
                    <AccommodationsPricesTableSelectedForm
                      v-show="elementVisible"
                      ref="floating"
                      :style="editorFloatingStyles"
                      :selected="selected"
                      @updated="updatedPrices"
                    />
                  </template>
                </teleport>
                <div class="absolute -right-2 top-1/2 flex -translate-y-1/2 cursor-ew-resize">
                  <AccommodationsPricesTableDragButton
                    @dragstart="dragging = 'right'"
                    @dragend="dragging = undefined"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { AccommodationPrices } from "@/contexts/accommodations/models/AccommodationPrices";
import type { SearchAccommodationsPricesCriteria } from "@/contexts/accommodations/models/SearchAccommodationsPricesCriteria";
import AccommodationsPricesTableDay from "@/contexts/accommodations/ui/components/AccommodationsPricesTableDay.vue";
import AccommodationsPricesTableDragButton from "@/contexts/accommodations/ui/components/AccommodationsPricesTableDragButton.vue";
import AccommodationsPricesTableSelectedForm from "@/contexts/accommodations/ui/components/AccommodationsPricesTableSelectedForm.vue";
import useAuth from "@/contexts/auth/composables/useAuth";
import useCalendar from "@/contexts/shared/composables/useCalendar";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import cn from "@/contexts/shared/utils/cn";
import { autoPlacement, autoUpdate, offset, shift, useFloating } from "@floating-ui/vue";
import { onClickOutside, useElementVisibility } from "@vueuse/core";

defineProps<{
  prices: AccommodationPrices[];
}>();

const emit = defineEmits<{
  updated: [];
}>();

const modelValue = defineModel<SearchAccommodationsPricesCriteria>({ default: { date: { start: new Date(), end: new Date() } } });

const { t: $t } = useI18n();
const dayjs = useDayjs();
const { authenticatedAccount } = useAuth();
const { days } = useCalendar<SearchAccommodationsPricesCriteria>({ value: modelValue, key: "date" });

const target = ref<HTMLElement>();
const element = ref<HTMLElement>();
const floating = ref<HTMLElement>();
const selection = ref<HTMLElement>();
const selected = ref<{ accommodation: Accommodation; value: [Date, Date] }>();
const dragging = ref<"left" | "right">();

const los = computed(() => authenticatedAccount.value?.los ?? []);
const selectedDays = computed(() => (selected.value ? dayjs(selected.value.value[1]).diff(dayjs(selected.value.value[0]), "day") + 1 : 0));
const previousDays = computed(() => dayjs(selected.value ? selected.value.value[0] : undefined).diff(dayjs(days.value[0]), "day"));

const elementVisible = useElementVisibility(element);
onClickOutside(
  target,
  () => {
    selected.value = undefined;
  },
  { ignore: [element, selection, floating] },
);

const onDrag = (date: Date, accommodation: Accommodation) => {
  if (dragging.value === "left") {
    if (dayjs(date).isAfter(selected.value ? selected.value.value[1] : date, "day")) {
      selected.value = {
        accommodation: accommodation,
        value: [selected.value ? selected.value.value[0] : date, date],
      };
      return;
    }
    selected.value = {
      accommodation: accommodation,
      value: [date, selected.value ? selected.value.value[1] : date],
    };
    return;
  }
  if (dragging.value === "right") {
    if (dayjs(date).isBefore(selected.value ? selected.value.value[0] : date, "day")) {
      selected.value = {
        accommodation: accommodation,
        value: [date, selected.value ? selected.value.value[1] : date],
      };
      return;
    }
    selected.value = {
      accommodation: accommodation,
      value: [selected.value ? selected.value.value[0] : date, date],
    };
    return;
  }
};

const { floatingStyles: editorFloatingStyles } = useFloating(selection, floating, {
  placement: "left-start",
  middleware: [
    shift(),
    offset(8),
    autoPlacement({
      autoAlignment: true,
      allowedPlacements: ["top-start", "bottom-start"],
    }),
  ],
  whileElementsMounted: autoUpdate,
});

const updatedPrices = () => {
  selected.value = undefined;
  emit("updated");
};
</script>
